export const countries = [
  { lada: "880", country: "Bangladesh", code: "BD" },
  { lada: "32", country: "Belgium", code: "BE" },
  { lada: "226", country: "Burkina Faso", code: "BF" },
  { lada: "359", country: "Bulgaria", code: "BG" },
  { lada: "387", country: "Bosnia and Herzegovina", code: "BA" },
  { lada: "1246", country: "Barbados", code: "BB" },
  { lada: "681", country: "Wallis and Futuna", code: "WF" },
  { lada: "590", country: "Saint Barthelemy", code: "BL" },
  { lada: "1441", country: "Bermuda", code: "BM" },
  { lada: "673", country: "Brunei", code: "BN" },
  { lada: "591", country: "Bolivia", code: "BO" },
  { lada: "973", country: "Bahrain", code: "BH" },
  { lada: "257", country: "Burundi", code: "BI" },
  { lada: "229", country: "Benin", code: "BJ" },
  { lada: "975", country: "Bhutan", code: "BT" },
  { lada: "1876", country: "Jamaica", code: "JM" },
  /* { lada: "", country: "Bouvet Island", code: "BV" }, */
  { lada: "267", country: "Botswana", code: "BW" },
  { lada: "685", country: "Samoa", code: "WS" },
  { lada: "599", country: "Bonaire, Saint Eustatius and Saba", code: "BQ" },
  { lada: "55", country: "Brazil", code: "BR" },
  { lada: "1242", country: "Bahamas", code: "BS" },
  { lada: "441534", country: "Jersey", code: "JE" },
  { lada: "375", country: "Belarus", code: "BY" },
  { lada: "501", country: "Belize", code: "BZ" },
  { lada: "7", country: "Russia", code: "RU" },
  { lada: "250", country: "Rwanda", code: "RW" },
  { lada: "381", country: "Serbia", code: "RS" },
  { lada: "670", country: "East Timor", code: "TL" },
  { lada: "262", country: "Reunion", code: "RE" },
  { lada: "993", country: "Turkmenistan", code: "TM" },
  { lada: "992", country: "Tajikistan", code: "TJ" },
  { lada: "40", country: "Romania", code: "RO" },
  { lada: "690", country: "Tokelau", code: "TK" },
  { lada: "245", country: "GuineaBissau", code: "GW" },
  { lada: "1671", country: "Guam", code: "GU" },
  { lada: "502", country: "Guatemala", code: "GT" },
  /* { lada: "", country: "South Georgia and the South Sandwich Islands", code: "GS" }, */
  { lada: "30", country: "Greece", code: "GR" },
  { lada: "240", country: "Equatorial Guinea", code: "GQ" },
  { lada: "590", country: "Guadeloupe", code: "GP" },
  { lada: "81", country: "Japan", code: "JP" },
  { lada: "592", country: "Guyana", code: "GY" },
  { lada: "441481", country: "Guernsey", code: "GG" },
  { lada: "594", country: "French Guiana", code: "GF" },
  { lada: "995", country: "Georgia", code: "GE" },
  { lada: "1473", country: "Grenada", code: "GD" },
  { lada: "44", country: "United Kingdom", code: "GB" },
  { lada: "241", country: "Gabon", code: "GA" },
  { lada: "503", country: "El Salvador", code: "SV" },
  { lada: "224", country: "Guinea", code: "GN" },
  { lada: "220", country: "Gambia", code: "GM" },
  { lada: "299", country: "Greenland", code: "GL" },
  { lada: "350", country: "Gibraltar", code: "GI" },
  { lada: "233", country: "Ghana", code: "GH" },
  { lada: "968", country: "Oman", code: "OM" },
  { lada: "216", country: "Tunisia", code: "TN" },
  { lada: "962", country: "Jordan", code: "JO" },
  { lada: "385", country: "Croatia", code: "HR" },
  { lada: "509", country: "Haiti", code: "HT" },
  { lada: "36", country: "Hungary", code: "HU" },
  { lada: "852", country: "Hong Kong", code: "HK" },
  { lada: "504", country: "Honduras", code: "HN" },
  /* { lada: " ", country: "Heard Island and McDonald Islands", code: "HM" }, */
  { lada: "58", country: "Venezuela", code: "VE" },
  { lada: "1787", country: "Puerto Rico", code: "PR" },
  { lada: "970", country: "Palestinian Territory", code: "PS" },
  { lada: "680", country: "Palau", code: "PW" },
  { lada: "351", country: "Portugal", code: "PT" },
  { lada: "47", country: "Svalbard and Jan Mayen", code: "SJ" },
  { lada: "595", country: "Paraguay", code: "PY" },
  { lada: "964", country: "Iraq", code: "IQ" },
  { lada: "507", country: "Panama", code: "PA" },
  { lada: "689", country: "French Polynesia", code: "PF" },
  { lada: "675", country: "Papua New Guinea", code: "PG" },
  { lada: "51", country: "Peru", code: "PE" },
  { lada: "92", country: "Pakistan", code: "PK" },
  { lada: "63", country: "Philippines", code: "PH" },
  { lada: "870", country: "Pitcairn", code: "PN" },
  { lada: "48", country: "Poland", code: "PL" },
  { lada: "508", country: "Saint Pierre and Miquelon", code: "PM" },
  { lada: "260", country: "Zambia", code: "ZM" },
  { lada: "212", country: "Western Sahara", code: "EH" },
  { lada: "372", country: "Estonia", code: "EE" },
  { lada: "20", country: "Egypt", code: "EG" },
  { lada: "27", country: "South Africa", code: "ZA" },
  { lada: "593", country: "Ecuador", code: "EC" },
  { lada: "39", country: "Italy", code: "IT" },
  { lada: "84", country: "Vietnam", code: "VN" },
  { lada: "677", country: "Solomon Islands", code: "SB" },
  { lada: "251", country: "Ethiopia", code: "ET" },
  { lada: "252", country: "Somalia", code: "SO" },
  { lada: "263", country: "Zimbabwe", code: "ZW" },
  { lada: "966", country: "Saudi Arabia", code: "SA" },
  { lada: "34", country: "Spain", code: "ES" },
  { lada: "291", country: "Eritrea", code: "ER" },
  { lada: "382", country: "Montenegro", code: "ME" },
  { lada: "373", country: "Moldova", code: "MD" },
  { lada: "261", country: "Madagascar", code: "MG" },
  { lada: "590", country: "Saint Martin", code: "MF" },
  { lada: "212", country: "Morocco", code: "MA" },
  { lada: "377", country: "Monaco", code: "MC" },
  { lada: "998", country: "Uzbekistan", code: "UZ" },
  { lada: "95", country: "Myanmar", code: "MM" },
  { lada: "223", country: "Mali", code: "ML" },
  { lada: "853", country: "Macao", code: "MO" },
  { lada: "976", country: "Mongolia", code: "MN" },
  { lada: "692", country: "Marshall Islands", code: "MH" },
  { lada: "389", country: "Macedonia", code: "MK" },
  { lada: "230", country: "Mauritius", code: "MU" },
  { lada: "356", country: "Malta", code: "MT" },
  { lada: "265", country: "Malawi", code: "MW" },
  { lada: "960", country: "Maldives", code: "MV" },
  { lada: "596", country: "Martinique", code: "MQ" },
  { lada: "1670", country: "Northern Mariana Islands", code: "MP" },
  { lada: "1664", country: "Montserrat", code: "MS" },
  { lada: "222", country: "Mauritania", code: "MR" },
  { lada: "441624", country: "Isle of Man", code: "IM" },
  { lada: "256", country: "Uganda", code: "UG" },
  { lada: "255", country: "Tanzania", code: "TZ" },
  { lada: "60", country: "Malaysia", code: "MY" },
  { lada: "52", country: "Mexico", code: "MX" },
  { lada: "972", country: "Israel", code: "IL" },
  { lada: "33", country: "France", code: "FR" },
  { lada: "246", country: "British Indian Ocean Territory", code: "IO" },
  { lada: "290", country: "Saint Helena", code: "SH" },
  { lada: "358", country: "Finland", code: "FI" },
  { lada: "679", country: "Fiji", code: "FJ" },
  { lada: "500", country: "Falkland Islands", code: "FK" },
  { lada: "691", country: "Micronesia", code: "FM" },
  { lada: "298", country: "Faroe Islands", code: "FO" },
  { lada: "505", country: "Nicaragua", code: "NI" },
  { lada: "31", country: "Netherlands", code: "NL" },
  { lada: "47", country: "Norway", code: "NO" },
  { lada: "264", country: "Namibia", code: "NA" },
  { lada: "678", country: "Vanuatu", code: "VU" },
  { lada: "687", country: "New Caledonia", code: "NC" },
  { lada: "227", country: "Niger", code: "NE" },
  { lada: "672", country: "Norfolk Island", code: "NF" },
  { lada: "234", country: "Nigeria", code: "NG" },
  { lada: "64", country: "New Zealand", code: "NZ" },
  { lada: "977", country: "Nepal", code: "NP" },
  { lada: "674", country: "Nauru", code: "NR" },
  { lada: "683", country: "Niue", code: "NU" },
  { lada: "682", country: "Cook Islands", code: "CK" },
  /* { lada: "", country: "Kosovo", code: "XK" }, */
  { lada: "225", country: "Ivory Coast", code: "CI" },
  { lada: "41", country: "Switzerland", code: "CH" },
  { lada: "57", country: "Colombia", code: "CO" },
  { lada: "86", country: "China", code: "CN" },
  { lada: "237", country: "Cameroon", code: "CM" },
  { lada: "56", country: "Chile", code: "CL" },
  { lada: "61", country: "Cocos Islands", code: "CC" },
  { lada: "1", country: "Canada", code: "CA" },
  { lada: "242", country: "Republic of the Congo", code: "CG" },
  { lada: "236", country: "Central African Republic", code: "CF" },
  { lada: "243", country: "Democratic Republic of the Congo", code: "CD" },
  { lada: "420", country: "Czech Republic", code: "CZ" },
  { lada: "357", country: "Cyprus", code: "CY" },
  { lada: "61", country: "Christmas Island", code: "CX" },
  { lada: "506", country: "Costa Rica", code: "CR" },
  { lada: "599", country: "Curacao", code: "CW" },
  { lada: "238", country: "Cape Verde", code: "CV" },
  { lada: "53", country: "Cuba", code: "CU" },
  { lada: "268", country: "Swaziland", code: "SZ" },
  { lada: "963", country: "Syria", code: "SY" },
  { lada: "599", country: "Sint Maarten", code: "SX" },
  { lada: "996", country: "Kyrgyzstan", code: "KG" },
  { lada: "254", country: "Kenya", code: "KE" },
  { lada: "211", country: "South Sudan", code: "SS" },
  { lada: "597", country: "Suriname", code: "SR" },
  { lada: "686", country: "Kiribati", code: "KI" },
  { lada: "855", country: "Cambodia", code: "KH" },
  { lada: "1869", country: "Saint Kitts and Nevis", code: "KN" },
  { lada: "269", country: "Comoros", code: "KM" },
  { lada: "239", country: "Sao Tome and Principe", code: "ST" },
  { lada: "421", country: "Slovakia", code: "SK" },
  { lada: "82", country: "South Korea", code: "KR" },
  { lada: "386", country: "Slovenia", code: "SI" },
  { lada: "850", country: "North Korea", code: "KP" },
  { lada: "965", country: "Kuwait", code: "KW" },
  { lada: "221", country: "Senegal", code: "SN" },
  { lada: "378", country: "San Marino", code: "SM" },
  { lada: "232", country: "Sierra Leone", code: "SL" },
  { lada: "248", country: "Seychelles", code: "SC" },
  { lada: "7", country: "Kazakhstan", code: "KZ" },
  { lada: "1345", country: "Cayman Islands", code: "KY" },
  { lada: "65", country: "Singapore", code: "SG" },
  { lada: "46", country: "Sweden", code: "SE" },
  { lada: "249", country: "Sudan", code: "SD" },
  { lada: "1809", country: "Dominican Republic", code: "DO" },
  { lada: "1767", country: "Dominica", code: "DM" },
  { lada: "253", country: "Djibouti", code: "DJ" },
  { lada: "45", country: "Denmark", code: "DK" },
  { lada: "1284", country: "British Virgin Islands", code: "VG" },
  { lada: "49", country: "Germany", code: "DE" },
  { lada: "967", country: "Yemen", code: "YE" },
  { lada: "213", country: "Algeria", code: "DZ" },
  { lada: "1", country: "United States", code: "US" },
  { lada: "598", country: "Uruguay", code: "UY" },
  { lada: "262", country: "Mayotte", code: "YT" },
  { lada: "1", country: "United States Minor Outlying Islands", code: "UM" },
  { lada: "961", country: "Lebanon", code: "LB" },
  { lada: "1758", country: "Saint Lucia", code: "LC" },
  { lada: "856", country: "Laos", code: "LA" },
  { lada: "688", country: "Tuvalu", code: "TV" },
  { lada: "886", country: "Taiwan", code: "TW" },
  { lada: "1868", country: "Trinidad and Tobago", code: "TT" },
  { lada: "90", country: "Turkey", code: "TR" },
  { lada: "94", country: "Sri Lanka", code: "LK" },
  { lada: "423", country: "Liechtenstein", code: "LI" },
  { lada: "371", country: "Latvia", code: "LV" },
  { lada: "676", country: "Tonga", code: "TO" },
  { lada: "370", country: "Lithuania", code: "LT" },
  { lada: "352", country: "Luxembourg", code: "LU" },
  { lada: "231", country: "Liberia", code: "LR" },
  { lada: "266", country: "Lesotho", code: "LS" },
  { lada: "66", country: "Thailand", code: "TH" },
  /* { lada: "", country: "French Southern Territories", code: "TF" }, */
  { lada: "228", country: "Togo", code: "TG" },
  { lada: "235", country: "Chad", code: "TD" },
  { lada: "1649", country: "Turks and Caicos Islands", code: "TC" },
  { lada: "218", country: "Libya", code: "LY" },
  { lada: "379", country: "Vatican", code: "VA" },
  { lada: "1784", country: "Saint Vincent and the Grenadines", code: "VC" },
  { lada: "971", country: "United Arab Emirates", code: "AE" },
  { lada: "376", country: "Andorra", code: "AD" },
  { lada: "1268", country: "Antigua and Barbuda", code: "AG" },
  { lada: "93", country: "Afghanistan", code: "AF" },
  { lada: "1264", country: "Anguilla", code: "AI" },
  { lada: "1340", country: "U.S. Virgin Islands", code: "VI" },
  { lada: "354", country: "Iceland", code: "IS" },
  { lada: "98", country: "Iran", code: "IR" },
  { lada: "374", country: "Armenia", code: "AM" },
  { lada: "355", country: "Albania", code: "AL" },
  { lada: "244", country: "Angola", code: "AO" },
  /* { lada: "", country: "Antarctica", code: "AQ" }, */
  { lada: "1684", country: "American Samoa", code: "AS" },
  { lada: "54", country: "Argentina", code: "AR" },
  { lada: "61", country: "Australia", code: "AU" },
  { lada: "43", country: "Austria", code: "AT" },
  { lada: "297", country: "Aruba", code: "AW" },
  { lada: "91", country: "India", code: "IN" },
  { lada: "35818", country: "Aland Islands", code: "AX" },
  { lada: "994", country: "Azerbaijan", code: "AZ" },
  { lada: "353", country: "Ireland", code: "IE" },
  { lada: "62", country: "Indonesia", code: "ID" },
  { lada: "380", country: "Ukraine", code: "UA" },
  { lada: "974", country: "Qatar", code: "QA" },
  { lada: "258", country: "Mozambique", code: "MZ" }
]