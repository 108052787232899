import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './recover/recover.component';
import { AuthGuard } from './guards/auth.guard';
import { CandidateDocumentsComponent } from './candidate-documents/candidate-documents.component';
import { NoticeComponent } from './notice/notice.component';
import { TermsComponent } from './terms/terms.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { CandidateExternalComponent } from './candidate-external/candidate-external.component';
import { CompanyRedirectGuard } from './guards/company-redirect.guard';
import { BlogComponent } from './blog/blog.component';
import { BlogIdComponent } from './blog-id/blog-id.component';
import { CookiesComponent } from './cookies/cookies.component';
import { SuccessPaymentComponent } from './success-payment/success-payment.component';
import { CancelPaymentComponent } from './cancel-payment/cancel-payment.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login - Haibu HR',
      description: 'Haibu HR  Un software para los héroes de Recursos Humanos',
      keywords: 'login, haibu, HR, recruitment, RH, HAIBUHR, Haibu, Reclutamiento, Recursos Humanos, Software, RRHH, Reclutamiento, ATS, Applicant Tracking System, Mérida, Yucatán, México',
      author: 'Haibu HR Team'
    }
  },
  {
    path: 'blog',
    component: BlogComponent,
    data: {
      title: 'Blog - Haibu HR',
      description: 'Descubre cómo las nuevas tecnologías mejoran el seguimiento y selección de candidatos',
      keywords: 'blog, digital, organizacion, haibu, HR, recruitment, RH, HAIBUHR, Haibu, Reclutamiento, Recursos Humanos, Software, RRHH, Reclutamiento, ATS, Applicant Tracking System, Mérida, Yucatán, México',
      author: 'Haibu HR Team'
    }
  },
  {
    path: 'blog/:slug', // ':slug' es el parámetro dinámico
    component: BlogIdComponent,
    data: {
      title: 'Blog - Haibu HR',
      description: 'Descubre cómo las nuevas tecnologías mejoran el seguimiento y selección de candidatos',
      keywords: 'blog, digital, organizacion, haibu, HR, recruitment, RH, HAIBUHR, Haibu, Reclutamiento, Recursos Humanos, Software, RRHH, Reclutamiento, ATS, Applicant Tracking System, Mérida, Yucatán, México',
      author: 'Haibu HR Team'
    }
  },
  {
    path: 'notice',
    component: NoticeComponent,
    data: {
      title: 'Notice - Haibu HR',
      description: 'Important notices and updates from Haibu Hr.',
      keywords: 'notice, updates, haibu'
    }
  },
  {
    path: 'terms',
    component: TermsComponent,
    data: {
      title: 'Terms and Conditions - Haibu HR',
      description: 'Read the terms and conditions of Haibu Hr.',
      keywords: 'terms, conditions, haibu'
    }
  },
  {
    path: 'cookies',
    component: CookiesComponent,
    data: {
      title: 'Cookies - Haibu HR',
      description: 'Read the cookies of Haibu Hr.',
      keywords: 'cookies, conditions, haibu'
    }
  },
  {
    path: 'recover',
    component: RecoverComponent,
    data: {
      title: 'Recover Account - Haibu Hr',
      description: 'Recover your Haibu Hr account.',
      keywords: 'recover, account, haibu'
    }
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Reset Password - Haibu HR',
      description: 'Reset your Haibu Hr password.',
      keywords: 'reset, password, haibu'
    }
  },
  {
    path: 'home',
    canActivate: [AuthGuard, CompanyRedirectGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: {
      title: 'Haibu HR',
      description: 'Welcome to Haibu Hr, your ultimate recruitment platform.',
      keywords: 'home, haibu, hr, recruitment'
    }
  },
  {
    path: 'candidateDocuments',
    component: CandidateDocumentsComponent,
    data: {
      title: 'Haibu HR',
      description: 'Sube y administra tus documentos de candidato.',
      keywords: 'candidate, documents, haibu'
    }
  },
  {
    path: 'success',
    component: SuccessPaymentComponent,
    data: {
      title: 'Pago exitoso - Haibu HR'
    }
  },
  {
    path: 'cancel',
    component: CancelPaymentComponent,
    data: {
      title: 'Pago cancelado - Haibu HR'
    }
  },
  {
    path: 'CD',
    component: CandidateDocumentsComponent,
    data: {
      title: 'Haibu HR',
      description: 'Sube y administra tus documentos de candidato.',
      keywords: 'candidate, documents, haibu'
    }
  },
  {
    path: 'CE',
    component: CandidateExternalComponent,
    data: {
      title: 'Haibu HR',
      description: 'Welcome to Haibu Hr, your ultimate recruitment platform.',
      keywords: 'candidate, external, haibu'
    }
  },
  {
    path: ':companyName',
    children: [
      {
        path: 'CD',
        component: CandidateDocumentsComponent,
        data: {
          title: 'Haibu HR',
          description: 'Sube y administra tus documentos de candidato.',
          keywords: 'candidate, documents, haibu'
        }
      },
      {
        path: 'CE',
        component: CandidateExternalComponent,
        data: {
          title: 'Haibu HR',
          description: 'Postulate a la vacante de tu interés.',
          keywords: 'candidate, external, haibu'
        }
      },
      {
        path: 'home',
        canActivate: [AuthGuard, CompanyRedirectGuard],
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        data: {
          title: 'Haibu HR',
          description: 'Bienvenido a Haibu HR, tu plataforma definitiva de reclutamiento.',
          keywords: 'home, haibu, hr, recruitment'
        }
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Restaura la posición del scroll a la parte superior
    anchorScrolling: 'enabled' // (opcional) Permite el uso de anclajes si tienes enlaces con #id
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
