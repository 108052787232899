import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { APIResponse } from 'src/app/interfaces/response';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-plans-dialog',
  templateUrl: './plans-dialog.component.html',
  styleUrls: ['./plans-dialog.component.css']
})
export class PlansDialogComponent implements OnInit {

  subscriptionType = 'semestral';
  token: APIResponse = this.localService.getJsonValue('token');
  loading: boolean = false;
  currentPlan: string;
  currentBilling: string;

  constructor(
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private dialogRef: MatDialogRef<PlansDialogComponent>
  ) { }

  ngOnInit(): void {
    this.currentPlan = this.token?.data?.comP_CURRENT_PLAN;
    if (this.currentPlan.includes('Annual')) {
      this.currentBilling = 'semestral';
    } else if (this.currentPlan.includes('Monthly')) {
      this.currentBilling = 'mensual';
    }

    if (!this.currentPlan) {
      this.currentPlan = 'Default';
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  setSubscriptionType(type: string) {
    this.subscriptionType = type;
  }

  scheduleMeet() {
    window.open('https://meet.goodtime.io/w/haibuhrcom/jgasteasoro/video-call/intro', '_blank');
  }

  upgradePlanStripe(plan: string) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    let priceId = '';

    switch (plan) {
      case 'Entry':
        priceId = environment.stripe.monthly.entry;
        break;
      case 'Mid':
        priceId = environment.stripe.monthly.mid;
        break;
      case 'EntryHH':
        priceId = environment.stripe.monthly.entryHH;
        break;
      case 'MidHH':
        priceId = environment.stripe.monthly.midHH;
        break;
      case 'EntryY':
        priceId = environment.stripe.yearly.entry;
        break;
      case 'MidY':
        priceId = environment.stripe.yearly.mid;
        break;
      case 'EntryHHY':
        priceId = environment.stripe.yearly.entryHH;
        break;
      case 'MidHHY':
        priceId = environment.stripe.yearly.midHH;
        /* if (this.token?.data?.companyId == '78445aab-c168-4e55-865d-16321c4a685e') {
          priceId = 'price_1QYG1F06Uk7hPRLENWXAyGtF'
        } */
        break;
      default:
        priceId = null; // O cualquier valor por defecto necesario
        break;
    }

    const upgradeData = {
      PriceId: priceId,
      SuccessUrl: environment.candidateUrl + "success",
      CancelUrl: environment.candidateUrl + "cancel",
      COMP_ID: this.token.data.companyId
    }
    this.puzzleService.upgradePlanStripe(upgradeData).subscribe((response: any) => {
      this.loading = false;
      if (response.data?.url) {
        window.location.href = response.data.url;
      }
    },
      error => {
        this.loading = false;
      });
  }
}
