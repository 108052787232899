import { Component, Inject, OnInit } from '@angular/core';
import { ChangeStatusComponent } from '../change-status/change-status.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResponseSmallDialogComponent } from '../response-small-dialog/response-small-dialog.component';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { Company } from 'src/app/interfaces/company';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-client-payment',
  templateUrl: './client-payment.component.html',
  styleUrls: ['./client-payment.component.css']
})
export class ClientPaymentComponent implements OnInit {

  paymentDate: any;
  selectedPlan: any;
  planOptions = [
    { value: '1', viewValue: '1MES' },
    { value: '2', viewValue: '3MES' },
    { value: '3', viewValue: '6MES' },
    { value: '4', viewValue: '12MES' }
  ];
  activeFreemium: boolean = false;
  minDate = new Date();
  compId: string;
  company: Company;
  sending: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<ChangeStatusComponent>,
    private dialog: MatDialog,
    private puzzleService: PuzzleService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: Company
  ) {
    if (data?.eH_PAYMENT_DATE) {
      this.paymentDate = data.eH_PAYMENT_DATE;
    } else {
      this.paymentDate = new Date();
    }

    if (data?.eH_COMP_FREEMIUM_CHANGE) {
      this.activeFreemium = data.eH_COMP_FREEMIUM_CHANGE
    }

    if (data?.comP_ID) {
      this.compId = data.comP_ID;
      this.company = data;
    }

    // 3 años en el pasado
    const date = new Date();
    date.setFullYear(date.getFullYear() - 3);
    this.minDate = date;
  }

  ngOnInit(): void {
    // calcular si el plan activo es de 1 mes, 3 meses, 6 meses o 12 meses
    if (this.company?.eH_PAYMENT_DATE && this.company?.eH_PAYMENT_DUE) {
      const startDate = new Date(this.company.eH_PAYMENT_DATE);
      const endDate = new Date(this.company.eH_PAYMENT_DUE);
      const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays <= 31) {
        this.selectedPlan = '1';
      } else if (diffDays > 31 && diffDays <= 92) {
        this.selectedPlan = '2';
      } else if (diffDays > 92 && diffDays <= 184) {
        this.selectedPlan = '3';
      } else if (diffDays > 184) {
        this.selectedPlan = '4';
      }
    }
  }

  getPlan(element: any): string {
    if (element.comP_JOBS_MANAGEMENT) {
      return 'haibujobs';
    } else
      if (element.comP_FREEMIUM) {
        return 'freemium';
      } else if (element.comP_IS_AGENCY && element.comP_HAS_SUBDOMAIN) {
        return 'full';
      } else if (element.comP_IS_AGENCY) {
        return 'agency';
      } else if (element.comP_HAS_SUBDOMAIN) {
        return 'middle';
      } else {
        return 'basic';
      }
  }

  closeModal() {
    this.dialogRef.close();
  }

  onDateChange(date: any): void {
    if (date) {
      this.paymentDate = date;
    }
  }

  savePayment() {
    if (this.sending) {
      return;
    }

    if (!this.paymentDate || !this.selectedPlan) {
      this.openResultDialog(0, this.translate.instant('clientBillingFillFields'));
      return;
    }

    this.sending = true;

    let dueDate;
    switch (this.selectedPlan) {
      case '1':
        dueDate = new Date(this.paymentDate);
        dueDate.setMonth(dueDate.getMonth() + 1);
        break;
      case '2':
        dueDate = new Date(this.paymentDate);
        dueDate.setMonth(dueDate.getMonth() + 3);
        break;
      case '3':
        dueDate = new Date(this.paymentDate);
        dueDate.setMonth(dueDate.getMonth() + 6);
        break;
      case '4':
        dueDate = new Date(this.paymentDate);
        dueDate.setMonth(dueDate.getMonth() + 12);
        break;
    }

    const data = {
      COMP_ID: this.compId,
      EH_PAYMENT_DATE: this.paymentDate,
      EH_PAYMENT_DUE: dueDate,
      EH_COMP_FREEMIUM_CHANGE: this.activeFreemium
    };

    this.puzzleService.updateOrCreateCompanyPayment(data).subscribe(
      (res) => {
        if (res.isSuccess) {
          this.openResultDialog(1, this.translate.instant('clientBillingSuccessPayment'));
          if (this.company.plan) {
            this.changePlan(this.company.plan, this.compId, 0);
          }
          this.dialogRef.close(true);
        } else {
          this.openResultDialog(0, this.translate.instant('clientBillingError'), res.message);
        }
        this.sending = false;
      },
      (error) => {
        this.openResultDialog(0, this.translate.instant('clientBillingError'), error.error.message);
        this.sending = false;
      }
    );
  }

  changePlan(selectedPlan: string, companyId: string, index: number) {
    switch (selectedPlan) {
      case 'freemium':
        this.changeToFreemiumPlan(companyId, index);
        break;
      case 'basic':
        this.changeToBasicPlan(companyId, index);
        break;
      case 'agency':
        this.changeAgencyStatus(companyId, index);
        break;
      case 'middle':
        this.changeToMiddlePlan(companyId, index);
        break;
      case 'full':
        this.changeToFullPlan(companyId, index);
        break;
      case 'haibujobs':
        this.changeToJobsPlan(companyId, index);
        break
    }
  }

  openResultDialog(mode: number, message?: string, content?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0:
        {
          data.push('Error');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 1:
        {
          data.push('Exito');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 2:
        {
          data.push('loading');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
            disableClose: true
          });
        }
        break;
    }
  }

  changeAgencyStatus(companyId: string, index: number) {
    let company: Company = {
      cOMP_ID: companyId,
      comP_IS_AGENCY: true
    };

    this.puzzleService.changeAgencyStatus(company).subscribe(
      resp => {
        // Handle the response if needed
      }
    );
  }

  changeToFreemiumPlan(companyId: string, index: number) {
    let company: Company = {
      cOMP_ID: companyId,
    };

    this.puzzleService.changeToFreemiumPlan(company).subscribe(
      resp => {
      }
    );
  }

  changeToJobsPlan(companyId: string, index: number) {
    let company: Company = {
      cOMP_ID: companyId,
    };

    this.puzzleService.changeToJobsPlan(company).subscribe(
      resp => {
      }
    );
  }

  changeToBasicPlan(companyId: string, index: number) {
    let company: Company = {
      cOMP_ID: companyId,
      COMP_VERSION: true
    };

    this.puzzleService.changeToBasicPlan(company).subscribe(
      resp => {
      }
    );
  }

  changeToMiddlePlan(companyId: string, index: number) {
    let company: Company = {
      cOMP_ID: companyId,
      COMP_VERSION: true
    };

    this.puzzleService.changeToMiddlePlan(company).subscribe(
      resp => {
      }
    );
  }

  changeToFullPlan(companyId: string, index: number) {
    let company: Company = {
      cOMP_ID: companyId,
      COMP_VERSION: true
    };

    this.puzzleService.changeToFullPlan(company).subscribe(
      resp => {
      }
    );
  }
}
