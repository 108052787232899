import { Injectable, InjectionToken, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

const DIALOG_COMPONENTS = new InjectionToken('DIALOG_COMPONENTS', {
  providedIn: 'root',
  factory: () => ({
    login: () => import('../dialogs/login-dialog/login-dialog.component').then(m => m.LoginDialogComponent),
    register: () => import('../dialogs/register-dialog/register-dialog.component').then(m => m.RegisterDialogComponent),
  })
});

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  constructor(private dialog: MatDialog, private injector: Injector) { }

  async openLoginModal(data: any = {}): Promise<void> {
    const { login } = this.injector.get(DIALOG_COMPONENTS);
    const component = await login();
    this.dialog.open(component, {
      width: '376px',
      height: '546px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: { jobs: data }
    });
  }

  async openRegisterModal(data: any = {}): Promise<void> {
    const { register } = this.injector.get(DIALOG_COMPONENTS);
    const component = await register();
    this.dialog.open(component, {
      width: '700px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: { prev: data, isJobs: data, plan: 'Mid + Head Hunter' }
    });
  }

  async openConfirmCodeModal(user: any): Promise<void> {
    const { register } = this.injector.get(DIALOG_COMPONENTS);
    const component = await register();
    this.dialog.open(component, {
      width: '700px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: { email: user }
    });
  }
}
