<div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
        <mat-icon class="close-x-primary">close</mat-icon>
    </button>
</div>

<div class="pb-2 text-center title-text-modal">
    {{ 'duplicateTest' | translate }}
</div>

<div class="md:px-6 px-0 form-container pt-3">
    <p class="descriptionDuplicateText">
        {{ 'duplicateTestDescription' | translate }}
    </p>
    <div class="pb-3 flex flex-col gap-2">
        <div class="flex gap-2" *ngFor="let job of jobList; let i = index">
            <mat-checkbox [(ngModel)]="job.checked">
            </mat-checkbox>
            <div (click)="selectJob(i)" class="cursor-pointer">
                {{ job.joB_NAME }}
            </div>
        </div>
    </div>
</div>

<div class="md:px-6 px-0 flex justify-between pt-4">
    <app-custom-primary-button id="addQuestion" size="filter-size" color="secondary" class="text-center"
        [textPadding]="'16px'" [textAlign]="'inherit'" (click)="closeModal()">
        <span class="btn-label">{{ 'cancelDuplicateTest' | translate }}</span>
    </app-custom-primary-button>
    <app-custom-primary-button id="saveQuestions" type="submit" size="filter-size" color="primary" class="text-center"
        [textPadding]="'16px'" [textAlign]="'inherit'" (click)="duplicateQuestionnaire()">
        <div class="w-full flex justify-center gap-2">
            <mat-spinner *ngIf="sending" diameter="20" color="primary"></mat-spinner>
            <span class="btn-label">{{ 'saveDuplicateTest' | translate }}</span>
        </div>
    </app-custom-primary-button>
</div>