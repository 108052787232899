<div class="w-full py-3 pb-3" [ngClass]="{'px-10': superadmin}">
  <div *ngIf="superadmin && !catalogueSection" class="flex justify-between">
    <div class="0 md:px-0  flex flex-row items-center pb-3 pt-2">
      <a href="/home/clientlist">
        <mat-icon class="align-middle   -mr-3"
          style="font-size: 24px; height: unset; width: unset; color: var(--color-primario)">
          arrow_back_ios
        </mat-icon>
      </a>
      <div class="title-text pl-2">{{"clientListBussines" | translate}}</div>
    </div>


    <div *ngIf="!newClient" class="flex justify-end pb-3 pt-2">
      <a (click)="goCompany(id)" class="font-medium text-base color-primario">{{"clientDetailConf" | translate}}</a>
    </div>
  </div>

  <div *ngIf="!newClient" class="py-4 max-w-full wizard-container mb-6">
    <div class="flex justify-between px-4  pt-4">
      <div class="flex items-center">
        <div class="title-candidate-profile">{{"clientDetailGeneralData" | translate}}</div>
      </div>
      <span *appPermission="'m002'">
        <a id="editBillingSection" class="flex items-center" *ngIf="!editFirstSection && !loadingDetail"
          (click)="editBillingSection()">
          <img [src]="editIcon" alt="Icono" class="w-5 h-5 mr-2">
          <span class="edit-text">{{"candidateDetailEditText" | translate}}</span>
        </a>
      </span>
    </div>
    <div *ngIf="!editFirstSection">
      <div class="flex flex-col md:flex-row px-4">
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 py-3 w-full">
          <div class="w-full">
            <label for="nombre" class="title-input-text">{{"clientDetailComercialName" | translate}}</label>
            <div class="px-1 py-3 break-all" name="nombre">
              {{ receivedCompany?.comP_NAME }}
            </div>
          </div>
          <div class="w-full">
            <label for="staff" class="title-input-text">{{"clientDetailStaff" | translate}}</label>
            <div class="px-1 py-3 break-all" name="staff">
              {{ receivedCompany.comP_AUTHORIZED_TEMPLATE }}
            </div>
          </div>
          <div class="w-full">
            <label for="area" class="title-input-text">{{"clientDetailArea" | translate}}</label>
            <div class="px-1 py-3 break-all" name="area" required>
              {{ getAreaKey(receivedCompany?.comP_AREA_ID) | translate }}
            </div>
          </div>
          <div class="w-full">
            <label for="limite" class="title-input-text">{{"clientDetailLimit" | translate}}</label>
            <div class="px-1 py-3 break-all" name="limite" required>
              {{ receivedCompany.comP_USER_LIMIT }}
            </div>
          </div>
          <div *ngIf="!receivedCompany.comP_IS_MINI" class="w-full">
            <div class="flex items-center gap-1">
              <label for="limite" class="title-input-text">{{"clientDetailCountDays" | translate}}</label>
              <mat-icon
                [matTooltip]="(receivedCompany.comP_USE_NATURAL_DAYS ? 'clientDetailConfCountDaysTooltip2' : 'clientDetailConfCountDaysTooltip') | translate"
                class="help-icon">help</mat-icon>
            </div>
            <div class="px-1 py-3 break-all" name="limite" required>
              {{ receivedCompany.comP_USE_NATURAL_DAYS ? ('naturales' | translate) : ('laborales' | translate) }}
            </div>
          </div>
          <div class="w-full" *ngIf="!receivedCompany.comP_USE_NATURAL_DAYS && !receivedCompany.comP_IS_MINI">
            <div class="flex items-center gap-1">
              <label for="limite" class="title-input-text">{{"clientDetailWorkedDays" | translate}}</label>
              <mat-icon [matTooltip]="'clientDetailConfWorkedDaysTooltip' | translate" class="help-icon">help</mat-icon>
            </div>
            <div class="px-1 py-3 break-all" name="limite" required>
              {{ getLabelDays(receivedCompany.comP_WORKING_DAYS_PER_WEEK) }}
            </div>
          </div>
          <div
            *ngIf="superadmin && receivedCompany?.comP_SUBSCRIPTION_STATUS != 'Paid' && receivedCompany?.comP_SUBSCRIPTION_STATUS != 'Cancelled'"
            class="w-full">
            <label for="limite" class="title-input-text">{{"clientDetailConfTrialEnd" | translate}}</label>
            <div class="px-1 py-3 break-all" name="limite" required>
              {{ receivedCompany?.comP_TRIAL_END_DATE | dateFormat }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="editFirstSection">
      <form (ngSubmit)="editOrCreateCompany(myForm, 1)" #myForm="ngForm" class="my-form">
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-3 w-full gap-4 px-4">
          <div class="w-full">
            <label for="nombre" class="title-input-text">{{"clientDetailConfName" | translate}}</label>
            <input id="clientDetailConfName" maxlength="100" [(ngModel)]="company.comP_NAME" type="text"
              class="input-style" name="nombre" required
              oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();" />
          </div>

          <div class="w-full">
            <label for="staff" class="title-input-text">{{"clientDetailConfStaff" | translate}}</label>
            <input id="clientDetailConfStaff" [(ngModel)]="company.comP_AUTHORIZED_TEMPLATE" type="text"
              class="input-style" name="staff" inputmode="decimal" pattern="[0-9]*" maxlength="9"
              oninput="this.value = this.value.replace(/[^0-9]/g, '');">
          </div>

          <div class="w-full">
            <label for="area" class="title-input-text">{{"clientDetailArea" | translate}}</label>
            <mat-select id="clientDetailArea" required [(ngModel)]="company.comP_AREA_ID" name="area" id=""
              class="px-1 border py-2.5 my-1 w-full mat-dropdown" required (focus)="onFocus($event)" (blur)="onBlur($event)">
              <mat-option *ngFor="let area of areas; let i = index" [value]="area.experienceAreaId">
                {{ area?.experienceAreaKey | translate }}
              </mat-option>
            </mat-select>
          </div>

          <div class="w-full">
            <label for="limite" class="title-input-text">{{"clientDetailConfUserLimit" | translate}}</label>
            <input id="clientDetailConfUserLimit" required [disabled]="!superadmin"
              [(ngModel)]="company.comP_USER_LIMIT" type="text" class="input-style" name="limite" inputmode="decimal"
              pattern="[0-9]*" maxlength="15" oninput="this.value = this.value.replace(/[^0-9]/g, '');" />
          </div>

          <div class="w-full" *ngIf="!company.comP_IS_MINI">
            <div class="flex items-center gap-1">
              <label for="natural" class="title-input-text">{{"clientDetailConfCountDays" | translate}}</label>
              <mat-icon
                [matTooltip]="(company.comP_USE_NATURAL_DAYS ? 'clientDetailConfCountDaysTooltip2' : 'clientDetailConfCountDaysTooltip') | translate"
                class="help-icon">help</mat-icon>
            </div>
            <mat-select id="clientDetailConfCountDays" [(ngModel)]="company.comP_USE_NATURAL_DAYS" name="natural" id=""
              class="px-1 border py-2.5 my-1 w-full mat-dropdown" (selectionChange)="onSelectDays($event)" (focus)="onFocus($event)" (blur)="onBlur($event)">
              <mat-option [value]="true">{{ "naturales" | translate }}</mat-option>
              <mat-option [value]="false">{{ "laborales" | translate }}</mat-option>
            </mat-select>
          </div>

          <div *ngIf="!company.comP_USE_NATURAL_DAYS && !company.comP_IS_MINI" class="w-full">
            <div class="flex items-center gap-1">
              <label for="worked" class="title-input-text">{{"clientDetailConfWorkedDays" | translate}}</label>
              <mat-icon [matTooltip]="'clientDetailConfWorkedDaysTooltip' | translate" class="help-icon">help</mat-icon>
            </div>
            <mat-select id="clientDetailConfWorkedDays" [(ngModel)]="company.comP_WORKING_DAYS_PER_WEEK" name="worked"
              id="" class="px-1 border py-2.5 my-1 w-full mat-dropdown" (focus)="onFocus($event)" (blur)="onBlur($event)">
              <mat-option [value]="7">{{ "sevenDays" | translate }}</mat-option>
              <mat-option [value]="6">{{ "sixDays" | translate }}</mat-option>
              <mat-option [value]="5">{{ "fiveDays" | translate }}</mat-option>
            </mat-select>
          </div>

          <div
            *ngIf="superadmin && company?.comP_SUBSCRIPTION_STATUS != 'Paid' && company?.comP_SUBSCRIPTION_STATUS != 'Cancelled'"
            class="w-full">
            <label for="birthDate" class="title-input-text">{{ 'clientDetailConfTrialEnd' | translate }}</label>
            <app-lang-datepicker [date]="company.comP_CHANGE_DATE_TRIAL" [max]="maxDate" [required]="true"
              (datechange)="company.comP_CHANGE_DATE_TRIAL = $event"></app-lang-datepicker>
          </div>

          <div *ngIf="superadmin && company?.comP_SUBSCRIPTION_STATUS == 'Freemium'">
            <label for="birthDate" class="title-input-text">{{ 'clientDetailConfActualPlan' | translate }}</label>
            <mat-select [value]="getPlan(company)" panelClass="my-custom-panel" class="input-style"
              (selectionChange)="company.plan = $event.value">
              <mat-option value="haibujobs">{{ "Haibu Jobs" | translate }}</mat-option>
              <mat-option value="freemium">{{ 'Freemium' | translate }}</mat-option>
              <mat-option value="basic">{{ 'Entry' | translate }}</mat-option>
              <mat-option value="agency">{{ 'Entry + HH' | translate }}</mat-option>
              <mat-option value="middle">{{ 'Mid' | translate }}</mat-option>
              <mat-option value="full">{{ 'Mid + HH' | translate }}</mat-option>
            </mat-select>
          </div>
        </div>

        <!-- <div class="flex flex-col md:flex-row px-6 md:px-3 pb-3">
          <div class="md:ml-auto md:mx-3 md:py-0 mx-0 py-2 ">
            <app-custom-primary-button id="cancelEditBillingData" *ngIf="!newClient" (click)="cancelEdit()" size="mini"
              color="secondary" class="w-full">
              {{ "clientDetailConfCancel" | translate }}
            </app-custom-primary-button>
          </div>
          <div class="md:mx-3 md:py-0 mx-0 py-2">
            <app-custom-primary-button id="saveEditBillingData" type="submit" size="mini" color="primary"
              (click)="submitted = true" class="w-full">
              {{ id ? ("clientDetailConfSave" | translate) : ("clientDetailConfCreate2" | translate) }}
            </app-custom-primary-button>
          </div>
        </div> -->

      </form>
    </div>
  </div>

  <div *ngIf="!newClient && !company.comP_IS_MINI && company.comP_HAS_SUBDOMAIN"
    class="py-4 max-w-full wizard-container mb-6">
    <div class="flex justify-between px-4  pt-4">
      <div class="flex items-center">
        <div class="title-candidate-profile">{{"createSubdomainLabel" | translate}}</div>
      </div>
      <span *appPermission="'m002'">
        <a id="editBillingSection" class="flex items-center" *ngIf="!editFirstSection && !loadingDetail"
          (click)="editBillingSection()">
          <img [src]="editIcon" alt="Icono" class="w-5 h-5 mr-2">
          <span class="edit-text">{{"candidateDetailEditText" | translate}}</span>
        </a>
      </span>
    </div>
    <div *ngIf="!editFirstSection">
      <div class="flex flex-col md:flex-row px-4">
        <div class="flex flex-wrap py-3 w-full -mx-2">
          <div *ngIf="receivedCompany?.comP_HAS_SUBDOMAIN" class="w-full md:w-1/2 lg:w-1/4 px-2">
            <label for="companyColorView" class="title-input-text">{{'clientDetailConfColor2' | translate}}</label>
            <div class="px-1 py-3 break-all" name="companyColorView" [style.background]="company.comP_PRIMARY_COLOR"
              style="width: 40px; height: 40px; border-radius: 50%; border: 1px solid #ccc;">
            </div>
          </div>
          <div
            *ngIf="receivedCompany?.comP_HAS_SUBDOMAIN && !receivedCompany.comP_SUBDOMAIN && !newClient && superadmin && !catalogueSection"
            class="w-full md:w-1/2 lg:w-1/4 px-2">
            <div (click)="openCreateSubdomainDialog()" class="font-medium text-base color-primario cursor-pointer">
              Crear subdominio
            </div>
          </div>
          <div *ngIf="receivedCompany?.comP_SUBDOMAIN" class="w-full md:w-1/2 lg:w-1/4 px-2">
            <label for="limite" class="title-input-text">{{"clientDetailConURL" | translate}}</label>
            <div class="px-1 py-3 break-all subdomain-input-text cursor-pointer" name="limite" required
              (click)="openSubdomain()">
              {{ receivedCompany.comP_SUBDOMAIN }}.haibuhr.com
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col py-3 w-full px-4 md:px-4">
        <label for="companyColor" class="title-input-text">{{'clientDetailConfDescription' | translate}}</label>
        <div class="w-full max-h-28 overflow-hidden">
          <quill-view [content]="company.comP_SUBDOMAIN_DESCRIPTION" format="html" theme="snow"></quill-view>
        </div>
      </div>
    </div>
    <div *ngIf="editFirstSection">
      <form (ngSubmit)="editOrCreateCompany(myForm, 1)" #myForm="ngForm" class="my-form">
        <div class="flex flex-col md:flex-row py-3 w-full px-4 md:px-4">

          <div *ngIf="receivedCompany?.comP_HAS_SUBDOMAIN" class="w-full md:w-1/2 lg:w-1/4 px-2">
            <label for="companyColor" class="title-input-text">{{'clientDetailConfColor2' | translate}}</label>
            <div class="custom-color-picker pt-2">
              <ngx-colors ngx-colors-trigger [(ngModel)]="company.comP_PRIMARY_COLOR" [format]="'hex'" name="color"
                style="display: inline-block; margin: 5px;"></ngx-colors>
            </div>
          </div>
        </div>

        <div class="flex flex-col py-3 w-full px-4 md:px-4">
          <label for="companyColor" class="title-input-text">{{'clientDetailConfDescription' | translate}}</label>
          <div class="w-full mt-1 mb-2">
            <quill-editor id="addCompanyDescription" [(ngModel)]="company.comP_SUBDOMAIN_DESCRIPTION"
              [modules]="editorOptions" [styles]="{borderRadius: '0px 0px 8px 8px', height: '300px'}" maxlength="5000"
              placeholder="" format="html" [sanitize]="true"></quill-editor>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="!newClient" class="py-4 max-w-full wizard-container">
    <div class="flex justify-between px-4 pt-4">
      <div class="flex items-center">
        <div class="title-candidate-profile">{{"clientDetailRFCData" | translate}}</div> <mat-icon
          *ngIf="company?.comP_ID && !company?.comP_IS_MINI && (company?.comP_CSF_ROUTE ? true : getPermission('m002'))"
          class="ml-2 cursor-pointer" [matMenuTriggerFor]="menu"
          [ngClass]="company?.comP_CSF_ROUTE ? 'file' : 'no-file'">
          description
        </mat-icon> <!-- Constancia situacion fiscal -->

        <mat-menu #menu="matMenu" class="csf-data">
          <div mat-menu-item *ngIf="!company?.comP_CSF_ROUTE && getPermission('m002')" (click)="openCSFDialog()"
            class="flex items-center gap-3">
            <mat-icon>upload</mat-icon>
            <div>{{"uploadCompCSF" | translate}}</div>
          </div>
          <div mat-menu-item *ngIf="company?.comP_CSF_ROUTE && getPermission('m002')" (click)="openCSFDialog()"
            class="flex items-center gap-3">
            <mat-icon>sync</mat-icon>
            <div>{{"changeCompCSF" | translate}}</div>
          </div>
          <div mat-menu-item *ngIf="company?.comP_CSF_ROUTE" (click)="viewCSF(company?.comP_CSF_ROUTE)"
            class="flex items-center gap-3">
            <mat-icon>visibility</mat-icon>
            <div>{{"viewCompCSF" | translate}}</div>
          </div>
        </mat-menu>
      </div>
      <span *appPermission="'m002'">
        <a id="editAdressSection" class="flex items-center" *ngIf="!editSecondSection && !loadingDetail"
          (click)="editAdressSection()">
          <img [src]="editIcon" alt="Icono" class="w-5 h-5 mr-2">
          <span class="edit-text">{{"candidateDetailEditText" | translate}}</span>
        </a>
      </span>
    </div>
    <div *ngIf="!editSecondSection" class="flex flex-col py-3 w-full px-4">
      <div class="flex flex-wrap justify-start -mx-2">
        <div class="px-2 w-full md:w-1/2 lg:w-1/4">
          <label for="razon" class="title-input-text">{{"clientDetailSocialReason" | translate}}</label>
          <div class="px-1 py-3 break-all" name="razon">
            {{ receivedCompany.comP_BUSINESS_NAME }}
          </div>
        </div>
        <div class="px-2 w-full md:w-1/2 lg:w-1/4">
          <label for="rfc" class="title-input-text">{{"clientDetailRFC" | translate}}</label>
          <div class="px-1 py-3 break-all" name="rfc">
            {{ receivedCompany.comP_RFC }}
          </div>
        </div>
        <div class="px-2 w-full md:w-1/2 lg:w-1/4">
          <label for="pais" class="title-input-text">{{"clientDetailCountry" | translate}}</label>
          <div *ngIf="receivedCompany.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID_INFO" class="px-1 py-3 break-all" name="pais">
            {{receivedCompany.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID_INFO.ctR_NAME}}
          </div>
        </div>
        <div class="px-2 w-full md:w-1/2 lg:w-1/4">

          <label for="estado" class="title-input-text">{{"clientDetailState" | translate}}</label>
          <div *ngIf="company.comP_ADDRESS_ID_INFO.adR_STATE_ID == null" class="px-1 py-3 break-all" name="estado">
            {{ receivedCompany.comP_ADDRESS_ID_INFO.adR_STATE_CUSTOM }}
          </div>
          <div *ngIf="company?.comP_ADDRESS_ID_INFO?.adR_STATE_ID != null" class="px-1 py-3 break-all" name="estado">
            {{receivedCompany.comP_ADDRESS_ID_INFO.adR_STATE_ID_INFO.stS_NAME}}
          </div>
        </div>
        <div class="px-2 w-full md:w-1/2 lg:w-1/4">
          <label for="ciudad" class="title-input-text">{{"clientDetailCity" | translate}}</label>
          <div *ngIf="company.comP_ADDRESS_ID_INFO.adR_CITY_ID == null" class="px-1 py-3 break-all" name=" ciudad">
            {{ receivedCompany.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM }}
          </div>
          <div *ngIf="company.comP_ADDRESS_ID_INFO.adR_CITY_ID != null" class="px-1 py-3 break-all" name=" ciudad">
            {{ receivedCompany.comP_ADDRESS_ID_INFO.adR_CITY_ID_INFO.ctY_NAME }}
          </div>
        </div>
        <div class="px-2 w-full md:w-1/2 lg:w-1/4">
          <label for="calle" class="title-input-text">{{"clientDetailStreet" | translate}}</label>
          <div class="px-1 py-3 break-all" name="calle">
            {{ receivedCompany.comP_ADDRESS_ID_INFO.adR_STREET }}
          </div>
        </div>
        <div class="px-2 w-full md:w-1/2 lg:w-1/4">
          <label for="numext" class="title-input-text">{{"clientDetailNumber" | translate}}</label>
          <div name="numext" class="px-1 py-3 break-all">
            {{ receivedCompany.comP_ADDRESS_ID_INFO.adR_STREET_NUMBER }}
          </div>
        </div>
        <div class="px-2 w-full md:w-1/2 lg:w-1/4">
          <label for="numerointerior" class="title-input-text">{{"clientDetailNumberInt" | translate}}</label>
          <div class="px-1 py-3 break-all" name="numerointerior">
            {{ receivedCompany.comP_ADDRESS_ID_INFO.adR_SUITE_NUMBER }}
          </div>
        </div>
        <div class="px-2 w-full md:w-1/2 lg:w-1/4">
          <label for="colonia" class="title-input-text">{{"clientDetailColony" | translate}}</label>
          <div class="px-1 py-3 break-all" name="colonia">
            {{ receivedCompany.comP_ADDRESS_ID_INFO.adR_SUBURB }}
          </div>
        </div>
        <div class="px-2 w-full md:w-1/2 lg:w-1/4">
          <label for="cp" class="title-input-text">{{"clientDetailPostalCode" | translate}}</label>
          <div class="px-1 py-3 break-all" name="cp">
            {{ receivedCompany.comP_ADDRESS_ID_INFO.adR_CP }}
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="editSecondSection">
      <form (ngSubmit)="editOrCreateCompany(myForm, 2)" #myForm="ngForm">
        <div *ngIf="company">
          <div class="flex flex-wrap py-3 w-full px-4">
            <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2">
              <label for="razon" class="title-input-text">{{"clientDetailConfSocialReason" | translate}}</label>
              <input id="clientDetailConfSocialReason" maxlength="100" required [(ngModel)]="company.comP_BUSINESS_NAME"
                type="text" class="input-style" name="razon"
                oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();" />
            </div>
            <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2">
              <label for="rfc" class="title-input-text">{{"clientDetailConfRFC" | translate}}</label>
              <input id="clientDetailConfRFC" maxlength="13" [(ngModel)]="company.comP_RFC" type="text"
                class="input-style" name="rfc"
                oninput="this.value = this.value.replace(/[^a-zA-ZÑñ\d]/g, '').toUpperCase();" />
            </div>
            <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2">
              <label for="pais" class="title-input-text">{{"clientDetailConfCountry" | translate}}</label>
              <mat-select id="clientDetailConfCountry" required
                [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID" (selectionChange)="onSelectCountry()"
                name="pais" id="" class="px-1 border py-2.5 my-1 w-full mat-dropdown" required (focus)="onFocus($event)" (blur)="onBlur($event)">
                <mat-option *ngFor="let country of countries; let i = index" [value]="country.ctR_ID"
                  (click)="loadStates(i)">
                  {{ country.ctR_NAME }}
                </mat-option>
              </mat-select>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2">
              <label for="estado" class="title-input-text">{{"clientDetailConfState" | translate}}</label>
              <mat-select id="clientDetailConfState" (selectionChange)="loadcitiesid($event.value)"
                *ngIf="!customstatesandcities" name="estado" class="px-1 border py-2.5 my-1 w-full mat-dropdown"
                [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_STATE_ID" (focus)="onFocus($event)" (blur)="onBlur($event)">
                <mat-option *ngFor="let state of states; let i = index" [value]="state.stS_ID" (click)="loadCities(i)">
                  {{ state.stS_NAME }}
                </mat-option>
              </mat-select>
              <input id="clientDetailConfStateCustom" maxlength="100" *ngIf="customstatesandcities"
                [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_STATE_CUSTOM" type="text"
                class="px-1 borde py-2 my-1 w-full break-all input-style" name="colonia"
                oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();" />
            </div>
            <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2">
              <label for="ciudad" class="title-input-text">{{"clientDetailConfCity" | translate}}</label>
              <mat-select id="clientDetailConfCity" *ngIf="!customstatesandcities" name="ciudad"
                class="px-1 border py-2.5 my-1 w-full mat-dropdown"
                [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_CITY_ID" (focus)="onFocus($event)" (blur)="onBlur($event)">
                <mat-option *ngFor="let city of cities" [value]="city.ctY_ID">
                  {{ city.ctY_NAME }}
                </mat-option>
              </mat-select>
              <input id="clientDetailConfCityCustom" maxlength="100" *ngIf="customstatesandcities"
                [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM" type="select"
                class="px-1 borde py-2 my-1 w-full break-all input-style" name="ciudad"
                oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();" />
            </div>
            <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2">
              <div class="flex flex-col">
                <label for="calle" class="title-input-text">{{"clientDetailConfStreet" | translate}}</label>
                <input id="clientDetailConfStreet" maxlength="100" [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_STREET"
                  type="text" class="px-1  py-2 my-1 w-full break-all input-style" name="calle"
                  oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();" />
              </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2">
              <div class="flex flex-col">
                <label for="numeroexterior" class="title-input-text">{{"clientDetailConfNumber" | translate}}</label>
                <input id="clientDetailConfNumber" maxlength="25"
                  [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_STREET_NUMBER" type="text"
                  class="px-1  py-2 my-1 w-full break-all input-style" name="numeroexterior"
                  oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();" />
              </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2">
              <div class="flex flex-col">
                <label for="numerointerior" class="title-input-text">{{"clientDetailConfNumberInt" | translate}}</label>
                <input id="clientDetailConfNumberInt" maxlength="25"
                  [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_SUITE_NUMBER" type="text"
                  class="px-1  py-2 my-1 w-full break-all input-style" name="numerointerior"
                  oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();" />
              </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2">
              <div class="flex flex-col">
                <label for="suburb" class="title-input-text">{{"clientDetailConfColony" | translate}}</label>
                <input id="clientDetailConfColony" maxlength="150" [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_SUBURB"
                  type="text" class="px-1  py-2 my-1 w-full break-all input-style" name="suburb"
                  oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();" />
              </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2">
              <div class="flex flex-col">
                <label for="cp" class="title-input-text">{{"clientDetailConfPostalCode" | translate}}</label>
                <input id="clientDetailConfPostalCode" [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_CP"
                  class="px-1  py-2 my-1 w-full break-all input-style" name="cp" maxlength="5"
                  (keypress)="($event.charCode >= 48 && $event.charCode < 58 )" type="text"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '').trimStart();" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex sm:flex-row flex-col sm:px-3 px-6 pb-3">
          <div class="sm:ml-auto sm:mx-3 sm:py-0 mx-0 py-2 ">
            <app-custom-primary-button id="cancelEditDirection" *ngIf="!newClient" size="mini" color="secondary"
              (click)="cancelEditDirection()" class="w-full">
              {{ "clientDetailConfCancel" | translate }}
            </app-custom-primary-button>
          </div>
          <div class="sm:mx-3 sm:py-0 mx-0 py-2">
            <app-custom-primary-button id="clientDetailConfSave" type="submit" size="mini" color="primary"
              (click)="submitted = true" class="w-full" [disabled]="sending">
              <span *ngIf="!sending">{{ id ? ("clientDetailConfSave" | translate) : ("clientDetailConfCreate2" |
                translate) }}</span>
              <div *ngIf="sending" class="w-full flex justify-center">
                <mat-spinner diameter="20" color="primary"></mat-spinner>
              </div>
            </app-custom-primary-button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="edit || newClient">
    <form (ngSubmit)="editOrCreateCompany(myForm)" #myForm="ngForm" class="my-form">
      <div *ngIf="company" class="flex flex-col md:flex-row ">
        <div class="flex flex-col py-3 w-full md:w-1/4 md:pr-6 md:pl-10 px-6"
          style="border-right: 1px solid; border-color: #4a5568">
          <div class="py-2">
            <label for="nombre" class="title-input-text">{{"clientDetailConfName" | translate}}</label>
            <input id="clientDetailConfName2" [(ngModel)]="company.comP_NAME" type="text"
              class="px-1 borde borde py-2 my-1 w-full break-all" style="color: #728096" name="nombre" maxlength="20"
              required />
          </div>

          <div class="py-2">
            <label for="razon" class="title-input-text">{{"clientDetailConfSocialReason" | translate}}</label>
            <input id="clientDetailConfSocialReason2" [(ngModel)]="company.comP_BUSINESS_NAME" type="text"
              class="px-1 borde py-2 my-1 w-full break-all" style="color: #728096" name="razon" maxlength="35"
              required />
          </div>

          <div class="py-2">
            <label for="rfc" class="title-input-text">{{"clientDetailConfRFC" | translate}}</label>
            <input id="clientDetailConfRFC2" maxlength="13" [(ngModel)]="company.comP_RFC" type="text"
              class="px-1 borde py-2 my-1 w-full break-all" style="color: #728096" name="rfc" />
          </div>
          <div class="py-2">
            <label for="limite" class="title-input-text">{{"clientDetailConfUserLimit" | translate}}</label>
            <input id="clientDetailConfUserLimit2" required [disabled]="!superadmin"
              [(ngModel)]="company.comP_USER_LIMIT" type="number" class="px-1 borde py-2 my-1 w-full break-all"
              style="color: #728096" name="limite" inputmode="decimal" min="1" />
          </div>
          <div *ngIf="superadmin" class="py-2">
            <mat-checkbox [(ngModel)]="company.comP_INIT_TRIAL" class="title-input-text">
              {{"clientDetailConfTrial" | translate}}
            </mat-checkbox>
          </div>
        </div>
        <div class="flex flex-col py-3 w-full md:w-3/4">
          <div class="flex flex-col md:flex-row w-full">
            <div class="px-6 py-2 w-full">
              <label for="pais" class="title-input-text">{{"clientDetailConfCountry" | translate}}</label>
              <mat-select id="clientDetailConfCountry2" required
                [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID" name="pais" id=""
                class="px-1 border py-2.5 my-1 w-full" style="color: #728096" required (focus)="onFocus($event)" (blur)="onBlur($event)">
                <mat-option *ngFor="let country of countries; let i = index" [value]="country.ctR_ID"
                  (click)="loadStates(i)">
                  {{ country.ctR_NAME }}
                </mat-option>
              </mat-select>
            </div>
            <div class="px-6 py-2 w-full">
              <label for="estado" class="title-input-text">{{"clientDetailConfState" | translate}}</label>
              <mat-select id="clientDetailConfState2" (selectionChange)="loadcitiesid($event.value)"
                *ngIf="!customstatesandcities" name="estado" id="" class="px-1 border py-2.5 my-1 w-full"
                [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_STATE_ID" style="color: #728096" (focus)="onFocus($event)" (blur)="onBlur($event)">
                <mat-option *ngFor="let state of states; let i = index" [value]="state.stS_ID" (click)="loadCities(i)">
                  {{ state.stS_NAME }}
                </mat-option>
              </mat-select>
              <input id="clientDetailConfStateCustom2" maxlength="35" *ngIf="customstatesandcities"
                [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_STATE_CUSTOM" type="text"
                class="px-1 borde py-2 my-1 w-full break-all" style="color: #728096" name="colonia" />
            </div>
            <div class="px-6 py-2 w-full">
              <label for="ciudad" class="title-input-text">{{"clientDetailConfCity" | translate}}</label>
              <mat-select id="clientDetailConfCity" *ngIf="!customstatesandcities" name="ciudad" id=""
                class="px-1 border py-2.5 my-1 w-full" [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_CITY_ID"
                style="color: #728096" (focus)="onFocus($event)" (blur)="onBlur($event)">
                <mat-option *ngFor="let city of cities" [value]="city.ctY_ID">
                  {{ city.ctY_NAME }}
                </mat-option>
              </mat-select>
              <input id="clientDetailConfCityCustom2" maxlength="35" *ngIf="customstatesandcities"
                [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM" type="select"
                class="px-1 borde py-2 my-1 w-full break-all" style="color: #728096" name="ciudad" />
            </div>
          </div>
          <div class="flex flex-col md:flex-row   w-full  ">
            <div class="px-6 py-2 w-full">
              <label for="calle" class="title-input-text">{{"clientDetailConfStreet" | translate}}</label>
              <input id="clientDetailConfStreet2" [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_STREET" type="text"
                class="px-1 borde py-2 my-1 w-full break-all" style="color: #728096" name="calle" maxlength="35" />
            </div>
            <div class="px-6 py-2 w-full">
              <label for="numeroexterior" class="title-input-text">{{"clientDetailConfNumber" | translate}}</label>
              <input id="clientDetailConfNumber2" maxlength="20"
                [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_STREET_NUMBER" type="text"
                class="px-1 borde py-2 my-1 w-full break-all" style="color: #728096" name="numeroexterior" />
            </div>
            <div class="px-6 py-2 w-full">
              <label for="numerointerior" class="title-input-text">{{"clientDetailConfNumberInt" | translate}}</label>
              <input id="clientDetailConfNumberInt2" maxlength="20"
                [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_SUITE_NUMBER" type="text"
                class="px-1 borde py-2 my-1 w-full break-all" style="color: #728096" name="numerointerior" />
            </div>
          </div>
          <div class="flex flex-col md:flex-row w-full">
            <div class="px-6 py-2 w-full  md:w-1/3">
              <label for="suburb" class="title-input-text">{{"clientDetailConfColony" | translate}}</label>
              <input id="clientDetailConfColony2" maxlength="35" [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_SUBURB"
                type="text" class="px-1 borde py-2 my-1 w-full break-all" style="color: #728096" name="suburb" />
            </div>

            <div class="px-6 py-2 w-full md:w-1/3">
              <label for="cp" class="title-input-text">{{"clientDetailConfPostalCode" | translate}}</label>
              <input id="clientDetailConfPostalCode2" [(ngModel)]="company.comP_ADDRESS_ID_INFO.adR_CP"
                class="px-1 borde py-2 my-1 w-full break-all" style="color: #728096" name="cp" maxlength="5"
                (keypress)="($event.charCode >= 48 && $event.charCode < 58 )" type="text" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex sm:flex-row flex-col md:px-3 px-6 pb-3">
        <div class="md:ml-auto md:mx-3 md:py-0 mx-0 py-2 ">
          <app-custom-primary-button id="clientDetailConfCancel" *ngIf="!newClient" (click)="edit = !edit" size="mini"
            color="secondary" class="w-full">
            {{ "clientDetailConfCancel" | translate }}
          </app-custom-primary-button>
        </div>
        <div class="md:mx-3 md:py-0 mx-0 py-2">
          <app-custom-primary-button id="clientDetailConfSave2" type="submit" size="mini" color="primary"
            (click)="submitted = true" class="w-full" [disabled]="sending">
            <span *ngIf="!sending">{{ id ? ("clientDetailConfSave" | translate) : ("clientDetailConfCreate2" |
              translate) }}</span>
            <div *ngIf="sending" class="w-full flex justify-center">
              <mat-spinner diameter="20" color="primary"></mat-spinner>
            </div>
          </app-custom-primary-button>
        </div>
      </div>

    </form>
  </div>

  <div *ngIf="!newClient && superadmin && !catalogueSection">
    <div class="0 md:px-0 text-3xl pb-3 pt-6" style="color: #718096">
      {{"clientDetailUserList" | translate}}
    </div>

    <div style="border: 1px solid; border-color: #f6f5f5; background-color: white" class="pt-8 px-6 max-w-full">
      <div class="flex md:flex-row flex-col">
        <div class="md:pr-6 md:w-1/3 w-full md:py-0 py-2">
          <label for="nombre" class="gray font-medium">{{"clientDetailUserListName" | translate}}</label>
          <input maxLenght="100" [(ngModel)]="nombre" type="select" class="px-1 borde py-2 my-1 md:w-full w-full"
            placeholder="" name="nombre" (keydown.enter)='Buscar()' />
        </div>
        <div class="md:px-6 md:w-1/3 w-full md:py-0 py-2">
          <label for="estatus" class="gray font-medium">{{"clientDetailUserListLastName" | translate}}</label>
          <mat-select name="estatus" id="" [(ngModel)]="estatus" class="text-center borde py-2 my-1 md:w-full w-full"
            (keydown.enter)='Buscar()' style="height: 38px">
            <mat-option selected value="Todos">{{"clientDetailUserListAll" | translate}}</mat-option>
            <mat-option value="Activos">{{"clientDetailUserListActive" | translate}}</mat-option>
            <mat-option value="Inactivos">{{"clientDetailUserListInactive" | translate}}</mat-option>
          </mat-select>
        </div>
        <div class="md:px-6 md:w-1/3 w-full flex md:flex-row flex-col">
          <div class="md:mx-3 mx-0 flex items-center pt-5 w-full md:w-auto">
            <span class="text-button w-full md:w-auto text-center" (click)="Limpiar()">
              {{ "cleanButton" | translate }}
            </span>
          </div>

          <div class="md:mx-3 mx-0 flex items-center pt-5 w-full md:w-auto">
            <app-custom-primary-button size="mini" color="primary" class="w-full md:w-auto" (click)="Buscar()">
              {{ "searchButton" | translate }}
            </app-custom-primary-button>
          </div>

        </div>
        <div class="flex justify-end items-center md:pt-0 mt-5">
          <a (click)="openAddDialog('Agregar')" mat-mini-fab color=""
            class="ml-auto mat-elevation-z0 flex justify-center items-center"
            style="background-color: var(--color-primario)">
            <mat-icon class="pb-1" style="
                color: white;
                background-color: var(--color-primario);
                width: unset;
                height: unset;
              ">add</mat-icon>
          </a>
        </div>
      </div>

      <div class="w-full pt-6 overflow-auto">
        <mat-table [dataSource]="dataSource" class="w-full" style="border: 1px solid #d7d7d7">
          <ng-container matColumnDef="anU_NAME">
            <mat-header-cell *matHeaderCellDef class=" md:w-1/6 large-cell"
              style="padding: 0px;">{{"clientDetailUserListTable" | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element" class="break-all w-full md:w-auto large-cell">
              <span class="mobile-label w-2/5 md:w-auto">{{"clientDetailUserListTable" | translate}}:</span>
              <span class="md:w-auto w-3/5 text-left">
                <a class="break-all text-left md:text-center md:pl-0 pl-3 inline-block"
                  (click)="openAddDialog('Editar', element.id)">{{
                  element.anU_NAME
                  }}
                </a>
              </span>
            </mat-cell>
          </ng-container>
          <ng-container class="md:w-1/4" matColumnDef="anU_LAST_NAME">
            <mat-header-cell *matHeaderCellDef class=" md:w-1/6 large-cell">{{"clientDetailUserListTableLastName" |
              translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element" class="break-all w-full md:w-auto large-cell">
              <span class="mobile-label w-2/5 md:w-auto">{{"clientDetailUserListTableLastName" | translate}}:</span>
              <span class="md:text-center md:pl-0 pl-3 text-left">
                {{ element.anU_LAST_NAME }}
              </span>
            </mat-cell>
          </ng-container>
          <ng-container class="md:w-1/4" matColumnDef="email">
            <mat-header-cell *matHeaderCellDef class=" md:w-1/6 email-cell">{{"clientDetailUserListTableEmail" |
              translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element" class="break-words w-full md:w-auto email-cell">
              <span class="mobile-label w-2/5 md:w-auto">{{"clientDetailUserListTableEmail" | translate}}:</span>
              <a href="emailto:element.email" class="md:text-center md:pl-0 pl-3 text-left">
                {{ element.email }}
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="roleName">
            <mat-header-cell *matHeaderCellDef class=" md:w-1/6 medium-cell">{{"clientDetailUserListTableRol" |
              translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element" class="break-all w-full md:w-auto medium-cell">
              <span class="mobile-label w-2/5 md:w-auto">{{"clientDetailUserListTableRol" | translate}}:</span>
              <span class="md:text-center md:pl-0 pl-3 text-left">
                {{ getTranslatedRole(element.roleName) }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="anU_ACTIVE_DISPLAY">
            <mat-header-cell *matHeaderCellDef class=" md:w-1/6 smaller-cell">{{"clientDetailUserListTableStatus" |
              translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element" class="break-words w-full md:w-auto smaller-cell">
              <span class="mobile-label w-2/5 md:w-auto">{{"clientDetailUserListTableStatus" | translate}}:</span>
              <span class="md:text-center md:pl-0 pl-3 text-left">
                {{ getTranslatedStatus(element.anU_ACTIVE_DISPLAY) }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="contra">
            <mat-header-cell *matHeaderCellDef class=" md:w-1/6 smaller-cell">{{"clientDetailUserListTablePassword" |
              translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element" class="break-all w-full md:w-auto smaller-cell">
              <span class="mobile-label w-2/5 md:w-auto">{{"clientDetailUserListTablePassword" | translate}}:</span>
              <a class="flex items-center justify-center md:pl-0 pl-3" (click)="openPassDialog(element)">
                <img [src]="passwordIcon" class="svg-size">
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="accion">
            <mat-header-cell *matHeaderCellDef class=" md:w-1/6 smaller-cell"
              style="padding: 0px;">{{"clientDetailUserListTableActions" | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element" class="break-all w-full md:w-auto smaller-cell">
              <span class="mobile-label w-2/5 md:w-auto">{{"clientDetailUserListTableActions" | translate}}:</span>
              <a *ngIf="element.anU_ACTIVE_DISPLAY == 'Activo'" class="flex items-center justify-center md:pl-0 pl-3"
                (click)="openConfirmDialog('Eliminar', element)">
                <mat-icon class="material-icons-outlined trash-icon">
                  delete_forever
                </mat-icon>
              </a>
              <a *ngIf="element.anU_ACTIVE_DISPLAY == 'Inactivo'" class="flex items-center justify-center md:pl-0 pl-3"
                (click)="openConfirmDialog('Habilitar', element)">
                <mat-icon class="material-icons-outlined refresh-icon">
                  autorenew
                </mat-icon>
              </a>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
          [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex">
        </mat-paginator>
      </div>
    </div>
  </div>

</div>