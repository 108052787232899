import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Branch } from 'src/app/interfaces/branch';
import { Department } from 'src/app/interfaces/department';
import { Job } from 'src/app/interfaces/job';
import { Vacancy } from 'src/app/interfaces/vacancy';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { CustomDropdownComponent } from 'src/app/common/components/catalogs/custom-dropdown/custom-dropdown.component';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { VacancyDialogData } from 'src/app/interfaces/VacancyDialogData';
import { Router } from '@angular/router';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CurrencyPipe } from '@angular/common';
import { FreemiumDialogComponent } from '../freemium-dialog/freemium-dialog.component';
import { APIResponse } from 'src/app/interfaces/response';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { CustomTourService } from 'src/app/services/custom-tour.service';
import { AddOrEditBranchComponent } from '../add-or-edit-branch/add-or-edit-branch.component';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AddOrEditJobComponent } from '../add-or-edit-job/add-or-edit-job.component';

@Component({
  selector: 'app-vacancy-dialog',
  templateUrl: './vacancy-dialog.component.html',
  styleUrls: ['./vacancy-dialog.component.css']
})

export class VacancyDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('autoTrigger', { read: MatAutocompleteTrigger }) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChildren(MatAutocomplete) autoCompletes: QueryList<MatAutocomplete>;
  @ViewChildren(MatAutocompleteTrigger) autoCompleteTriggers: QueryList<MatAutocompleteTrigger>;
  @ViewChild('inputElement') inputElement: ElementRef;
  @ViewChild('select1') select1: ElementRef;
  @ViewChild('select2') select2: ElementRef;
  @ViewChild('select3') select3: ElementRef;
  @ViewChild('select4') select4: ElementRef;
  @ViewChild('select5') select5: ElementRef;
  public id: string;
  jobHasQuest: boolean = false;
  vacancyQuestEnabled: boolean = false;
  jobs: Job[];
  departments: Department[];
  branchs: Branch[];
  paymentPeriods: any[];
  jobId: string;
  departmentId: string;
  selectedPaymentPeriod: string;
  branchId: string
  directBoss: string = '';
  allFieldsFilled = false;
  showErrorMessage: boolean = false;
  errorMessage: string = '';
  closeIcon: SafeResourceUrl = '../../../assets/icons/close-icon.svg'
  dangerousIcon: SafeResourceUrl = '../../../assets/icons/warning-icon.svg'
  isEditMode: boolean = false;
  existingVacancy?: Vacancy;
  vacancyStatus: string = '';
  isDeleteMode: boolean = false;
  isCloseMode: boolean = false;
  isDuplicateMode: boolean = false;
  allUsers: any[] = [];
  usuarios = [];
  recruitersList = [];
  recruiterListFiltered = [];
  usuariosFiltrados = [];
  selectedUsers = [];
  length = 0;
  pageSize = 1000;
  pageIndex = 0;
  currentUser: string;
  inputText: string = '';
  lastAtPos: number = -1;
  isUserMentioned: boolean = false;
  previousInputValue: string = '';
  formattedSalary: string = '';
  actualSalary: number = 0;
  vacancyCount: number = 1;
  salaryType: boolean = false;
  vacancyCountType: boolean = false;
  disabledVacancyCount: boolean = false;
  minDate = new Date().toLocaleDateString().split('T')[0];
  openVacancyDate: string | Date;
  closeVacancyDate: string | Date;
  editModeActive: boolean = false;
  creator: string = '';
  vacancyCountIlimitado: string;
  mainRecruiter: any;
  lastMainRecruiter: any;
  disabledSend: boolean = false;
  editIcon: SafeResourceUrl = '../../../assets/icons/edit-icon.svg';
  jobError: boolean = false;
  branchError: boolean = false;
  departmentError: boolean = false;
  bossError: boolean = false;
  salaryVisibility: boolean = true;
  companyVisibility: boolean = true;
  isFreemium: boolean = false;
  isHaibuJobs: boolean = false;
  isTelevisa: boolean = false;
  companyName: string = '';
  companyId: string = '';
  token: APIResponse = this.localService.getJsonValue('token');
  modalities: any[];
  jobTypes: any[];
  selectedModality: any;
  selectedJobType: any;

  filteredJobs: Observable<any[]>;
  filteredBranches: Observable<any[]>;
  filteredDepartments: Observable<any[]>;
  jobControl = new FormControl();
  branchControl = new FormControl();
  departmentControl = new FormControl();
  inputControl = new FormControl();

  constructor(
    private puzzleService: PuzzleService,
    private localService: LocalService,
    public dialogRef: MatDialogRef<VacancyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe,
    private renderer: Renderer2,
    public dialog: MatDialog,
    private customTourService: CustomTourService
  ) {
    this.currentUser = this.localService.getJsonValue('token')?.data.id.toLowerCase();
  }

  ngOnInit(): void {
    this.vacancyCountIlimitado = this.translate.instant('Ilimitado');
    const now = new Date();

    // Obtener fecha local en formato "YYYY-MM-DD"
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Mes empieza desde 0
    const day = now.getDate().toString().padStart(2, '0');

    // Combinar fecha y hora local
    this.openVacancyDate = `${year}-${month}-${day}`;

    this.isFreemium = this.token.data.isFreemium;
    this.isHaibuJobs = this.token.data.isHaibuJobs;
    this.companyName = this.token.data.companyName;
    this.companyId = this.token.data.companyId
    this.isTelevisa = this.token.data.companyId === 'f928ebd8-7181-44a5-9de5-fa8868062713' || this.token.data.companyId === '78445aab-c168-4e55-865d-16321c4a685e';

    this.getUsers();
    this.getUsersRecruiters();
    this.getJobs();
    this.getDepartmentsOrdered();
    this.getBranchsOrdered();
    this.getUsername();
    this.getPaymentPeriods();
    this.getModalities();
    this.getJobTypes();

    if (this.data) {
      const { action, existingVacancy } = this.data as VacancyDialogData;
      this.isEditMode = action === 'edit';
      this.isDeleteMode = action === 'delete';
      this.isCloseMode = action === 'close';
      this.isDuplicateMode = action === 'duplicate';

      if (existingVacancy && (this.isEditMode || this.isCloseMode || this.isDeleteMode || this.isDuplicateMode)) {
        this.initializeFormWithExistingVacancy(existingVacancy);

        if (this.isEditMode) {
          if (this.editModeActive) {
            this.jobControl.enable();
            this.departmentControl.enable();
            this.branchControl.enable();
            this.inputControl.enable();
          } else {
            this.jobControl.disable();
            this.departmentControl.disable();
            this.branchControl.disable();
            this.inputControl.disable();
          }
        }
      }
    } else {
      this.jobControl.valueChanges.subscribe(value => {
        const isFilled = this.jobId ? true : false;
        this.customTourService.nombreFieldStatus$.next(isFilled);
      });

      this.branchControl.valueChanges.subscribe(value => {
        const isFilled = this.branchId ? true : false;
        this.customTourService.descriptionFieldStatus$.next(isFilled);
      });

      this.departmentControl.valueChanges.subscribe(value => {
        const isFilled = this.departmentId ? true : false;
        this.customTourService.departmentFieldStatus$.next(isFilled);
      });

      this.inputControl.valueChanges.subscribe(value => {
        const isFilled = value && value.trim().length > 0;
        this.customTourService.bossFieldStatus$.next(isFilled);
      });
    }
  }

  ngAfterViewInit(): void {
    this.applyInitialStylesAndValues();
  }

  private _filterJobs(value: string): any[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.jobs.filter(job => job.joB_NAME.toLowerCase().includes(filterValue));
    } else {
      return this.jobs;
    }
  }

  private _filterBranches(value: string): any[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.branchs.filter(branch => branch.broF_NAME.toLowerCase().includes(filterValue));
    } else {
      return this.branchs;
    }
  }

  private _filterDepartments(value: string): any[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.departments.filter(department => department.deP_NAME.toLowerCase().includes(filterValue));
    } else {
      return this.departments;
    }
  }

  private applyInitialStylesAndValues(): void {
    if (this.directBoss) {
      this.inputElement.nativeElement.value = this.directBoss;
      this.inputElement.nativeElement.classList.add('text-orange');
      this.previousInputValue = this.directBoss;
      this.cdr.detectChanges(); // Asegúrate de que los cambios son detectados
    }
  }



  onInput(value: string): void {
    this.bossError = false;
    if (value === this.previousInputValue) {
      return;
    }
    this.previousInputValue = value;

    const cursorPosition = this.inputElement.nativeElement.selectionStart;
    const lastAtPos = value.lastIndexOf('@', cursorPosition);

    this.isUserMentioned = lastAtPos !== -1 && cursorPosition > lastAtPos;

    if (this.isUserMentioned) {
      const substringQuery = value.substring(lastAtPos + 1, cursorPosition);
      if (substringQuery.length === 0) {
        this.usuariosFiltrados = this.usuarios.filter(usuario => !this.selectedUsers.some(user => user.id === usuario.id));
        setTimeout(() => this.autocompleteTrigger.openPanel(), 1);
      } else {
        this.usuariosFiltrados = this.usuarios.filter(usuario =>
          usuario.nombre.toLowerCase().includes(substringQuery.toLowerCase()) &&
          !this.selectedUsers.some(user => user.id === usuario.id)
        );
      }
      // filtrar lista de usuarios
      this.usuariosFiltrados = this.usuarios.filter(usuario =>
        usuario.nombre.toLowerCase().includes(substringQuery.toLowerCase())
      );
    } else {
      this.isUserMentioned = false;
      this.autocompleteTrigger.closePanel();
      // si habia un direct boss y se borra, se quita de selectedUsers
      const directBossId = this.usuarios.find(user => user.nombre === this.directBoss)?.id;
      if (directBossId && !value.includes(this.directBoss)) {
        this.selectedUsers = this.selectedUsers.filter(user => user.id !== directBossId);
        this.updateFilteredUsers();
      }
      this.directBoss = value;
    }
    this.bossError = !this.directBoss || this.directBoss === undefined || this.directBoss === '';
    this.checkAndRemoveUnmentionedUsers(value);
    this.checkAndAdjustInputStyle(value);
    this.cdr.detectChanges();
  }

  onChangeVacancyCountType(event: MatSelectChange) {
    this.disabledVacancyCount = event.value;
  }

  private checkAndAdjustInputStyle(value: string): void {
    if (!this.selectedUsers.some(user => user.nombre === value)) {
      this.inputElement.nativeElement.classList.remove('text-orange');
    }
  }

  private checkAndRemoveUnmentionedUsers(value: string): void {
    if (this.directBoss && value !== this.directBoss) {
      this.selectedUsers = this.selectedUsers.filter(user => user.nombre !== this.directBoss);
      this.updateFilteredUsers();
      this.directBoss = '';
      this.inputElement.nativeElement.classList.remove('text-orange');
    }
  }

  onUserSelect(userName: string): void {
    const selectedUser = this.usuarios.find(user => user.nombre === userName);
    if (selectedUser && !this.selectedUsers.some(user => user.id === selectedUser.id)) {
      this.selectedUsers.push(selectedUser);
      this.updateFilteredUsers();
    }
    this.selectedUsers = this.selectedUsers/* .filter(usuario => this.currentUser !== usuario.id); */
    this.directBoss = userName;
    this.inputElement.nativeElement.value = userName;
    this.inputElement.nativeElement.classList.add('text-orange');
    this.previousInputValue = this.inputElement.nativeElement.value;
    this.cdr.detectChanges();
  }

  updateFilteredUsers(): void {
    const value = this.inputText || '';
    const cursorPosition = this.inputElement.nativeElement.selectionStart;
    const lastAtPos = value.lastIndexOf('@', cursorPosition - 1);
    if (lastAtPos !== -1 && cursorPosition > lastAtPos + 1) {
      const substringQuery = value.substring(lastAtPos + 1, cursorPosition);
      this.usuariosFiltrados = this.usuarios.filter(usuario =>
        usuario.nombre.toLowerCase().includes(substringQuery.toLowerCase()) &&
        !this.selectedUsers.some(selectedUser => selectedUser.id === usuario.id)
      );
    } else {
      this.usuariosFiltrados = this.usuarios.filter(usuario =>
        !this.selectedUsers.some(selectedUser => selectedUser.id === usuario.id)
      );
    }
  }

  updateFilteredRecruiters(): void {
    // this.recruiterListFiltered = this.recruitersList.filter(usuario => usuario.id !== this.mainRecruiter?.id);
  }

  onParticipantSelected(event: MatSelectChange): void {
    if (this.isFreemium) {
      this.dialog.open(FreemiumDialogComponent, {
        panelClass: 'freemium-dialog',
        width: '500px',
        maxWidth: '95vw',
      });
      return;
    }
    const user = event.value;
    if (!this.selectedUsers.some(u => u.id === user.id)) {
      this.selectedUsers.push(user);
      this.updateFilteredUsers();
    }
  }

  onParticipantSelected2(event: MatSelectChange): void {
    const user = event.value;
    // si el main recruiter cambia, se elimina de selectedUsers
    if (this.lastMainRecruiter && this.selectedUsers.some(u => u.id === this.lastMainRecruiter.id)) {
      this.selectedUsers = this.selectedUsers.filter(u => u.id !== this.lastMainRecruiter.id);
      this.updateFilteredRecruiters();
    }
    if (!this.selectedUsers.some(u => u.id === user.id)) {
      this.selectedUsers.push(user);
      this.updateFilteredRecruiters();
      this.lastMainRecruiter = user
    }
    this.selectedUsers = this.selectedUsers/* .filter(usuario => this.currentUser !== usuario.id); */
  }

  removeUser(userObject: any) {
    if (this.directBoss === userObject.nombre) {
      this.directBoss = '';
      if (this.inputElement) { // Asegúrate de que inputElement se ha inicializado
        this.inputElement.nativeElement.classList.remove('text-orange');
      }
    }

    const isDirectBossInList = this.selectedUsers.some(user => user.nombre === this.directBoss);
    if (isDirectBossInList) {
      if (this.inputElement) { // Asegúrate de que inputElement se ha inicializado
        this.inputElement.nativeElement.value = this.directBoss;
        this.inputElement.nativeElement.classList.add('text-orange');
      }
      this.previousInputValue = this.directBoss;
    } else {
      if (this.inputElement) { // Asegúrate de que inputElement se ha inicializado
        this.inputElement.nativeElement.value = this.directBoss;
        this.inputElement.nativeElement.classList.remove('text-orange');
      }
    }

    this.selectedUsers = this.selectedUsers.filter(user => user.id !== userObject.id);

    this.usuariosFiltrados = this.usuarios.filter(usuario =>
      !this.selectedUsers.some(selectedUser => selectedUser.id === usuario.id)
    );

    this.updateFilteredUsers();
  }

  getUsers(): void {
    if (this.usuarios.length === 0) {
      this.puzzleService.getAllUsers(this.pageSize, this.pageIndex, null, 'Activos', this.id, null, true).subscribe(users => {

        this.allUsers = users.map(user => ({
          id: user.id.toLowerCase(),
          nombre: user.anU_FULL_NAME,
          email: user.email
        }));
        /* if (this.existingVacancy && (this.isEditMode || this.isCloseMode || this.isDeleteMode || this.isDuplicateMode)) {
          this.mainRecruiter = this.allUsers.find(user => user.id === this.existingVacancy.vacanT_MAIN_RECRUITER);
        } */
        this.usuarios = this.allUsers/* .filter(user => user.id !== this.currentUser) */

        // quitar si ya se selecciono
        this.usuariosFiltrados = this.usuarios.filter(usuario =>
          !this.selectedUsers.find(selectedUser => selectedUser.id === usuario.id) /* && usuario.id !== this.currentUser */
        );

        try {
          this.length = users[0].totaL_COUNT;
        } catch (error) {
          this.length = 0;
        }
      }, error => {
      });
    }
  }

  getUsersRecruiters(): void {
    if (this.usuarios.length === 0) {
      this.puzzleService.getAllUsers(this.pageSize, this.pageIndex, null, 'Activos', this.id, null, true, false, true).subscribe(users => {

        this.recruitersList = users.map(user => ({
          id: user.id.toLowerCase(),
          nombre: user.anU_FULL_NAME,
          email: user.email
        }));
        if (this.existingVacancy && (this.isEditMode || this.isCloseMode || this.isDeleteMode || this.isDuplicateMode)) {
          this.mainRecruiter = this.recruitersList.find(user => user.id === this.existingVacancy.vacanT_MAIN_RECRUITER);
        }

        // quitar si ya se selecciono
        // this.recruiterListFiltered = this.recruitersList.filter(usuario => usuario.id !== this.mainRecruiter?.id);

      }, error => {
      });
    }
  }

  getModalities(): void {
    this.puzzleService.getWorkingModality().subscribe(modalities => {
      this.modalities = modalities;
      if (this.existingVacancy?.workingModalityInfo?.id) {
        this.selectedModality = this.modalities?.filter(modality => modality.id === this.existingVacancy?.workingModalityInfo?.id)[0];
      } else {
        this.selectedModality = this.modalities?.filter(modality => modality.code === 'PRE')[0];
      }

    });
  }

  getJobTypes(): void {
    this.puzzleService.getWorkingTypes().subscribe(jobTypes => {
      this.jobTypes = jobTypes;
      if (this.existingVacancy?.workingTypeInfo?.id) {
        this.selectedJobType = this.jobTypes?.filter(jobType => jobType.id === this.existingVacancy?.workingTypeInfo?.id)[0];
      } else {
        this.selectedJobType = this.jobTypes?.filter(jobType => jobType.code === 'TC')[0];
      }
    });
  }

  toggleSalaryVisibility() {
    this.salaryVisibility = !this.salaryVisibility;
  }

  toggleCompanyVisibility() {
    this.companyVisibility = !this.companyVisibility;
  }

  private initializeFormWithExistingVacancy(vacancy: Vacancy): void {
    this.existingVacancy = vacancy;
    this.salaryVisibility = vacancy.vacanT_SALARY_MICROSITE_VISIBILITY;
    this.companyVisibility = vacancy.vacanT_COMPANY_NAME_VISIBILITY;
    this.vacancyQuestEnabled = vacancy.vacanT_QUESTIONNAIRE_VISIBILITY;
    this.jobId = vacancy.vacanT_JOB_ID;
    this.jobControl.setValue(vacancy.jobInfo?.joB_NAME);
    this.departmentId = vacancy.vacanT_DEP_ID;
    this.departmentControl.setValue(vacancy.departmentInfo?.deP_NAME);
    this.selectedPaymentPeriod = vacancy.vacanT_PAYMENT_PERIOD;
    this.directBoss = vacancy.vacanT_DIRECT_BOSS;
    this.retryDirectBossUpdate();
    this.vacancyStatus = vacancy.vacanT_ACTIVE ? 'open' : 'closed';
    this.branchId = vacancy.vacanT_BROF_ID;
    this.branchControl.setValue(vacancy.branchOfficeInfo?.broF_NAME);
    this.hasQuestionnaire(this.jobId);

    if (vacancy?.workingModalityInfo?.id) {
      this.selectedModality = this.modalities?.filter(modality => modality.id === vacancy?.workingModalityInfo?.id)[0];
    } else {
      this.selectedModality = this.modalities?.filter(modality => modality.code === 'PRE')[0];
    }
    if (vacancy?.workingTypeInfo?.id) {
      this.selectedJobType = this.jobTypes?.filter(jobType => jobType.id === vacancy?.workingTypeInfo?.id)[0];
    } else {
      this.selectedJobType = this.jobTypes?.filter(jobType => jobType.code === 'TC')[0];
    }

    if (vacancy.vacanT_INITIAL_DATE && !this.isDuplicateMode) {
      this.openVacancyDate = vacancy.vacanT_INITIAL_DATE.toString().split('T')[0];
    } else {
      this.openVacancyDate = new Date().toLocaleDateString().split('T')[0];
    }

    // si la fecha tiene formato 1/24/2025 se cambia a 2025-01-24
    if (this.openVacancyDate.toString().includes('/')) {
      const date = this.openVacancyDate.toString().split('/');
      this.openVacancyDate = date[2] + '-'  + date[1].padStart(2, '0') + '-' + date[0].padStart(2, '0')
    }

    if (vacancy.vacanT_CLOSE_DATE) {
      this.closeVacancyDate = vacancy.vacanT_CLOSE_DATE.toString().split('T')[0];
    } else {
      this.closeVacancyDate = vacancy.vacanT_CLOSE_DATE;
    }
    if (this.isEditMode) {
      this.minDate = new Date(vacancy.vacanT_CREATED).toLocaleDateString().split('T')[0];
    } else if (this.isDuplicateMode) {
      this.minDate = new Date().toLocaleDateString().split('T')[0];
    }
    this.actualSalary = vacancy.vacanT_SALARY;
    this.formattedSalary = this.currencyPipe.transform(this.actualSalary, 'USD', 'symbol', '1.2-2');
    this.salaryType = vacancy.vacanT_SALARY_TYPE ? vacancy.vacanT_SALARY_TYPE : false;
    this.vacancyCount = vacancy.vacanT_NUMBER;
    if (this.vacancyCount <= 0) {
      this.vacancyCount = 1;
    }
    this.vacancyCountType = vacancy.vacanT_NUMBER_TYPE ? vacancy.vacanT_NUMBER_TYPE : false;
    this.disabledVacancyCount = vacancy.vacanT_NUMBER_TYPE ? vacancy.vacanT_NUMBER_TYPE : false;
    this.creator = vacancy.vacantCreatedBy.anU_NAME + ' ' + vacancy.vacantCreatedBy.anU_LAST_NAME;

    const usersList = vacancy.participants/* .filter(user => user.participanT_USER_ID.toLowerCase() !== this.currentUser) */
    this.selectedUsers = usersList.map(participant => ({
      id: participant.participanT_USER_ID.toLowerCase(),
      nombre: participant.participanT_FULL_NAME,
      email: ''
    }));

    this.usuariosFiltrados = this.usuarios.filter(usuario => usuario.nombre !== this.directBoss);

    this.mainRecruiter = this.recruitersList.find(user => user.id === vacancy.vacanT_MAIN_RECRUITER);

    this.directBoss = this.transformValue(vacancy.vacanT_DIRECT_BOSS);
  }

  retryDirectBossUpdate(maxAttempts: number = 10, delay: number = 100): void {
    let attempts = 0;

    const tryUpdate = () => {
      if (this.directBoss && this.inputElement) {
        // Lógica principal
        this.inputElement.nativeElement.value = this.directBoss;
        this.inputElement.nativeElement.classList.add('text-orange');
        this.previousInputValue = this.directBoss; // Importante para detectar cambios
        this.checkAndAdjustInputStyle(this.directBoss); // Ajuste de estilo
      } else if (attempts < maxAttempts) {
        // Reintentar si no se cumple la condición
        attempts++;
        setTimeout(tryUpdate, delay);
      } else {
        // Log cuando no se puede actualizar
        console.warn('Failed to update input element after maximum retries');
      }
    };

    tryUpdate();
  }

  closeErrorModal() {
    this.showErrorMessage = false;
  }

  getJobs() {
    this.puzzleService
      .getJobs(this.localService.getJsonValue('token')?.data.companyId)
      .subscribe((response) => {
        this.jobs = response;
        this.hasQuestionnaire(this.jobId);
        this.filteredJobs = this.jobControl.valueChanges.pipe(
          startWith(''),
          tap(value => {
            // Verificar si el valor ingresado coincide con alguna opción de jobs
            const matchingJob = this.jobs.find(job => job.joB_NAME.toLowerCase().trim() === this.jobControl.value?.toLowerCase().trim());
            this.jobId = matchingJob ? this.jobId : null; // Asigna el ID si coincide, o null si no coincide
          }),
          map(value => this._filterJobs(this.jobControl.value))
        );

        this.initAutocompletes();
      });
  }

  initAutocompletes() {
    if (this.autoCompletes) {
      this.autoCompletes.forEach((autoComplete, index) => {
        autoComplete.opened.subscribe(() => {
          setTimeout(() => {
            const overlayContainer = document.querySelector('.cdk-overlay-container') as HTMLElement;

            // Crear el elemento `tourOverlay` si no existe
            let tourOverlay = document.getElementById('tourOverlay');

            // Agregar el overlay al `cdk-overlay-container` o al `body`
            if (tourOverlay && overlayContainer) {
              overlayContainer.appendChild(tourOverlay);
            }
          }, 0);
        });

        autoComplete.closed.subscribe(() => {
        });
      });
    }
  }

  checkIfAllFieldsFilled() {
    this.allFieldsFilled =
      this.jobId && this.jobId.trim() !== '' &&
      this.departmentId && this.departmentId.trim() !== '' &&
      this.branchId && this.branchId.trim() !== '' &&
      this.directBoss && this.directBoss.trim() !== '';
  }


  getDepartmentsOrdered() {
    this.puzzleService
      .getDepartmentsOrdered(
        this.localService.getJsonValue('token')?.data.companyId
      )
      .subscribe((response) => {
        this.departments = response;
        this.filteredDepartments = this.departmentControl.valueChanges.pipe(
          startWith(''),
          tap(value => {
            const matchingDepartment = this.departments.find(department => department.deP_NAME.toLowerCase().trim() === this.departmentControl.value?.toLowerCase().trim());
            this.departmentId = matchingDepartment ? this.departmentId : null;
          }),
          map(value => this._filterDepartments(this.departmentControl.value))
        );
        this.initAutocompletes();
      });
  }

  getBranchsOrdered() {
    this.puzzleService
      .getBranchsOrdered(this.localService.getJsonValue('token')?.data.companyId)
      .subscribe((response) => {
        this.branchs = response;
        this.filteredBranches = this.branchControl.valueChanges.pipe(
          startWith(''),
          tap(value => {
            const matchingBranch = this.branchs.find(branch => branch.broF_NAME.toLowerCase().trim() === this.branchControl.value?.toLowerCase().trim());
            this.branchId = matchingBranch ? this.branchId : null;
          }),
          map(value => this._filterBranches(this.branchControl.value))
        );
        this.initAutocompletes();
      });
  }

  getPaymentPeriods() {
    this.puzzleService.getPaymentPeriods().subscribe((response) => {
      this.paymentPeriods = response;
      if (!this.selectedPaymentPeriod) {
        this.selectedPaymentPeriod = this.paymentPeriods.filter(period => period.pyP_NAME === 'Mensual')[0].pyP_ID;
      }
    });
  }

  get showCatalogsEmptyMessage(): boolean {
    return (this.jobs && this.jobs.length === 0) ||
      (this.departments && this.departments.length === 0) ||
      (this.branchs && this.branchs.length === 0);
  }

  onJobSelected(event: MatAutocompleteSelectedEvent) {
    this.jobError = false;
    this.jobId = event.option.value;
    this.jobControl.setValue(event.option.viewValue);
    this.hasQuestionnaire(this.jobId);
    this.checkIfAllFieldsFilled();

    const job = this.jobs.find(job => job.joB_ID === this.jobId);
    // si no tiene descripcion se abre un dialogo para agregarla
    if (!job?.joB_DESCRIPTION) {
      this.openEditOrAddDialog('Editar', job);
      this.openResponseDialog('Error', this.translate.instant('jobWithoutDescription'));
    }
  }

  openEditOrAddDialog(accion: string, job?: Job) {
    let dataToSend: Job | string;
    if (accion === 'Agregar') {
      dataToSend = this.id;
    } else if (accion === 'Editar' && job) {
      dataToSend = job;
    }

    const dialogRef = this.dialog.open(AddOrEditJobComponent, {
      width: '1000px',
      maxWidth: '95vw',
      data: dataToSend
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (Array.isArray(result)) {
        this.handleDialogResults(result);
      } else if (result) {
        this.handleDialogResult(result);
      }

      this.puzzleService
        .getJobs(this.localService.getJsonValue('token')?.data.companyId)
        .subscribe((response) => {
          this.jobs = response;
          // si el job seleccionado no tiene descripcion se quita la seleccion
          const job2 = this.jobs.find(job => job.joB_ID === this.jobId);
          if (!job2?.joB_DESCRIPTION) {
            this.jobId = null;
            this.jobControl.setValue('');
          }
        });
    });
  }

  onDepartmentSelected(event: MatAutocompleteSelectedEvent) {
    this.departmentError = false;
    this.departmentId = event.option.value;
    this.departmentControl.setValue(event.option.viewValue);
    this.checkIfAllFieldsFilled();
  }

  onBranchSelected(event: MatAutocompleteSelectedEvent) {
    this.branchError = false;
    this.branchId = event.option.value;
    this.branchControl.setValue(event.option.viewValue);
    this.checkIfAllFieldsFilled();
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.addVacancy();
    }
  }

  openBranchModal(branch: any) {
    let data: string[] = [];
    data.push(this.translate.instant('setBranchLocation'));
    data.push(this.translate.instant('setBranchLocationText'));
    data.push('true');
    data.push(this.translate.instant('setBranchBtn'));

    let kdd = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: data,
      disableClose: true
    });

    kdd.afterClosed().subscribe((result) => {
      if (result) {
        const addDialogRef = this.dialog.open(AddOrEditBranchComponent, {
          width: '700px',
          maxWidth: '95vw',
          data: branch
        });

        addDialogRef.afterClosed().subscribe(result => {
          if (Array.isArray(result)) {
            this.handleDialogResults(result);
          } else if (result) {
            this.handleDialogResult(result);
          }
        });
      }
    });
  }

  handleDialogResults(results: any[]) {
    let successMessages = new Set<string>();
    let errorMessages = new Set<string>();
    let arraySuccess: string[] = [];
    let arrayError: string[] = [];

    for (const response of results) {
      if (response.isSuccess) {
        successMessages.add(response.message);
        arraySuccess.push(response.name);
      } else {
        errorMessages.add(response.message);
        arrayError.push(response.name);
      }
    }

    let arrayTextSuccess = arraySuccess.join(', ');
    let arrayTextError = arrayError.join(', ');

    if (arrayTextSuccess) {
      arrayTextSuccess += '.';
    }

    if (arrayTextError) {
      arrayTextError += '.';
    }

    if (successMessages.size > 0) {
      this.openResponseDialog('Exito', Array.from(successMessages).join('\n'), arrayTextSuccess);
    }

    if (errorMessages.size > 0) {
      this.openResponseDialog('Error', Array.from(errorMessages).join('\n'), arrayTextError);
    }

    this.getBranchsOrdered();
  }

  handleDialogResult(result: any) {
    if (result.isSuccess) {
      this.openResponseDialog('Exito', result.message);
    } else {
      this.openResponseDialog('Error', result.message);
    }

    this.getBranchsOrdered();
  }

  openResponseDialog(response: string, message?: string, extra?: string) {
    let data: string[] = [];
    if (response == 'Error') {
      data.push('Error');
      data.push(message);
      if (extra) data.push(extra);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    } else if (response == 'Exito') {
      data.push('Exito');
      data.push(message);
      if (extra) data.push(extra);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    }
  }

  addVacancy() {

    if (this.disabledSend) {
      return;
    }
    this.checkIfAllFieldsFilled();
    this.checkErrors();

    if (!this.allFieldsFilled) {
      this.showErrorMessage = true;
      this.translate.get('allFieldsRequired').subscribe((res: string) => {
        this.errorMessage = res;
      });
      return;
    }

    const branchObject = this.branchs.find(branch => branch.broF_ID === this.branchId);

    if (!branchObject?.broF_CITY) {
      this.openBranchModal(branchObject);
      return;
    }

    const job = this.jobs.find(job => job.joB_ID === this.jobId);
    // si no tiene descripcion se abre un dialogo para agregarla
    if (!job?.joB_DESCRIPTION) {
      this.openEditOrAddDialog('Editar', job);
      this.openResponseDialog('Error', this.translate.instant('jobWithoutDescription'));
      return;
    }

    this.disabledSend = true;

    // agregar hora actual a openVacancyDate y closeVacancyDate
    if (this.openVacancyDate) {
      const now = new Date();
      const localTime = now.toTimeString().split(' ')[0]; // Devuelve "HH:mm:ss"
      this.openVacancyDate += 'T' + localTime;
    }

    if (this.closeVacancyDate) {
      const now = new Date();
      const localTime = now.toTimeString().split(' ')[0]; // Devuelve "HH:mm:ss"
      this.closeVacancyDate += 'T' + localTime;
    }

    const vacancyData = {
      VACANT_ID: this.existingVacancy && this.existingVacancy.vacanT_ID ? this.existingVacancy.vacanT_ID : undefined,
      VACANT_COMPANY_ID: this.localService.getJsonValue('token')?.data.companyId,
      VACANT_DEP_ID: this.departmentId,
      VACANT_BROF_ID: this.branchId,
      VACANT_JOB_ID: this.jobId,
      VACANT_DIRECT_BOSS: this.directBoss,
      VACANT_ACTIVE: this.existingVacancy ? this.existingVacancy.vacanT_ACTIVE : true,
      VACANT_SALARY: this.actualSalary,
      VACANT_SALARY_TYPE: this.salaryType,
      VACANT_NUMBER: this.vacancyCount,
      VACANT_NUMBER_TYPE: this.vacancyCountType,
      VACANT_INITIAL_DATE: this.openVacancyDate,
      VACANT_CLOSE_DATE: this.closeVacancyDate,
      VACANT_MAIN_RECRUITER: this.mainRecruiter?.id || null,
      vacanT_PAYMENT_PERIOD: this.selectedPaymentPeriod,
      vacanT_SALARY_MICROSITE_VISIBILITY: this.salaryVisibility,
      vacanT_COMPANY_NAME_VISIBILITY: this.companyVisibility,
      VACANT_QUESTIONNAIRE_VISIBILITY: this.vacancyQuestEnabled,
      ParticipantUserIds: this.selectedUsers.map(user => user.id),
      WorkingModalityInfo: {
        ID: this.selectedModality.id
      },
      WorkingTypeInfo: {
        ID: this.selectedJobType.id
      }
    };

    this.puzzleService.addVacancy(vacancyData).subscribe(
      (response) => {
        if (response.isSuccess) {
          this.dialogRef.close(response);
        } else {
          this.dialogRef.close({ isSuccess: false, message: this.translate.instant('addVacancyError') });
        }
      },
      (error) => {
        console.error('Error', error);
        this.dialogRef.close({ isSuccess: false, message: this.translate.instant('addVacancyError') });
      }
    );
  }

  editVacancy() {
    if (this.disabledSend) {
      return;
    }
    this.checkIfAllFieldsFilled();
    this.checkErrors();

    if (!this.allFieldsFilled) {
      this.showErrorMessage = true;
      this.translate.get('allFieldsRequired').subscribe((res: string) => {
        this.errorMessage = res;
      });
      return;
    }

    const branchObject = this.branchs.find(branch => branch.broF_ID === this.branchId);

    if (!branchObject?.broF_CITY) {
      this.openBranchModal(branchObject);
      return;
    }

    const job = this.jobs.find(job => job.joB_ID === this.jobId);
    // si no tiene descripcion se abre un dialogo para agregarla
    if (!job?.joB_DESCRIPTION) {
      this.openEditOrAddDialog('Editar', job);
      this.openResponseDialog('Error', this.translate.instant('jobWithoutDescription'));
      return;
    }

    // agregar hora actual a openVacancyDate y closeVacancyDate
    if (this.openVacancyDate) {
      const now = new Date();
      const localTime = now.toTimeString().split(' ')[0]; // Devuelve "HH:mm:ss"
      this.openVacancyDate += 'T' + localTime;
    }

    if (this.closeVacancyDate) {
      const now = new Date();
      const localTime = now.toTimeString().split(' ')[0]; // Devuelve "HH:mm:ss"
      this.closeVacancyDate += 'T' + localTime;
    }

    this.disabledSend = true;
    const vacancyData = {
      VACANT_ID: this.existingVacancy && this.existingVacancy.vacanT_ID ? this.existingVacancy.vacanT_ID : undefined,
      VACANT_COMPANY_ID: this.localService.getJsonValue('token')?.data.companyId,
      VACANT_DEP_ID: this.departmentId,
      VACANT_BROF_ID: this.branchId,
      VACANT_JOB_ID: this.jobId,
      VACANT_DIRECT_BOSS: this.directBoss,
      VACANT_ACTIVE: this.existingVacancy.vacanT_ACTIVE,
      VACANT_SALARY: this.actualSalary,
      VACANT_SALARY_TYPE: this.salaryType,
      VACANT_NUMBER: this.vacancyCount,
      VACANT_NUMBER_TYPE: this.vacancyCountType,
      VACANT_INITIAL_DATE: this.openVacancyDate,
      VACANT_CLOSE_DATE: this.closeVacancyDate,
      VACANT_MAIN_RECRUITER: this.mainRecruiter?.id || null,
      vacanT_PAYMENT_PERIOD: this.selectedPaymentPeriod,
      vacanT_SALARY_MICROSITE_VISIBILITY: this.salaryVisibility,
      vacanT_COMPANY_NAME_VISIBILITY: this.companyVisibility,
      VACANT_QUESTIONNAIRE_VISIBILITY: this.vacancyQuestEnabled,
      ParticipantUserIds: this.selectedUsers.map(user => user.id),
      WorkingModalityInfo: {
        ID: this.selectedModality.id
      },
      WorkingTypeInfo: {
        ID: this.selectedJobType.id
      }
    };

    this.puzzleService.addVacancy(vacancyData).subscribe(
      (response) => {
        if (response.isSuccess) {
          this.dialogRef.close(response);
        } else {
          this.dialogRef.close({ isSuccess: false, message: this.translate.instant('addVacancyError') });
        }
      },
      (error) => {
        console.error('Error', error);
        this.dialogRef.close({ isSuccess: false, message: this.translate.instant('addVacancyError') });
      }
    );
  }

  closeVacancy() {
    if (this.disabledSend) {
      return;
    }

    // agregar hora actual a openVacancyDate y closeVacancyDate
    if (this.openVacancyDate) {
      const now = new Date();
      const localTime = now.toTimeString().split(' ')[0]; // Devuelve "HH:mm:ss"
      this.openVacancyDate += 'T' + localTime;
    }

    if (this.closeVacancyDate) {
      const now = new Date();
      const localTime = now.toTimeString().split(' ')[0]; // Devuelve "HH:mm:ss"
      this.closeVacancyDate += 'T' + localTime;
    }

    this.disabledSend = true;
    const vacancyData = {
      VACANT_ID: this.existingVacancy && this.existingVacancy.vacanT_ID ? this.existingVacancy.vacanT_ID : undefined,
      VACANT_COMPANY_ID: this.localService.getJsonValue('token')?.data.companyId,
      VACANT_DEP_ID: this.departmentId,
      VACANT_BROF_ID: this.branchId,
      VACANT_JOB_ID: this.jobId,
      VACANT_DIRECT_BOSS: this.directBoss,
      VACANT_ACTIVE: this.existingVacancy ? !this.existingVacancy.vacanT_ACTIVE : true,
      VACANT_SALARY: this.actualSalary,
      VACANT_SALARY_TYPE: this.salaryType,
      VACANT_NUMBER: this.vacancyCount,
      VACANT_NUMBER_TYPE: this.vacancyCountType,
      VACANT_INITIAL_DATE: this.openVacancyDate,
      VACANT_CLOSE_DATE: this.closeVacancyDate,
      VACANT_MAIN_RECRUITER: this.mainRecruiter?.id || null,
      vacanT_PAYMENT_PERIOD: this.selectedPaymentPeriod,
      vacanT_SALARY_MICROSITE_VISIBILITY: this.salaryVisibility,
      vacanT_COMPANY_NAME_VISIBILITY: this.companyVisibility,
      VACANT_QUESTIONNAIRE_VISIBILITY: this.vacancyQuestEnabled,
      ParticipantUserIds: this.selectedUsers.map(user => user.id),
      WorkingModalityInfo: {
        ID: this.selectedModality.id
      },
      WorkingTypeInfo: {
        ID: this.selectedJobType.id
      }
    };

    this.puzzleService.addVacancy(vacancyData).subscribe(
      (response) => {
        if (response.isSuccess) {
          this.dialogRef.close(response);
        } else {
          this.dialogRef.close({ isSuccess: false, message: this.translate.instant('addVacancyError') });
        }
      },
      (error) => {
        console.error('Error', error);
        this.dialogRef.close({ isSuccess: false, message: this.translate.instant('addVacancyError') });
      }
    );
  }

  deleteVacancy() {
    if (this.disabledSend) {
      return;
    }

    // agregar hora actual a openVacancyDate y closeVacancyDate
    if (this.openVacancyDate) {
      const now = new Date();
      const localTime = now.toTimeString().split(' ')[0]; // Devuelve "HH:mm:ss"
      this.openVacancyDate += 'T' + localTime;
    }

    if (this.closeVacancyDate) {
      const now = new Date();
      const localTime = now.toTimeString().split(' ')[0]; // Devuelve "HH:mm:ss"
      this.closeVacancyDate += 'T' + localTime;
    }

    this.disabledSend = true;
    const vacancyData = {
      VACANT_ID: this.existingVacancy && this.existingVacancy.vacanT_ID ? this.existingVacancy.vacanT_ID : undefined,
      VACANT_COMPANY_ID: this.localService.getJsonValue('token')?.data.companyId,
      VACANT_DEP_ID: this.departmentId,
      VACANT_BROF_ID: this.branchId,
      VACANT_JOB_ID: this.jobId,
      VACANT_DIRECT_BOSS: this.directBoss,
      VACANT_ACTIVE: false,
      VACANT_DELETED: true,
      VACANT_SALARY: this.actualSalary,
      VACANT_SALARY_TYPE: this.salaryType,
      VACANT_NUMBER: this.vacancyCount,
      VACANT_NUMBER_TYPE: this.vacancyCountType,
      VACANT_INITIAL_DATE: this.openVacancyDate,
      VACANT_CLOSE_DATE: this.closeVacancyDate,
      VACANT_MAIN_RECRUITER: this.mainRecruiter?.id || null,
      vacanT_PAYMENT_PERIOD: this.selectedPaymentPeriod,
      vacanT_SALARY_MICROSITE_VISIBILITY: this.salaryVisibility,
      vacanT_COMPANY_NAME_VISIBILITY: this.companyVisibility,
      VACANT_QUESTIONNAIRE_VISIBILITY: this.vacancyQuestEnabled,
      ParticipantUserIds: this.selectedUsers.map(user => user.id),
      WorkingModalityInfo: {
        ID: this.selectedModality.id
      },
      WorkingTypeInfo: {
        ID: this.selectedJobType.id
      }
    };

    this.puzzleService.addVacancy(vacancyData).subscribe(
      (response) => {
        if (response.isSuccess) {
          this.dialogRef.close(response);
        } else {
          this.dialogRef.close({ isSuccess: false, message: 'addvacancyDeletionError' });
        }
      },
      (error) => {
        console.error('Error', error);
        this.dialogRef.close({ isSuccess: false, message: 'addvacancyDeletionError' });
      }
    );
  }

  getUsername() {
    let tokencito = this.localService.getJsonValue('token');

    if (tokencito.data.companyId != null) {
      this.id = tokencito.data.companyId;
    }
  }

  goMyCompany() {
    this.router.navigate(['home/catalogues/catalogs'], {
      queryParams: { id: this.id },
    });
    this.dialogRef.close();

  }

  toggleEditMode() {
    this.editModeActive = !this.editModeActive;
    if (this.editModeActive) {
      this.jobControl.enable();
      this.departmentControl.enable();
      this.branchControl.enable();
      this.inputControl.enable();
    } else {
      this.jobControl.disable();
      this.departmentControl.disable();
      this.branchControl.disable();
      this.inputControl.disable();
    }
  }

  onVacancyCountChange(event: Event) {
    const inputValue = Number((event.target as HTMLInputElement).value);
    const input1 = document.getElementById('candidateDetailVacancyCount1') as HTMLInputElement;
    const input2 = document.getElementById('candidateDetailVacancyCount2') as HTMLInputElement;
    const input3 = document.getElementById('candidateDetailVacancyCount3') as HTMLInputElement;
    if (inputValue < 0) {
      this.vacancyCount = Math.abs(inputValue);
      if (input1) input1.value = Math.abs(inputValue).toString();
      if (input2) input2.value = Math.abs(inputValue).toString();
      if (input3) input3.value = Math.abs(inputValue).toString();
    } else if (inputValue === 0) {
      this.vacancyCount = 1;
      if (input1) input1.value = '1';
      if (input2) input2.value = '1';
      if (input3) input3.value = '1';
    } else if (inputValue > 999) {
      this.vacancyCount = 999;
      if (input1) input1.value = '999';
      if (input2) input2.value = '999';
      if (input3) input3.value = '999';
    } else {
      this.vacancyCount = inputValue;
      if (input1) input1.value = inputValue.toString();
      if (input2) input2.value = inputValue.toString();
      if (input3) input3.value = inputValue.toString();
    }
  }

  onFocus(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.addClass(target, 'focused');
  }

  onBlur(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.removeClass(target, 'focused');
  }

  onFocusInput(event: FocusEvent, container: HTMLElement) {
    this.renderer.addClass(container, 'focused');
  }

  onBlurInput(event: FocusEvent, container: HTMLElement) {
    this.renderer.removeClass(container, 'focused');
  }

  handleKeyDownTab(event: KeyboardEvent) {
    // Permitir barra espaciadora
    if (event.key === ' ') {
      return;
    }
    // Permitir el tabulador
    if (event.key !== 'Tab') {
      // Prevenir la entrada de texto
      event.preventDefault();
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  private transformValue(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]/g, '').trimStart();
  }

  checkErrors() {
    this.jobError = !this.jobId || this.jobId === undefined || this.jobId === '';
    this.branchError = !this.branchId || this.branchId === undefined || this.branchId === '';
    this.departmentError = !this.departmentId || this.departmentId === undefined || this.departmentId === '';
    this.bossError = !this.directBoss || this.directBoss === undefined || this.directBoss === '';
  }

  hasQuestionnaire(jobId: string) {
    if (!this.jobs || this.jobs.length === 0) {
      return;
    }
    const job = this.jobs.find(job => job.joB_ID === jobId);
    if (job) {
      this.jobHasQuest = job.haS_QUESTIONNAIRE;
    }
  }

  goTo(url: string, id: string) {
    this.dialog.closeAll();
    this.router.navigate([url], {
      queryParams: { id: id },
    });
  }

  truncateToZero(value: number): number {
    return value < 0 ? 0 : value;
  }
}
