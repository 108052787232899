import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Token } from 'src/app/interfaces/token';
import { UserLogin } from 'src/app/interfaces/user-login';
import { constants } from 'src/app/services/constants';
import { LocalService } from 'src/app/services/local.service';
import { LoginService } from 'src/app/services/login.service';
import { PuzzleService } from 'src/app/services/puzzle.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent implements OnInit {

  user: string = '';
  password: string = '';
  hide: boolean = true;
  isJobs: boolean = false;
  sendToNotifications: boolean = false;
  sending: boolean = false;
  errorMessage: string = '';
  token: Token;
  closeIcon: SafeResourceUrl = '../../../assets/icons/close-icon.svg'
  dangerousIcon: SafeResourceUrl = '../../../assets/icons/emergency-icon.svg'

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isJobs = data.jobs || false;
  }

  ngOnInit() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let notifications = urlParams.get('notifications');
    if (notifications != null && notifications != '' && notifications) {
      this.sendToNotifications = true;
    }
  }

  login() {
    if (this.sending) return;
    if (!this.password || !this.user) {
      this.errorMessage = this.translate.instant('loginEmptyFields');
      return;
    }

    let userLogin: UserLogin = {
      UserName: this.user,
      Password: this.password,
      RefreshToken: '',
      AccessToken: ''
    };

    this.sending = true;

    this.puzzleService.login(userLogin).subscribe(token => {
      if (token.isSuccess) {
        this.localService.setJsonValue('token', token);
        this.puzzleService.setToken(token);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let processid = urlParams.get('processid');

        this.token = this.localService.getJsonValue('token');

        if (this.token?.data?.roleId == constants.superadmin) {
          this.router.navigate(['home/clientlist']);
        } else {
          if (this.sendToNotifications) {
            this.router.navigate(['home/myprofile']);
          } else if (processid != null && processid != '') {
            this.router.navigate(['home/redirect'], { queryParams: { processid: processid } });
          } else {
            this.router.navigate(['home/dashboard']);
          }
        }

        this.dialogRef.close();
      } else {
        if (token.messageCode == 'EMAIL_NOT_CONFIRMED') {
          this.openConfirmCodeModal();
          return;
        }
        // Manejar errores específicos de forma traducida
        if (token.messageCode === 'USER_NOT_FOUND') {
          this.errorMessage = this.translate.instant('loginUserNotExist'); // Traducción de 'User account does not exist'
        } else if (token.messageCode === 'INVALID_CREDENTIALS') {
          this.errorMessage = this.translate.instant('loginIncorrectCredentials'); // Traducción de 'Incorrect user credentials'
        } else if (token.messageCode === 'COMPANY_NOT_ACTIVE') {
          this.errorMessage = this.translate.instant('loginIncorrectCompanyNoActive');
        } else if (token?.message?.includes('Timeout expired')) {
          this.errorMessage = this.translate.instant('loginTimeoutExpired');
        } else {
          // Si el error es diferente, mostrar el mensaje tal cual lo devuelve el servidor
          this.errorMessage = token.message;
        }
        this.sending = false;
      }
    }, err => {
      this.errorMessage = this.translate.instant('loginError'); // Error genérico de login
      this.sending = false;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  closeErrorModal() {
    this.errorMessage = '';
  }

  openRegisterModal() {
    this.loginService.openRegisterModal(this.isJobs);
    this.dialogRef.close();
  }

  openConfirmCodeModal() {
    this.loginService.openConfirmCodeModal(this.user);
    this.dialogRef.close();
  }
}

