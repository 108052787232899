import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        console.error('Global error caught:', error);

        if (this.shouldHardRefresh(error)) {
            console.warn('Triggering hard refresh...');
            window.location.reload(); // Hard refresh
        }
    }

    private shouldHardRefresh(error: any): boolean {
        const errorMessage = error?.message || '';

        return (
            errorMessage.includes('Cannot access') ||
            errorMessage.includes('before initialization') ||
            errorMessage.includes("Cannot read properties of undefined (reading 'call')")
        );
    }
}
