<div *ngIf="!disableClose" class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
  <button id="closeModalChangeStatus2" mat-icon-button (click)="cerrar(false)" tabindex="-1">
    <mat-icon class="close-x-primary">close</mat-icon>
  </button>
</div>

<div class="pb-3 text-center title-text-modal">
  {{data[0]}}
</div>


<div class="py-4 text-center text-gray-600 font-size">
  <div [innerHTML]="data[1]"></div>
</div>

<div class="flex justify-center mt-4 gap-3 flex-col md:flex-row">
  <div *ngIf="data[4]" class="w-full md:w-1/2">
    <app-custom-primary-button id="confirmDialogBtn" id="btnNo" size="confirm" color="secondary" class="w-full"
      (click)="cerrar(false)">
      {{ data[4] }}
    </app-custom-primary-button>
  </div>
  <div class="w-full md:w-1/2">
    <app-custom-primary-button id="confirmDialogBtn" id="btnNo" size="confirm" color="primary" class="w-full"
      (click)="cerrar(true)">
      <span *ngIf="data[3]"> {{ data[3] }}</span>
      <span *ngIf="!data[3]">{{ 'confirmDialog' | translate }}</span>
    </app-custom-primary-button>
  </div>
</div>