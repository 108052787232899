<app-navbar></app-navbar>
<div class="flex flex-col md:pt-0 md:px-0 md:justify-center items-center border-gray-50 bg-white min-w-full no-zoom">
  <div class="bg-white w-full flex flex-col justify-center items-center nav-transition"
    [ngClass]="scrolled ? '' : 'sliders'">

    <div *ngIf="currentLandingCarrousel.length > 0 || currentLandingCarrouselMobile.length > 0" class="relative mb-1 mt-4 w-full">
      <owl-carousel-o #owlElement [options]="customOptionsCarrouselLanding">
        <ng-container *ngIf="isMobileScreen; else desktopSlides">
          <ng-container *ngFor="let landing of currentLandingCarrouselMobile">
            <ng-template carouselSlide>
              <div class="flex justify-center items-center w-full h-full cursor-pointer" (click)="goToLink(landing.link)">
                <img class="w-full h-auto object-contain" [src]="landing.Logo_route" [alt]="landing.comP_NAME" />
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-template #desktopSlides>
          <ng-container *ngFor="let landing of currentLandingCarrousel">
            <ng-template carouselSlide>
              <div class="flex justify-center items-center w-full h-full cursor-pointer" (click)="goToLink(landing.link)">
                <img class="w-full h-auto object-contain" [src]="landing.Logo_route" [alt]="landing.comP_NAME" />
              </div>
            </ng-template>
          </ng-container>
        </ng-template>
      </owl-carousel-o>
    </div>

    <!-- Carousel -->
    <div *ngIf="companiesCarousel.length > 0" class="relative mb-1 mt-4 w-full">
      <owl-carousel-o #owlElement [options]="customOptions">
        <ng-container *ngFor="let company of companiesCarousel; let i = index;">
          <ng-template carouselSlide>
            <div class="flex justify-center items-center p-4">
              <img class="h-24 w-auto object-contain object-center" [src]="company.comP_LOGO_ROUTE"
                [alt]="company.comP_NAME" />
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>

    <div class="w-full">
      <div class="responsive-iframe-container">
        <iframe src="https://www.youtube.com/embed/uf3ktPfqfMA" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>

    <div class="bg-secondary w-full">
      <div class="px-4 md:px-20">
        <div class="flex flex-col lg:flex-row w-full items-stretch justify-center text-center px-4 lg:px-6 py-10">
          <div class="flex flex-col items-center justify-center mb-8 lg:mb-0 lg:mx-2 xl:mx-4 w-full lg:w-1/3 h-full">
            <div class="flex items-center justify-center w-20 h-20 rounded-full p-2 overflow-hidden bg-white">
              <img class="w-20 h-auto mx-auto" [src]="candidateIcon" alt="">
            </div>
            <div class="text-center pt-2 stats-number">
              {{ stats?.totalCandidates }}
            </div>
            <div class="text-center unique-text pt-1 text-sm">
              {{"loginCandidates" | translate}}
            </div>
          </div>

          <div class="flex flex-col items-center justify-center mb-8 lg:mb-0 lg:mx-2 xl:mx-4 w-full lg:w-1/3 h-full">
            <div class="flex items-center justify-center w-20 h-20 rounded-full p-2 overflow-hidden bg-white">
              <img class="w-20 h-auto mx-auto" [src]="userIcon" alt="">
            </div>
            <div class="text-center pt-2 stats-number">
              {{ stats?.totalUsers }}
            </div>
            <div class="text-center unique-text pt-1 text-sm">
              {{"loginUsers" | translate}}
            </div>
          </div>

          <div class="flex flex-col items-center justify-center mb-8 lg:mb-0 lg:mx-2 xl:mx-4 w-full lg:w-1/3 h-full">
            <div class="flex items-center justify-center w-20 h-20 rounded-full p-2 overflow-hidden bg-white">
              <img class="w-20 h-auto mx-auto" [src]="clientIcon" alt="">
            </div>
            <div class="text-center pt-2 stats-number">
              {{ stats?.totalCompanies }}
            </div>
            <div class="text-center unique-text pt-1 text-sm">
              <div>{{"loginCompanies" | translate}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-4 md:px-20 pb-12 background-gif">
      <div class="flex flex-col md:flex-row w-full">
        <div class="w-full md:w-1/2 px-6 py-4 md:py-0 flex items-center justify-center">
          <img class="w-3/4 md:w-2/3 lg:w-3/4 h-auto image-rendering" [src]="gifIcon" alt="">
        </div>
        <div id="rompe" class="w-full md:w-1/2 px-6">
          <div class="w-full pt-12 pb-6 subtitle-gray font-medium text-left">
            {{"loginFindTalent" | translate}}
          </div>
          <div class="pb-6 pt-2 md:text-3xl text-left green-title green-title-size">
            {{"loginWhyRecruiter" | translate}}
          </div>
          <div class="pt-6 pr-3 text-justify meaning-haibu-text" [innerHTML]="'loginPuzzleMeaning' | translate"></div>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap px-4 md:pr-20 lg:px-28 bg-white">
      <div id="modules" class="w-full pt-8 pb-4 px-8 green-title green-title-size text-center">
        {{"loginModules" | translate}}
      </div>
      <!-- Primera columna -->
      <div class="w-full md:w-1/3 px-8 py-8 flex flex-col justify-between items-stretch">
        <!-- Sección 1 -->
        <div class="mb-8">
          <div>
            <img class="w-28 h-auto mx-auto" src="../../assets/animations/red-en-la-nube.gif" alt="Nube">
          </div>
          <h2 class="text-left blue-container-text mb-2 whitespace-nowrap">{{"loginDigitalized" | translate}}</h2>
          <p class="text-left text-help-haibu">{{"loginSelectCandidate" | translate}}</p>
        </div>
        <!-- Sección 2 -->
        <div>
          <div>
            <img class="w-28 h-auto mx-auto" src="../../assets/animations/cliente.gif" alt="Nube">
          </div>
          <h2 class="text-left blue-container-text mb-2 whitespace-nowrap">{{"loginTeamTitle" | translate}}</h2>
          <p class="text-left text-help-haibu break-words">{{"loginFirstTalent" | translate}}</p>
        </div>
      </div>

      <!-- Segunda columna (Imagen) -->
      <div class="w-full md:w-1/3 flex justify-center items-center px-8 py-8 md:py-12">
        <div class="outer-circle">
          <div class="outer-circle-wrapper">
            <div class="circle-wrapper">
              <img [src]="actualImage" alt="Logo Haibu" class="image-style w-28 p-1">
            </div>
          </div>
        </div>
      </div>

      <!-- Tercera columna -->
      <div class="w-full md:w-1/3 px-8 py-8 flex flex-col justify-between items-stretch">
        <!-- Sección 1 -->
        <div class="mb-8">
          <div>
            <img class="w-28 h-auto mx-auto" src="../../assets/animations/presentacion.gif" alt="Nube">
          </div>
          <h2 class="text-left blue-container-text mb-2 whitespace-nowrap">{{"loginFilterAndSelect" | translate}}</h2>
          <p class="text-left text-help-haibu ">{{"loginFiltersTitle" | translate}}</p>
        </div>
        <!-- Sección 2 -->
        <div>
          <div>
            <img class="w-28 h-auto mx-auto" src="../../assets/animations/hexagonal.gif" alt="Nube">
          </div>
          <h2 class="text-left blue-container-text mb-2">{{"loginEasyFeatures" | translate}}</h2>
          <p class="text-left text-help-haibu">{{"loginEasyFeaturesTalent" | translate}}</p>
        </div>
      </div>
    </div>

    <div class="bg-secondary w-full">
      <div class="px-4 md:px-20">
        <div class="w-full pt-12 subtitle-gray font-medium text-center">
          {{"loginFindTalent" | translate}}
        </div>
        <div class="w-full py-8 md:py-12 px-8 green-title green-title-size text-center">
          {{"loginContinue" | translate}}
        </div>
        <div class="flex flex-col lg:flex-row w-full items-stretch justify-center text-center px-4 lg:px-6 pb-12 pt-4">
          <div class="flex flex-col items-center justify-center mb-8 lg:mb-0 lg:mx-2 xl:mx-4 w-full lg:w-1/3 h-full">
            <div class="flex items-center justify-center h-full lg:h-20">
              <img class="w-16 lg:w-20 h-auto mx-auto" [src]="teamSettingsIcon" alt="">
            </div>
            <div class="text-center unique-text pt-4 text-base lg:text-sm">
              {{"loginOneUser" | translate}}
            </div>
          </div>

          <div class="flex flex-col items-center justify-center mb-8 lg:mb-0 lg:mx-2 xl:mx-4 w-full lg:w-1/3 h-full">
            <div class="flex items-center justify-center h-full lg:h-20">
              <img class="w-16 lg:w-20 h-auto mx-auto" [src]="teamSquareIcon" alt="">
            </div>
            <div class="text-center unique-text pt-4 text-base lg:text-sm">
              {{"loginOneUserPrice" | translate}}
            </div>
          </div>

          <div class="flex flex-col items-center justify-center mb-8 lg:mb-0 lg:mx-2 xl:mx-4 w-full lg:w-1/3 h-full">
            <div class="flex items-center justify-center h-full lg:h-20">
              <img class="w-16 lg:w-20 h-auto mx-auto" [src]="teamPuzzleIcon" alt="">
            </div>
            <div class="text-center unique-text pt-4 text-base lg:text-sm">
              <div>{{"loginOneUser2" | translate}}</div>
              <div>{{"loginOneUserPrice2" | translate}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full py-8">
      <div class="px-4 md:px-20 lg:px-40">
        <div class="flex flex-col md:flex-row">
          <div>
            <h2 class="text-left blue-container-text mb-2 whitespace-nowrap">{{"navbarDashboard" | translate}}</h2>
            <p class="text-left text-help-haibu break-words">{{"dashboardLoginD" | translate}}</p>
          </div>
          <div>
            <img class="px-0 md:px-12" src="../../assets/img/Dashboard.png" alt="Dashboard Icon">
          </div>
        </div>
      </div>
    </div>


    <div class="w-full plan-table py-10">
      <div class="mb-6">
        <div class="green-title green-title-size text-center">
          {{"loginPlansCompare" | translate}}
        </div>
      </div>
      <div class="px-4 lg:px-28 xl:px-36 w-full overflow-hidden plan-table flex justify-start lg:justify-center">
        <mat-table #table [dataSource]="dataSource" class="mat-elevation-z0 w-full py-3 plan-table-mat">

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef class="font-bold"></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="font-bold text-sm">{{ element.name | translate }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="entry">
            <mat-header-cell *matHeaderCellDef>
              <div class="font-bold text-center w-full">
                {{ 'plan.1' | translate }}
                <span [innerHTMl]="'plan.1' | translate"></span>
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="text-center w-full font-lg">
                <mat-icon *ngIf="element.entry" class="text-green-500 font-bold">check</mat-icon>
                <mat-icon *ngIf="!element.entry" class="text-red-500 font-bold">close</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="entryHH">
            <mat-header-cell *matHeaderCellDef>
              <div class="font-bold text-center w-full px-0 md:px-5">
                {{ 'plan.2' | translate }}
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="text-center w-full font-lg">
                <mat-icon *ngIf="element.entryHH" class="text-green-500 font-bold">check</mat-icon>
                <mat-icon *ngIf="!element.entryHH" class="text-red-500 font-bold">close</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="mid">
            <mat-header-cell *matHeaderCellDef>
              <div class="font-bold text-center w-full">
                {{ 'plan.3' | translate }}
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="text-center w-full font-lg">
                <mat-icon *ngIf="element.mid" class="text-green-500 font-bold">check</mat-icon>
                <mat-icon *ngIf="!element.mid" class="text-red-500 font-bold">close</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="midHH">
            <mat-header-cell *matHeaderCellDef>
              <div class="font-bold text-center w-full px-0 md:px-5">
                {{ 'plan.4' | translate }}
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="text-center w-full">
                <mat-icon *ngIf="element.midHH" class="text-green-500 font-bold">check</mat-icon>
                <mat-icon *ngIf="!element.midHH" class="text-red-500 font-bold">close</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="enterprise">
            <mat-header-cell *matHeaderCellDef>
              <div class="font-bold text-center w-full">
                {{ 'plan.5' | translate }}
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div class="text-center w-full">
                <mat-icon *ngIf="element.enterprise" class="text-green-500 font-bold">check</mat-icon>
                <mat-icon *ngIf="!element.enterprise" class="text-red-500 font-bold">close</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
      <div class="text-xs text-gray-400 text-center">*{{ 'adviceFeatures' | translate }}</div>
    </div>

    <div class="w-full background-mixed">
      <div id="price" class="px-5 md:px-8">
        <div class="pt-9 pb-6">
          <div class="green-title green-title-size text-center">
            {{"loginOneUser3" | translate}}
          </div>
        </div>
        <div>
          <div class="meaning-haibu-text meaning-size-alter text-left">
            {{"loginOneUserPrice3" | translate}}
          </div>
        </div>
        <div class="mt-6 flex justify-center">
          <div class="subscription-switch-cont">
            <div class="flex items-center justify-center w-auto text-center">
              <button
                class="py-2 px-4 w-28 rounded-l-full bg-white text-gray-900 font-medium focus:outline-none text-sm"
                [ngClass]="{'background-haibu text-white': subscriptionType === 'mensual'}"
                (click)="setSubscriptionType('mensual')">
                {{ 'mensual' | translate }}
              </button>
              <button
                class="py-2 px-4 w-28 rounded-r-full bg-white text-gray-900 font-medium focus:outline-none text-sm"
                [ngClass]="{'background-haibu text-white': subscriptionType === 'semestral'}"
                (click)="setSubscriptionType('semestral')">
                {{ 'semestral' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div class="text-gray-900 text-lg w-full text-center pt-3">{{ 'introPricesLogin' | translate }}</div>
        <div class="flex flex-col md:flex-row justify-center gap-4 pt-2 pb-6 relative price-container">
          <div class="absolute left-0 top-1/2 flex items-center justify-center translate-item z-30">
            <div class="arrow-price rounded-full w-10 h-10 flex items-center justify-center cursor-pointer"
              (click)="scrollLeft()">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </div>
          </div>
          <div #priceCards class="horizontal-scroll py-4 px-4">
            <div class="flex flex-nowrap gap-4">
              <!-- Card 0 -->
              <!-- <div
                class="card rounded-lg overflow-hidden shadow-lg background-haibu p-6 flex flex-col items-stretch justify-between">
                <div>
                  <div class="tag text-center mb-5 px-3 py-2">{{ 'plan.0' | translate }}</div>
                  <div class="border-t border-white-300 w-full max-w-lg mx-auto pt-8 pb-4">
                    <div class="w-full max-w-lg mx-auto pb-2">
                      <p class="text-center mb-6 pb-2 bycompany-text ">
                        {{ 'byCompany.free' | translate }}
                      </p>
                    </div>
                    <div class="flex justify-center mb-2 pb-2">
                      <div class="price-text"> {{ 'price.0.' + subscriptionType | translate }}</div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="border-b border-white-300 w-full max-w-lg mx-auto pb-2">
                  </div>

                  <div class="mt-4">
                    <app-custom-primary-button color="secondary" size="small" (click)="openRegisterModal()"
                      class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
                      {{ 'callToAction.free' | translate }}
                    </app-custom-primary-button>
                  </div>
                  <p class="text-center align-bottom pt-5 bycompany-text credit-card-text">
                    {{ 'loginNoCreditCard'| translate }}
                  </p>
                  <p class="text-center align-bottom bycompany-text credit-card-text">
                    *{{ 'loginNoTax'| translate }}
                  </p>
                </div>
              </div> -->
              <!-- Card 1 -->
              <div class="card rounded-lg overflow-hidden shadow-lg bg-white p-6 flex flex-col items-center">
                <div class="tag text-center mb-5 px-3 py-2">{{ 'plan.1' | translate }}</div>
                <div class="border-t border-white-300 w-full max-w-lg mx-auto pt-8 pb-4">
                  <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center mb-3">
                    <div class="price-text2 text-secondary"> {{ 'price.1.canceled' | translate }}</div>
                    <div class="bycompany-text2 ml-1 text-secondary">MXN*</div>
                  </div>
                  <div class="w-full max-w-lg mx-auto pb-2">
                    <p *ngIf="subscriptionType === 'semestral'"
                      class="text-center mb-6 pb-2 bycompany-text text-primary">
                      {{ 'byCompany.' + subscriptionType | translate }}
                    </p>
                  </div>
                  <div class="flex justify-center mb-2 pb-2">
                    <div class="price-text text-primary"> {{ 'price.1.' + subscriptionType | translate }}</div>
                    <div class="bycompany-text text-primary ml-2">MXN*</div>
                  </div>
                </div>
                <div class="border-b border-primary-300 w-full max-w-lg mx-auto pb-2">
                  <p class="text-center mb-4 pb-2 bycompany-text text-primary">
                    {{ 'mensual' | translate }}
                  </p>
                </div>
                <!-- Botón o acción -->
                <div class="mt-4">
                  <a id="subscriptionUrl" (click)="openRegisterModal(false, 'Entry')" target="_blank">
                    <app-custom-primary-button color="primary" size="small"
                      class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
                      {{ 'callToAction.' + subscriptionType | translate }}
                    </app-custom-primary-button>
                  </a>
                </div>
                <p class="text-center align-bottom pt-5 bycompany-text credit-card-text text-black">
                  {{ 'loginNoCreditCard'| translate }}
                </p>
                <p class="text-center align-bottom bycompany-text credit-card-text text-black">
                  *{{ 'loginNoTax'| translate }}
                </p>
              </div>
              <!-- Card 2 -->
              <div class="card rounded-lg overflow-hidden shadow-lg background-haibu p-6 flex flex-col items-center">
                <div class="tag text-center mb-5 px-3 py-2">{{ 'plan.2' | translate }}</div>
                <div class="border-t border-white-300 w-full max-w-lg mx-auto pt-8 pb-4">
                  <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center mb-3">
                    <div class="price-text2"> {{ 'price.2.canceled' | translate }}</div>
                    <div class="bycompany-text2 ml-1">MXN*</div>
                  </div>
                  <div class="w-full max-w-lg mx-auto pb-2">
                    <p *ngIf="subscriptionType === 'semestral'" class="text-center mb-6 pb-2 bycompany-text ">
                      {{ 'byCompany.' + subscriptionType | translate }}
                    </p>
                  </div>
                  <div class="flex justify-center mb-2 pb-2">
                    <div class="price-text"> {{ 'price.2.' + subscriptionType | translate }}</div>
                    <div class="bycompany-text ml-2">MXN*</div>
                  </div>
                </div>
                <div class="border-b border-white-300 w-full max-w-lg mx-auto pb-2">
                  <p class="text-center mb-4 pb-2 bycompany-text ">
                    {{ 'mensual' | translate }}
                  </p>
                </div>
                <!-- Botón o acción -->
                <div class="mt-4">
                  <a id="subscriptionUrl" (click)="openRegisterModal(false, 'Head Hunter')" target="_blank">
                    <app-custom-primary-button color="secondary" size="small"
                      class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
                      {{ 'callToAction.' + subscriptionType | translate }}
                    </app-custom-primary-button>
                  </a>
                </div>
                <p class="text-center align-bottom pt-5 bycompany-text credit-card-text">
                  {{ 'loginNoCreditCard'| translate }}
                </p>
                <p class="text-center align-bottom bycompany-text credit-card-text">
                  *{{ 'loginNoTax'| translate }}
                </p>
              </div>
              <!-- Card 3 -->
              <div class="card rounded-lg overflow-hidden shadow-lg bg-white p-6 flex flex-col items-center">
                <div class="tag text-center mb-5 px-3 py-2">{{ 'plan.3' | translate }}</div>
                <div class="border-t border-white-300 w-full max-w-lg mx-auto pt-8 pb-4">
                  <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center mb-3">
                    <div class="price-text2 text-secondary"> {{ 'price.3.canceled' | translate }}</div>
                    <div class="bycompany-text2 ml-1 text-secondary">MXN*</div>
                  </div>
                  <div class="w-full max-w-lg mx-auto pb-2">
                    <p *ngIf="subscriptionType === 'semestral'"
                      class="text-center mb-6 pb-2 bycompany-text text-primary">
                      {{ 'byCompany.' + subscriptionType | translate }}
                    </p>
                  </div>
                  <div class="flex justify-center mb-2 pb-2">
                    <div class="price-text text-primary"> {{ 'price.3.' + subscriptionType | translate }}</div>
                    <div class="bycompany-text text-primary ml-2">MXN*</div>
                  </div>
                </div>
                <div class="border-b border-primary-300 w-full max-w-lg mx-auto pb-2">
                  <p class="text-center mb-4 pb-2 bycompany-text text-primary">
                    {{ 'mensual' | translate }}
                  </p>
                </div>
                <!-- Botón o acción -->
                <div class="mt-4">
                  <a id="subscriptionUrl" (click)="openRegisterModal(false, 'Mid')" target="_blank">
                    <app-custom-primary-button color="primary" size="small"
                      class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
                      {{ 'callToAction.' + subscriptionType | translate }}
                    </app-custom-primary-button>
                  </a>
                </div>
                <p class="text-center align-bottom pt-5 bycompany-text credit-card-text text-black">
                  {{ 'loginNoCreditCard'| translate }}
                </p>
                <p class="text-center align-bottom bycompany-text credit-card-text text-black">
                  *{{ 'loginNoTax'| translate }}
                </p>
              </div>
              <!-- Card 4 -->
              <div class="card rounded-lg overflow-hidden shadow-lg background-haibu p-6 flex flex-col items-center">
                <div class="tag text-center mb-5 px-3 py-2">{{ 'plan.4' | translate }}</div>
                <div class="border-t border-white-300 w-full max-w-lg mx-auto pt-8 pb-4">
                  <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center mb-3">
                    <div class="price-text2"> {{ 'price.4.canceled' | translate }}</div>
                    <div class="bycompany-text2 ml-1">MXN*</div>
                  </div>
                  <div class="w-full max-w-lg mx-auto pb-2">
                    <p *ngIf="subscriptionType === 'semestral'" class="text-center mb-6 pb-2 bycompany-text ">
                      {{ 'byCompany.' + subscriptionType | translate }}
                    </p>
                  </div>
                  <div class="flex justify-center mb-2 pb-2">
                    <div class="price-text"> {{ 'price.4.' + subscriptionType | translate }}</div>
                    <div class="bycompany-text ml-2">MXN*</div>
                  </div>
                </div>
                <div class="border-b border-white-300 w-full max-w-lg mx-auto pb-2">
                  <p class="text-center mb-4 pb-2 bycompany-text ">
                    {{ 'mensual' | translate }}
                  </p>
                </div>
                <!-- Botón o acción -->
                <div class="mt-4">
                  <a id="subscriptionUrl" (click)="openRegisterModal(false, 'Mid + Head Hunter')" target="_blank">
                    <app-custom-primary-button color="secondary" size="small"
                      class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
                      {{ 'callToAction.' + subscriptionType | translate }}
                    </app-custom-primary-button>
                  </a>
                </div>
                <p class="text-center align-bottom pt-5 bycompany-text credit-card-text">
                  {{ 'loginNoCreditCard'| translate }}
                </p>
                <p class="text-center align-bottom bycompany-text credit-card-text">
                  *{{ 'loginNoTax'| translate }}
                </p>
              </div>
              <!-- Card 5 -->
              <div
                class="card rounded-lg overflow-hidden shadow-lg bg-white p-6 flex flex-col items-stretch justify-between">
                <div>
                  <div class="tag text-center mb-5 px-3 py-2">{{ 'plan.5' | translate }}</div>
                  <div class="border-t border-white-300 w-full max-w-lg mx-auto pt-8 pb-4">
                    <p class="text-center mb-2 pb-2 bycompany-text text-primary mt-8">
                      {{ 'personalizedPlan' | translate }}
                    </p>
                    <div class="flex justify-center mb-2 pb-2">
                      <div class="contact-price-text text-primary text-center">
                        {{ 'price.5.' + subscriptionType | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="border-b border-primary-300 w-full max-w-lg mx-auto pb-2">
                  </div>
                  <!-- Botón o acción -->
                  <div class="mt-4">
                    <a id="subscriptionUrl" [href]="getSuscriptionUrl(5)" target="_blank">
                      <app-custom-primary-button color="primary" size="small"
                        class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
                        {{ 'price.5.' + subscriptionType | translate }}
                      </app-custom-primary-button>
                    </a>
                  </div>
                  <p class="text-center align-bottom pt-5 bycompany-text credit-card-text text-black">
                    {{ 'loginNoCreditCard'| translate }}
                  </p>
                  <p class="text-center align-bottom bycompany-text credit-card-text text-black">
                    *{{ 'loginNoTax'| translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="absolute right-0 top-1/2 flex items-center justify-center translate-item z-30">
            <div class="arrow-price rounded-full w-10 h-10 flex items-center justify-center cursor-pointer"
              (click)="scrollRight()">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="px-4 lg:px-20 w-full background-green py-12 items-center justify-center">
    <div class="flex flex-col w-full items-center justify-center">
      <div class="w-full lg:w-3/4  flex items-center justify-center">
        <div class="rounded-md w-full md:w-full">
          <div id="demo" class="bg-white rounded-lg p-6 shadow-md">
            <div>
              <div class="subtitle-gray text-center pt-4">
                {{"loginAskForADemo" | translate}}
              </div>
              <div class="benefits-text pt-4 pb-4">
                {{"loginWantAllBenefits" | translate}}
              </div>
              <div class="text-form">
                {{"loginFillAllForm" | translate}}
              </div>
              <div class="text-form pb-2">
                {{"scheduleCallNow" | translate}} <a class="goodtime"
                  href="https://meet.goodtime.io/w/haibuhrcom/jgasteasoro/video-call"
                  target="_blank">{{"scheduleCallNow2" | translate}}</a>.
              </div>
            </div>
            <form [formGroup]="demoForm" class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full border-radius">
                  <mat-label for="loginNameInput">{{ 'loginAskDemoName' | translate }}</mat-label>
                  <input #nameInput id="loginNameInput" maxlength="40" formControlName="names" matInput type="text"
                    class="validate w-full border-radius rounded-lg" [placeholder]="'loginAskDemoName' | translate" required
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('names').invalid && (demoForm.get('names').dirty || demoForm.get('names').touched)"
                  class="text-red-500 text-xs mt-3 -mb-6">
                  <span *ngIf="demoForm.get('names').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: 'loginAskDemoName' | translate } }}
                  </span>
                  <span *ngIf="demoForm.get('names').errors['noSpecialCharacters']">
                    {{ 'invalidCharactersKeyForm' | translate: { key: 'loginAskDemoName' | translate } }}
                  </span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label for="loginNameInput">{{ 'loginAskDemoLastName' | translate }}</mat-label>
                  <input id="loginLastNameInput" maxlength="40" formControlName="lastnames" matInput type="text"
                    class="validate w-full" [placeholder]="'loginAskDemoLastName' | translate" required
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('lastnames').invalid && (demoForm.get('lastnames').dirty || demoForm.get('lastnames').touched)"
                  class="text-red-500 text-xs mt-3 -mb-6">
                  <span *ngIf="demoForm.get('lastnames').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: 'loginAskDemoLastName' | translate } }}
                  </span>
                  <span *ngIf="demoForm.get('lastnames').errors['noSpecialCharacters']">
                    {{ 'invalidCharactersKeyForm' | translate: { key: 'loginAskDemoLastName' | translate } }}
                  </span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full logindepaddme">
                  <mat-label for="loginNameInput">{{ 'loginAskDemoEmail' | translate }}</mat-label>
                  <input id="loginEmailInput" maxlength="80" formControlName="email" matInput type="text"
                    class="validate" [placeholder]="'loginAskDemoEmail' | translate" required oninput="this.value = this.value.trim();">
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('email').invalid && (demoForm.get('email').dirty || demoForm.get('email').touched)"
                  class="text-red-500 text-xs mt-3 -mb-6">
                  <span *ngIf="demoForm.get('email').errors['required']">{{ 'hasWhiteSpaceKeyForm' | translate: { key:
                    'loginAskDemoEmail' | translate } }}</span>
                  <span
                    *ngIf="!demoForm.get('email').errors['required'] && (demoForm.get('email').errors['email'] || demoForm.get('email').errors['isEmailValid'])">{{
                    'emailMassiveLinkValid' | translate }}</span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full logindepaddme">
                  <mat-label for="loginNameInput">{{ 'loginAskDemoPhone' | translate }}</mat-label>
                  <input id="loginCellPhoneInput" maxlength="10" minlength="10"
                    (keypress)="($event.charCode >= 48 && $event.charCode < 58)" formControlName="phone" matInput
                    type="text" class="validate" [placeholder]="'loginAskDemoPhone' | translate" required
                    oninput="this.value = this.value.replace(/[^0-9]/g, '').trim();">
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('phone').invalid && (demoForm.get('phone').dirty || demoForm.get('phone').touched)"
                  class="text-red-500 text-xs mt-3 -mb-6">
                  <span *ngIf="demoForm.get('phone').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: 'loginAskDemoPhone' | translate } }}
                  </span>
                  <span
                    *ngIf="!demoForm.get('phone').errors['minlength'] && demoForm.get('phone').errors['isPhoneValid']">
                    {{ 'phoneNumberInvalid' | translate }}
                  </span>
                  <span *ngIf="demoForm.get('phone').errors['minlength']">
                    {{ 'invalidAtLeastLengthKeyForm' | translate: { key: 'loginAskDemoPhone' | translate, number: 10 } }}
                  </span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full logindepaddme">
                  <mat-label for="loginNameInput">{{ 'loginAskDemoCompany' | translate }}</mat-label>
                  <input id="loginBussinesInput" maxlength="100" formControlName="company" matInput type="text"
                    class="validate" [placeholder]="'loginAskDemoCompany' | translate" required
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('company').invalid && (demoForm.get('company').dirty || demoForm.get('company').touched)"
                  class="text-red-500 text-xs mt-3 -mb-6">
                  <span *ngIf="demoForm.get('company').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: 'loginAskDemoCompany' | translate } }}
                  </span>
                  <span *ngIf="demoForm.get('company').errors['noSpecialCharacters']">
                    {{ 'invalidCharactersKeyForm' | translate: { key: 'loginAskDemoCompany' | translate } }}
                  </span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full logindepaddme">
                  <mat-label for="loginNameInput">{{ 'loginAskCountNumber' | translate }}</mat-label>
                  <mat-select id="loginNumberEmployeesSelect" formControlName="userLimit"
                    [placeholder]="'loginAskCountNumber' | translate" required>
                    <mat-option *ngFor="let userLimit of userLimits; let i = index"
                      [value]="userLimit.usrlD_DESCRIPTION">
                      {{ userLimit.usrlD_DESCRIPTION }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('userLimit').invalid && (demoForm.get('userLimit').dirty || demoForm.get('userLimit').touched)"
                  class="text-red-500 text-xs mt-4 -mb-6">
                  <span *ngIf="demoForm.get('userLimit').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: 'loginAskCountNumber' | translate } }}
                  </span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full logindepaddme">
                  <mat-label for="loginNameInput">{{ 'loginAskCountry' | translate }}</mat-label>
                  <mat-select id="loginCountrySelect" formControlName="country" [placeholder]="'loginAskCountry' | translate" required>
                    <mat-option *ngFor="let country of countries; let i = index" [value]="country.ctrD_NAME">
                      {{ country.ctrD_NAME }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="demoForm.get('country').invalid && (demoForm.get('country').dirty || demoForm.get('country').touched)"
                  class="text-red-500 text-xs mt-4 -mb-4">
                  <span *ngIf="demoForm.get('country').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: 'loginAskCountry' | translate } }}
                  </span>
                </div>
              </div>

              <div class="pb-3">
                <mat-form-field appearance="outline" class="w-full logindepaddme">
                  <mat-label for="loginNameInput">{{ 'loginAskDemoCode' | translate }}</mat-label>
                  <input id="loginCodeInput" maxlength="20" formControlName="code" matInput type="text" class="validate"
                    [placeholder]="'loginAskDemoCode' | translate" oninput="this.value = this.value.trimStart();">
                </mat-form-field>
              </div>

              <re-captcha #captchaComponent (resolved)="resolved($event)" name="captcha"
                siteKey="6LdhgoYpAAAAAKlDW2aRdu6nH_Gmk0KTTU7tcoMr"></re-captcha>

              <div class="py-3">
                <input id="termsAndConditionsAccepted" formControlName="terms" type="checkbox" id="terms" name="terms"
                  required>
                <label for="terms">
                  {{ "loginAskAcept" | translate }}
                  <a class="cursor-pointer color-haibu" href="/terms" target="_blank">{{ "loginTermsAndCondition" |
                    translate }}</a>
                </label>
                <br>
                <mat-error class="error-label"
                  *ngIf="demoForm.controls.terms.invalid && demoForm.controls.terms.touched">
                  {{ 'mustAcceptTerms' | translate }}
                </mat-error>
              </div>

              <app-custom-primary-button id="loginWantACall" [disabled]="sending" color="primary" size="small"
                class="w-full" type="submit" (click)="sendMail()">
                {{ 'loginWantACall' | translate }}
              </app-custom-primary-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white w-full flex flex-col md:flex-row justify-around items-center px-10 pt-6 pb-3 background-green">
    <div class="flex-1 flex justify-start text-white">
      © 2024 Haibu
    </div>
    <div class="flex-1 flex justify-center">
      <a id="loginPrivacyAdvice" href="/notice" target="_blank" class="color-haibu ml-8">
        {{"loginPrivacyAdvice" | translate}}
      </a>
    </div>
    <div class="flex-1 flex justify-start md:pr-8 items-center">
      <a id="stripeBtn" target="_blank" class="cursor-default">
        <img [src]="stripeIcon" alt="Stripe" class="stripe-icon">
      </a>
      <a id="americanExpressIcon" target="_blank" class="cursor-default">
        <img [src]="americanExpressIcon" alt="AmericanExpress"
          class="w-12 sm:w-14 md:w-16 lg:w-16 h-10 sm:h-12 md:h-14 lg:h-16 pr-2 sm:pr-3">
      </a>
      <a id="visaIcon" target="_blank" class="cursor-default">
        <img [src]="visaIcon" alt="Visa" class="w-12 sm:w-14 md:w-16 lg:w-16 h-10 sm:h-12 md:h-14 lg:h-16 pr-2 sm:pr-3">
      </a>
      <a id="mastercardIcon" target="_blank" class="cursor-default">
        <img [src]="mastercardIcon" alt="MasterCard"
          class="w-12 sm:w-14 md:w-16 lg:w-16 h-10 sm:h-12 md:h-14 lg:h-16 pr-2">
      </a>

      <a id="linkedinIcon" class="pt-2 pb-2" href="https://www.linkedin.com/company/rompecabezashrcom" target="_blank">
        <img class="h-12 w-12" [src]="linkedinIcon" alt="">
      </a>
      <a id="igIcon" class="pt-2 pb-2" href="https://www.instagram.com/haibu.hr/" target="_blank">
        <img class="h-12 w-12" [src]="igIcon" alt="">

      </a>
    </div>
  </div>
</div>
