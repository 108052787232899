<mat-toolbar class="z-10 shadow background-colorr">
  <div class="flex flex-row w-full h-full">
    <div class="w-1/3 flex justify-center md:justify-start items-center">
      <img class="w-16 m-4" [src]="logoImage" alt="" />
    </div>
    <div class="w-1/3 flex justify-center items-center">
    </div>
  </div>
  <div class="flex justify-end w-full md:w-2/4 py-8">
    <select
      class="block appearance-none bg-white border border-gray-300 hover:border-gray-400 px-2 py-1 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
      (change)="changeLanguage($event.target.value)" [value]="currentLang">
      <option value="eng">English</option>
      <option value="esp">Español</option>
    </select>
  </div>
</mat-toolbar>

<div *ngIf="isTokenValid === true && !isFormAlreadySubmitted" class="md:px-16 md:mt-3">
  <form (ngSubmit)="createCandidate(myForm)" #myForm="ngForm" class="my-form pt-2">
    <!--Esta sección es para el nuevo candidato -->
    <div>
      <div class="custom-max-width mx-auto flex flex-col">
        <div class="text-center w-full green-title">{{"candidateAddData" | translate}}</div>
        <div [innerHTML]="'candidateExternalWelcomeMessage' | translate:{ company: companyName, job: jobName }"
          class="text-left pl-5 w-full gray-subtitle pt-4 pb-4"></div>

        <div class=" flex flex-col py-3 w-full px-6 wizard-container mb-4">
          <div class="flex justify-between items-center py-1.5 text-xl wizard-header" (click)="toggleWizardContainer()">
            <div class="dropdown-title pl-2">
              {{"candidateDetailGeneralData" | translate}}
            </div>
            <button type="button">
              <img [src]="expandIcon" alt="" [ngClass]="{'rotate-180': isWizardContainerExpanded}">
            </button>
          </div>
          <div *ngIf="isWizardContainerExpanded" class="flex flex-col flex-wrap p-4">

            <!-- Foto de perfil -->
            <!-- <label class="gray-subtitle pb-3 block">
              {{ "candidateExternalUploadPhoto" | translate }}
            </label> -->
            <div class="flex pb-4 w-full justify-center pl-0">
              <div class="w-44 h-44 relative">
                <!-- Borde redondo externo -->
                <div class="w-44 h-44 absolute rounded-full border image-container z-10"></div>

                <!-- Imagen del perfil del usuario -->
                <img *ngIf="userImage" class="w-44 h-44 absolute rounded-full object-cover" [src]="userImage" alt="">
                <img *ngIf="!userImage" class="w-44 h-44 absolute rounded-full object-cover" [src]="defaultpfp" alt="">

                <!-- Indicador en la parte inferior derecha -->
                <div class="p-1 absolute camera-position z-20">
                  <div class="flex justify-center items-center bg-circle-color rounded-full w-10 h-10">
                    <div id="selectNewLogoPicture" (click)="inputFileLogo.click()"
                      class="cursor-pointer w-6 h-6 flex justify-center items-center">
                      <img [src]="cameraIcon" alt="Upload Icon" class="w-6 h-6">
                    </div>
                  </div>
                  <input #inputFileLogo type="file" accept="image/*" (change)="readURL($event)" class="hidden">
                </div>
              </div>
            </div>
            <!-- Foto de perfil END -->

            <div class="flex flex-col md:flex-row flex-wrap">
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="nameInput" class="title-input-text">
                  {{ "candidateDetailGeneralDataName" | translate }}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="nameInput" type="text" class="custom-input"
                    [placeholder]="'candidatePlaceHolderName' | translate" [(ngModel)]="candidate.cdT_NAME" name="name"
                    required (keydown.tab)="onTab($event, 'surnameInput')"
                    (keydown.tab)="onTab($event, 'lastNameInput')" tabindex="2" maxlength="20"
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="lastNameInput" class="title-input-text">
                  {{"candidateDetailGeneralDataLastName" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="lastNameInput" type="text" class="custom-input"
                    [placeholder]="'candidatePlaceHolderLastName' | translate"
                    [(ngModel)]="candidate.cdT_PATERNAL_SURNAME" name="lastname" required tabindex="3" maxlength="20"
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="surnameInput" class="title-input-text">
                  {{"candidateDetailGeneralDataSecondLastName" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="surnameInput" type="text" class="custom-input"
                    [placeholder]="'candidatePlaceHolderSecondLastName' | translate"
                    [(ngModel)]="candidate.cdT_MATERNAL_SURNAME" name="surname" required tabindex="4" maxlength="20"
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="genderSelect" class="title-input-text">
                  {{"candidateDetailGeneralDataGender" | translate}}
                </label>
                <div [ngClass]="{'error-border': isGenderInvalid}">
                  <div class="custom-dropdown">
                    <mat-select [(ngModel)]="selectedGender" name="genderSelection"
                      class="borde p-3 w-full md:w-2/3 centered-text brown-border custom-input" tabindex="5"
                      (selectionChange)="onGenderSelected($event)" (focus)="onFocus($event)" (blur)="onBlur($event)">
                      <mat-option *ngFor="let gender of genders; trackBy: trackByFn" [value]="gender.gndR_ID">
                        {{ 'gender.' + gender.gndR_NAME | translate }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="emailInput" class="title-input-text">
                  {{ "candidateDetailGeneralDataEmail" | translate }}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="emailInput" type="email" class="custom-input"
                    [placeholder]="'candidatePlaceHolderEmail' | translate" [(ngModel)]="candidate.cdT_EMAIL"
                    name="mail" required email #emailInput="ngModel" maxlength="100" tabindex="6"
                    oninput="this.value = this.value.split('').filter(char => /^[a-zA-Z0-9._%+-@]*$/.test(char)).join('').trimStart();"
                    autocapitalize="none">
                  <div *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)" class="text-red-500">
                    <!--                  <div *ngIf="emailInput.errors?.required">
                    {{ 'candidateDetailRequiredEmail' | translate }}
                  </div> -->
                    <div *ngIf="emailInput.errors?.email">
                      {{ 'candidateDetailRequiredFormatEmail' | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="phoneInput" class="title-input-text">
                  {{"candidateDetailGeneralDataCelphone" | translate}}
                </label>
                <div [ngClass]="{'error-border': isPhoneInvalid}" class="input-container mobile-responsive">
                  <app-phone-lada-select [phone]="candidate.cdT_PHONE_NUMBER_ONE" [tabindex1]="7" [tabindex2]="8"
                    [placeholder]="'candidatePlaceHolderPhone' | translate"
                    (phoneval)="candidate.cdT_PHONE_NUMBER_ONE = $event">
                  </app-phone-lada-select>
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="candidateDetailReferenceBy2" class="title-input-text">
                  {{"candidateDetailReferenceBy2" | translate}}*
                </label>
                <div [ngClass]="{'error-border': isReferenceInvalid}">
                  <div class="custom-dropdown">
                    <mat-select id="candidateDetailReferenceBy2" [(ngModel)]="selectedSource"
                      name="candidateDetailReferenceBy2"
                      class="borde p-3 w-full md:w-2/3 centered-text brown-border custom-input" tabindex="9"
                      (selectionChange)="onSourceSelected($event)" (focus)="onFocus($event)" (blur)="onBlur($event)">
                      <mat-option *ngFor="let source of sources; trackBy: trackByFn"
                        [value]="source.recruitmenT_SOURCE_ID">
                        {{ source.recruitmenT_SOURCE_NAME }}
                      </mat-option>
                      <mat-option value="1">
                        {{ "candidateDetailReferred" | translate }}
                      </mat-option>
                      <mat-option value="0">{{ "candidateDetailOther" | translate }}</mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>

              <div *ngIf="selectedSource == '0'" class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="candidateDetailOtherReference" class="title-input-text">
                  {{"candidateDetailOtherReference" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="candidateDetailOtherReference" type="text" class="custom-input"
                    [placeholder]="'candidateDetailOtherReferencePlaceHolder' | translate"
                    [(ngModel)]="candidate.cdT_OTHER_REFERRED" name="candidateDetailOtherReference" tabindex="10"
                    maxlength="50" required
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>

              <div *ngIf="selectedSource == '1'" class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="candidateDetailReferenceBy" class="title-input-text">
                  {{"candidateDetailReferenceBy3" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="candidateDetailReferenceBy" type="text" class="custom-input"
                    [placeholder]="'candidateDetailReferenceByPlaceHolder' | translate"
                    [(ngModel)]="candidate.cdT_REFERRED_BY" name="candidateDetailReferenceBy" tabindex="11"
                    [maxlength]="50" required
                    oninput="this.value = this.value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();">
                </div>
              </div>

              <!-- Nivel de estudios -->
              <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="genderSelect" class="title-input-text">
                  {{"candidateStudyLevel" | translate}}*
                </label>
                <div [ngClass]="{'error-border': isStudyLevelInvalid}">
                  <div class="custom-dropdown">
                    <mat-select [(ngModel)]="selectedStudyLevel" name="studySelection"
                      class="borde p-3 w-full md:w-2/3 centered-text brown-border custom-input" tabindex="12"
                      (selectionChange)="onStudyLevelSelected($event)" (focus)="onFocus($event)"
                      (blur)="onBlur($event)">
                      <mat-option *ngFor="let option of studyLevels" [value]="option.scH_ID">
                        {{ ('study.' + option.scH_NAME) | translate }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>

              <!-- Experiencia -->
              <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="genderSelect" class="title-input-text">
                  {{"candidateYearsOfExperience" | translate}}*
                </label>
                <div [ngClass]="{'error-border': isYearsOfExperienceInvalid}">
                  <div class="custom-dropdown">
                    <mat-select [(ngModel)]="selectedYearsOfExperience" name="yearsSelection"
                      class="borde p-3 w-full md:w-2/3 centered-text brown-border custom-input" tabindex="13"
                      (selectionChange)="onYearsOfExperienceSelected($event)" (focus)="onFocus($event)"
                      (blur)="onBlur($event)">
                      <mat-option *ngFor="let option of yearsOfExperience" [value]="option.exP_ID">
                        {{ 'experience.' + option.exP_NAME | translate }}
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>

              <!-- Fecha de nacimiento -->
              <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="birthdateInput" class="title-input-text">
                  {{"candidateDetailGeneralDataBirthdate" | translate}}*
                </label>
                <div class="h-full">
                  <app-lang-datepicker #datePicker id="birthdateInput"
                    [borde]="(isBirthdateInvalid || isBirthdateEmpty) ? 'red' : '#BDBDBD'"
                    [date]="candidate.cdT_BIRTH_DATE" [max]="maxDate" [tabindex]="14"
                    (datechange)="candidate.cdT_BIRTH_DATE = $event" [lang]="currentLang">
                  </app-lang-datepicker>
                </div>
              </div>

              <!-- Salario -->
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="candidateDetailDetailSalaryExpectation" class="title-input-text">
                  {{ "candidateDetailDetailSalaryExpectation" | translate }}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="candidateDetailDetailSalaryExpectation" type="text" class="custom-input" maxlength="13"
                    [placeholder]="'candidateDetailSalaryPlaceHolder' | translate" name="salary"
                    [(ngModel)]="formattedSalary" (numericValue)="actualSalary = $event" appFormatCurrencyDirective
                    tabindex="15">
                </div>
              </div>

              <!-- Link personal -->
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="candidatePortfolio" class="title-input-text">
                  {{"candidatePortfolio" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="candidatePortfolio" type="text" class="custom-input"
                    [placeholder]="'candidatePortfolioPlaceHolder' | translate" [(ngModel)]="candidate.cdT_PORTFOLIO"
                    name="candidatePortfolio" tabindex="16" maxlength="120">
                </div>
              </div>

              <!-- <div class="py-1 px-1 w-full md:w-1/2  lg:w-1/3 pb-5">
                <label for="phone2Input" class="title-input-text">
                  {{"candidateDetailGeneralDataPhone" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="phone2Input" type="text" class="custom-input"
                    [placeholder]="'candidatePlaceHolderPhone' | translate" [(ngModel)]="candidate.cdT_PHONE_NUMBER_TWO"
                    name="phone2" maxlength="10" (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                    tabindex="8">
                </div>
              </div> -->

              <div *ngIf="isDropdownVisible" class="py-1 px-1 w-full pb-5">
                <div class="flex justify-start items-center">
                  <div class="text-left mr-4">
                    <label for="state" class="gray-subtitle pb-2 block">
                      {{ (companyId === 'a9240b0e-24ab-4df5-a631-a0948d152d09'
                      ? 'customTranslationKeyForCompany'
                      : 'candidateExternalUploadDocuments')
                      | translate }}
                    </label>
                  </div>
                </div>
                <input #fileSelect id="fileSelect" type="file"
                  accept=".doc,.docx,application/msword,application/vnd.ms-excel,.xlsx,.xls,image/*,application/pdf,.pdf"
                  (click)="fileSelect.value = null" (change)="fileChangeEvent($event,0) " class="hidden">

                <div *ngIf="showdocuments" class="flex flex-col md:flex-row md:items-center w-full">
                  <app-custom-dropdown [ngClass]="{'error-border': isFileInvalid}" [items]="visibleInnerDocs"
                    [(ngModel)]="selectedInnerDoc" [translateLabels]="true" name="innerdoc" width="100%"
                    class="w-full mb-4 md:mb-0 border-input" displayProperty="pdT_NAME" tabindexnumber="17">
                  </app-custom-dropdown>

                  <div class="w-full md:w-auto pl-0 md:pl-4">
                    <app-custom-primary-button size="excel-size" color="secondary" type="button"
                      class="w-full border-input" (click)="fileSelectClick()" tabindex="18"
                      (keypress)="onAddFileKeyPress($event)">
                      {{ "candidateDetailSaveDocuments" | translate }}
                    </app-custom-primary-button>
                  </div>
                </div>

                <div *ngIf="selectedInnerDoc" class="mt-4">
                  <div class="drag-drop-area border-dashed border-2 rounded-lg p-4 text-center cursor-pointer"
                    [ngClass]="dragging ? 'dragging' : 'border-gray-400'" (dragover)="onDragOver($event)"
                    (dragleave)="onDragLeave($event)" (drop)="onDrop($event)" (click)="fileSelectClick()">
                    <div class="py-10">
                      <div class="text-gray-500 m-0">
                        {{ "dragAndDrop" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="!isDropdownVisible || innerDocuments.length > 0" class="py-1 px-1 w-full pb-5 flex-col">
                <div *ngFor="let innerDoc of innerDocuments; let i = index">
                  <a (click)="opendoc(innerDoc.cdoC_DOCUMENT_ROUTE)"
                    class="document-card w-full gray-subtitle flex items-center justify-between p-2 mt-3">
                    <div class="flex items-center">
                      <img [src]="fileIcon" alt="Document Icon" class="mr-2">
                      {{ innerDoc.cdoC_DOCUMENT_NAME | translate }}
                    </div>
                    <a *ngIf="showdocuments" (click)="deletedoc(innerDoc,0)" class="flex items-center">
                      <img [src]="deleteIcon" alt="trash">
                    </a>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Cuestionario filtro -->
        <div *ngIf="questionaire" class="flex flex-col py-3 w-full px-6 wizard-container mb-4">
          <div class="flex justify-between items-center py-1.5 text-xl wizard-header"
            (click)="toggleWizardContainerQuestionnaire()" (keydown)="handleKeydownQuestionnaire($event)" tabindex="19">
            <div class="dropdown-title pl-2">
              {{"candidateDetailQuestionnaireData" | translate}}
            </div>
            <button type="button">
              <img [src]="expandIcon" alt="" [ngClass]="{'rotate-180': isWizardContainerExpandedQuestionnaire}">
            </button>
          </div>
          <div *ngIf="isWizardContainerExpandedQuestionnaire" class="flex flex-col md:flex-row flex-wrap p-4">
            <div class="flex flex-col mb-2 gap-1 w-full">
              <div class="w-full green-title text-sm">
                {{ questionaire.questionnairE_NAME }}
              </div>
              <div class="w-full gray-subtitle text-xs text-wrap whitespace-pre-wrap">
                {{ questionaire.questionnairE_DESCRIPTION }}
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-3 items-end">
              <div *ngFor="let question of questionaire.questions; let i = index" class="w-full">
                <label class="title-input-text">
                  {{ question.questioN_TEXT }}*
                </label>
                <div *ngIf="question.questioN_TYPE == 'OPEN_ENDED'" class="input-container mobile-responsive">
                  <input [id]="'questionFormQuest' + i" type="text" class="custom-input2" [tabIndex]="20 + i"
                    [name]="'quest' + i" [ngClass]="{'error-input-border': question.invalid}"
                    [placeholder]="question.questioN_TEXT" [(ngModel)]="question.response" maxlength="4000">
                </div>
                <div *ngIf="['MULTIPLE_CHOICE', 'YES_NO'].includes(question.questioN_TYPE)" class="flex flex-col gap-2">
                  <mat-select [id]="'questionFormQuest' + i" [(ngModel)]="question.response" [name]="'quest' + i"
                    class="borde p-3 w-full" [tabIndex]="20 + i" [ngClass]="{'error-input-border': question.invalid}"
                    (focus)="onFocus($event)" (blur)="onBlur($event)">
                    <mat-option *ngFor="let option of question.options; let i = index" [value]="option.optioN_ID">
                      {{ option.optioN_TEXT | translate }}
                    </mat-option>
                  </mat-select>
                </div>
                <div *ngIf="question.questioN_TYPE == 'CHECKBOXES'" class="flex flex-col gap-2">
                  <mat-select [id]="'questionFormQuest' + i" [(ngModel)]="question.response" [name]="'quest' + i"
                    class="borde p-3 w-full" [tabIndex]="20 + i" [ngClass]="{'error-input-border': question.invalid}"
                    multiple (focus)="onFocus($event)" (blur)="onBlur($event)"
                    (selectionChange)="onSelectionChange($event, question)">
                    <mat-option *ngFor="let option of question.options; let i = index" [value]="option.optioN_ID"
                      [disabled]="isOptionDisabled(question, option.optioN_ID)">
                      {{ option.optioN_TEXT | translate }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!--Esta sección es para el nuevo candidato Información de dirección (Opcional)-->
        <!--<div class="flex flex-col py-3 w-full px-6 wizard-container mb-4">
          <div class="flex justify-between items-center py-1.5 text-xl wizard-header"
            (click)="toggleWizardContainerAdress()" (keydown)="handleKeydownAdress($event)" tabindex="9">
            <div class="dropdown-title pl-2">
              {{"candidateDetailAdressData" | translate}}
            </div>
            <button type="button">
              <img [src]="expandIcon" alt="" [ngClass]="{'rotate-180': isWizardContainerExpandedAdress}">
            </button>
          </div>
          <div *ngIf="isWizardContainerExpandedAdress" class="flex flex-col md:flex-row flex-wrap p-4">
            <div class="flex flex-col md:flex-row flex-wrap w-full">

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="streetInput" class="title-input-text">
                  {{"candidateDetailGeneralDataAdressStreet" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="streetInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderStreet' | translate"
                    [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_STREET" name="street" maxlength="100" tabindex="10">
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="extnumInput" class="title-input-text">
                  {{"candidateDetailGeneralDataAdressNumber" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="extnumInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderExtNumber' | translate"
                    [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_STREET_NUMBER" name="extnum" maxlength="100"
                    tabindex="11">
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="intnumInput" class="title-input-text">
                  {{"candidateDetailGeneralDataAdressNumberInt" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="intnumInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderIntNumber' | translate"
                    [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_SUITE_NUMBER" name="intnum" maxlength="100"
                    tabindex="12">
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="suburbInput" class="title-input-text">
                  {{"candidateDetailGeneralDataAdressColony" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="suburbInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderSuburb' | translate"
                    [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_SUBURB" name="suburb" maxlength="100" tabindex="13">
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <div>
                  <label for="stateSelect" class="title-input-text">
                    {{"candidateDetailGeneralDataAddressState" | translate}}
                  </label>
                    <mat-select [(ngModel)]="selectedState" name="stateSelection" class="borde p-3 w-full md:w-2/3 centered-text brown-border" tabindex="14" (selectionChange)="onStateMatSelectChange($event)">
                      <mat-option *ngFor="let state of states; trackBy: trackByStateFn" [value]="state">
                        {{ state.stS_NAME }}
                      </mat-option>
                    </mat-select>
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="citySelect" class="title-input-text">
                  {{"candidateDetailGeneralDataAddressCity" | translate}}
                </label>
                <mat-select [(ngModel)]="selectedCity" name="citySelection" class="borde p-3 w-full md:w-2/3 centered-text brown-border" tabindex="15" (selectionChange)="onCityMatSelectChange($event)">
                  <mat-option *ngFor="let city of cities; trackBy: trackByCityFn" [value]="city">
                    {{ city.ctY_NAME }}
                  </mat-option>
                </mat-select>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="cpInput" class="title-input-text">
                  {{ "candidateDetailGeneralDataAdressPostalCode" | translate }}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="cpInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderPostalCode' | translate"
                    [(ngModel)]="candidate.cdT_ADDRESS_INFO.adR_CP" name="cp" maxlength="5"
                    (keypress)="($event.charCode >= 48 && $event.charCode < 58 )" tabindex="16">
                </div>
              </div>
            </div>
          </div>
        </div>-->

        <!--Esta sección es para el nuevo candidato Datos personales (Opcional)-->
        <!--<div class="flex flex-col py-3 w-full px-6 wizard-container mb-4">
          <div class="flex justify-between items-center py-1.5 text-xl wizard-header"
            (click)="toggleWizardContainerGeneralOptional()" (keydown)="handleKeydownGeneralData($event)" tabindex="17">
            <div class="dropdown-title pl-2">
              {{"candidateDetailOptional" | translate}}
            </div>
            <button type="button">
              <img [src]="expandIcon" alt="" [ngClass]="{'rotate-180': isWizardContainerGeneralOptionalExpanded}">
            </button>
          </div>
          <div *ngIf="isWizardContainerGeneralOptionalExpanded" class="flex flex-col md:flex-row flex-wrap p-4">
            <div class="flex flex-col md:flex-row flex-wrap">

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="streetInput" class="title-input-text">
                  {{"candidateDetailGeneralDataCURP" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="curpInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderCURP' | translate" [(ngModel)]="candidate.cdT_CURP" name="curp"
                    maxlength="18" tabindex="18">
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="extnumInput" class="title-input-text">
                  {{"candidateDetailGeneralDataRFC" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="rfcInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderRFC' | translate" [(ngModel)]="candidate.cdT_RFC" name="rfc"
                    maxlength="13" tabindex="19">
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="intnumInput" class="title-input-text">
                  {{"candidateDetailGeneralDataNSS" | translate}}
                </label>
                <div class="input-container mobile-responsive">
                  <input id="nssInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderNSS' | translate"
                    (keypress)="($event.charCode >= 48 && $event.charCode <= 57)"
                    [(ngModel)]="candidate.cdT_SOCIAL_SECURITY_NUMBER" name="nss" maxlength="11" tabindex="20">
                </div>
              </div>
              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="suburbInput" class="title-input-text">
                  {{"candidateDetailGeneralDataBirthdate" | translate}}
                </label>
                <div class="">
                  <div class="datepicker-flex-container">
                    <input id="birthdateInput" type="date" class="dropdown-selected"
                      [ngModel]="candidate.cdT_BIRTH_DATE | date: 'yyyy-MM-dd'"
                      (ngModelChange)="candidate.cdT_BIRTH_DATE = $event" [max]="maxDate" name="dob" tabindex="21">
                  </div>
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <div>
                  <label for="stateSelect" class="title-input-text">
                    {{"candidateDetailGeneralDataBirthState" | translate}}
                  </label>
                  <mat-select [(ngModel)]="selectedBirthState" name="birthstateSelection" (selectionChange)="onStateMatSelectChange2($event)" class="borde p-3 w-full md:w-2/3 centered-text brown-border" tabindex="22">
                    <mat-option *ngFor="let state of states2; trackBy: trackByBirthStateFn" [value]="state">
                      {{ state.stS_NAME }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="birthcitySelect" class="title-input-text">
                  {{"candidateDetailGeneralDataBirthCity" | translate}}
                </label>
                <mat-select [(ngModel)]="selectedBirthCity" name="birthcitySelection" (selectionChange)="onCityMatSelectChange2($event)" class="borde p-3 w-full md:w-2/3 centered-text brown-border" tabindex="23">
                  <mat-option *ngFor="let city of cities2; trackBy: trackByCityFn2" [value]="city">
                    {{ city.ctY_NAME }}
                  </mat-option>
                </mat-select>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <div>
                  <label for="stateSelect" class="title-input-text">
                    {{"candidateDetailGeneralDataProfessionalLicense" | translate}}
                  </label>
                  <input id="cedulaprofesionalInput" type="text" class="custom-input input-width-height"
                    [placeholder]="'candidatePlaceHolderProfessionalLicense' | translate"
                    (keypress)="($event.charCode >= 48 && $event.charCode < 58 )"
                    [(ngModel)]="candidate.cdT_PROFESSIONAL_CERTIFICATE" name="cedulaprofesional" maxlength="13"
                    tabindex="24">
                </div>
              </div>

              <div class="py-1 px-1 w-full md:w-1/2 lg:w-1/3 pb-5">
                <label for="birthcitySelect" class="title-input-text">
                  {{"candidateDetailGeneralDataCredit" | translate}}
                </label>
                <input id="creditoinfonavitInput" type="text" class="custom-input input-width-height"
                  [placeholder]="'candidatePlaceHolderCredit' | translate"
                  (keypress)="($event.charCode >= 48 && $event.charCode < 58 )"
                  [(ngModel)]="candidate.cdT_INFONAVIT_CREDIT" name="creditoinfonavit" maxlength="13" tabindex="25">
              </div>

            </div>
          </div>
        </div>-->

        <!--Esta sección es para el nuevo candidato Subir documentos (Opcional)-->
        <!--<div class="flex flex-col py-3 w-full px-6 wizard-container mb-4">
          <div class="flex justify-between items-center py-1.5 text-xl wizard-header"
            (click)="toogleWizardContainerExpedient()" (keydown)="handleKeydownContainerExpedient($event)"
            tabindex="26">
            <div class="dropdown-title pl-2">
              {{"candidateDetailExpedient" | translate}}
            </div>
            <button type="button">
              <img [src]="expandIcon" alt="" [ngClass]="{'rotate-180': isWizardContainerDigitalExpedientExpanded}">
            </button>
          </div>
          <div *ngIf="isWizardContainerDigitalExpedientExpanded" class="flex flex-col md:flex-row flex-wrap p-4">
            <div class="flex flex-col w-full">
              <div class="py-2 px-6 w-full">
                <div class="flex justify-start items-center">
                  <div class="text-left mr-4">
                    <label for="state" class="gray-subtitle pb-2 block">
                      {{ "candidateExternalUploadDocuments" | translate }}
                    </label>
                  </div>
                </div>
                <input #fileSelect id="fileSelect" type="file"
                  accept=".doc,.docx,application/msword,application/vnd.ms-excel,.xlsx,.xls,image/*,application/pdf,.pdf"
                  (click)="fileSelect.value = null" (change)="fileChangeEvent($event,0) " tabindex="26" class="hidden">

                <div *ngIf="showdocuments" class="flex flex-col md:flex-row md:items-center w-full">
                  <app-custom-dropdown [items]="visibleInnerDocs" [(ngModel)]="selectedInnerDoc" name="innerdoc"
                    width="100%" class="w-full mb-4 md:mb-0" displayProperty="pdT_NAME" tabindex="27">
                  </app-custom-dropdown>

                  <div class="w-full md:w-auto pl-0 md:pl-4">
                    <app-custom-primary-button size="excel-size" color="secondary" type="button" class="w-full"
                      (click)="fileSelectClick()" tabindex="28">
                      {{ "candidateDetailSaveDocuments" | translate }}
                    </app-custom-primary-button>
                  </div>
                </div>
              </div>

              <div class="py-4 pl-5 flex flex-wrap">
                <div *ngFor="let innerDoc of innerDocuments; let i = index" class="flex py-2 pr-5 md:w-1/2 w-full">
                  <a (click)="opendoc(innerDoc.cdoC_DOCUMENT_ROUTE)"
                    class="document-card w-full gray-subtitle flex items-center justify-between p-2">
                    <div class="flex items-center">
                      <img [src]="fileIcon" alt="Document Icon" class="mr-2">
                      {{ innerDoc.cdoC_DOCUMENT_NAME }}
                    </div>
                    <a *ngIf="showdocuments" (click)="deletedoc(innerDoc,0)" class="flex items-center">
                      <img [src]="deleteIcon" alt="trash">
                    </a>
                  </a>
                </div>
              </div>

              <div *ngIf="innerDocuments.length === 0" class="flex flex-col items-center justify-center px-6">
                <img [src]="expedientImage" alt="Sin documentos" class="w-60 h-40">
                <div class="text-left w-full">
                  <div class="green-title pb-4 pt-4">{{ "candidateExternalNoDocuments" | translate }}</div>
                  <div class="pb-2 gray-subtitle">
                    {{ "candidateExternalSubtitle" | translate }}
                  </div>
                  <div class="gray-subtitle">
                    {{ "candidateExternalSubtitle2" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
    <div class="pb-3 text-center md:text-right">

      <input class="validate align-middle" type="checkbox" id="terms" name="terms" value="terms"
        [(ngModel)]="termsAccepted" required tabindex="29">
      <label for="terms" class="inline align-middle ml-2"> {{"loginAskAcept" | translate}} <a
          class="cursor-pointer color-haibu" href="/terms" target="_blank">{{"loginTermsAndCondition" |
          translate}}</a></label>
    </div>
    <div class="flex justify-center md:justify-end">
      <div class="w-full px-4 md:px-0 md:w-auto">
        <app-custom-primary-button [disabled]="isButtonDisabled" type="submit" color="primary" size="small"
          class="w-full md:w-auto" (click)="submitted = true" (keydown.space)="onSpacePress($event)"
          (keydown.enter)="onEnterPress($event)" tabindex="30">
          <span *ngIf="!isButtonDisabled">{{ "clientDetailConfCreate" | translate }}</span>
          <div *ngIf="isButtonDisabled" class="w-full flex justify-center">
            <mat-spinner diameter="20" color="primary"></mat-spinner>
          </div>
        </app-custom-primary-button>
      </div>
    </div>

  </form>
</div>

<div *ngIf="isTokenValid === false" class="flex flex-col items-center justify-center min-h-screen">
  <img [src]="haibuBroken" alt="Token not valid" class="w-1/3 md:w-1/4 max-w-xs mx-auto">
  <p class="mt-4 text-lg">{{ 'candidateExternalTokenNotValid' | translate }}</p>
</div>

<div *ngIf="isTokenValid === null" class="flex flex-col items-center justify-center min-h-screen">
  <img [src]="haibuLoading" alt="Loading..." class="w-1/3 md:w-1/4 max-w-xs mx-auto">
  <p class="mt-4 text-lg">{{ 'candidateExternalValidatingToken' | translate }}</p>
</div>

<div *ngIf="isFormAlreadySubmitted" class="flex flex-col items-center justify-center min-h-screen">
  <img [src]="haibuCheck" alt="Submission confirmed" class="w-1/3 md:w-1/4 max-w-xs mx-auto">
  <div class="mt-4 px-4 md:px-20 lg:px-32 xl:px-48">
    <p class="text-lg text-center"
      [innerHTML]="'candidateExternalFormComplete' | translate:{ company: companyName, job: jobName }"></p>
  </div>
</div>