import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../../interfaces/user';
import { PasswordDialogComponent } from '../../dialogs/password-dialog/password-dialog.component';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { AddDialogComponent } from '../../dialogs/add-dialog/add-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PuzzleService } from '../../services/puzzle.service';
import { Country } from '../../interfaces/country';
import { ctR_STATES } from '../../interfaces/ctrstate';
import { stS_CITIES } from '../../interfaces/stscity';
import { Company } from '../../interfaces/company';
import { LocalService } from '../../services/local.service';
import { ResponseDialogComponent } from '../../dialogs/response-dialog/response-dialog.component';
import { APIResponse } from '../../interfaces/response';
import { UserStatus } from '../../interfaces/user-status';
import { NgForm } from '@angular/forms';
import { InjectUser } from '../../interfaces/inject-user';
import { PageEvent } from '@angular/material/paginator';
import { SwUpdate } from '@angular/service-worker';
import { constants } from 'src/app/services/constants';
import { TranslateService } from '@ngx-translate/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddSubdomainComponent } from 'src/app/dialogs/add-subdomain/add-subdomain.component';
import { UploadCsfDialogComponent } from 'src/app/dialogs/upload-csf-dialog/upload-csf-dialog.component';
import { environment } from 'src/environments/environment';
import { DocuViewerComponent } from 'src/app/dialogs/docu-viewer/docu-viewer.component';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css'],
})
export class ClientDetailComponent implements OnInit {

  length = 0;
  pageSize = 50;
  pageIndex = 0;
  pageSizeOptions = [50, 100, 200];
  showFirstLastButtons = true;
  filtros: boolean = false;
  data: string[] = [];
  newClient: boolean = false;
  id: string;
  iduser: string;
  token: APIResponse = this.localService.getJsonValue('token');
  actualDate: Date = new Date();
  countries: Country[];
  users: User[];
  states: ctR_STATES[];
  cities: stS_CITIES[];
  dataSource: User[];
  superadmin: boolean = false;
  customstatesandcities: boolean = false;
  user: User;
  languagePreference: string = '';
  nombre: string = null;
  estatus: string = 'Todos';
  receivedCompany: Company = {
    comP_ID: null,
    // comP_CODE:            '',
    comP_NAME: '',
    comP_BUSINESS_NAME: '',
    comP_RFC: '',
    comP_LOGO_ROUTE: '',
    comP_ADDRESS_ID: null,
    comP_USER_LIMIT: null,
    comP_ACTIVE: 1,
    comP_ADDRESS_ID_INFO: {
      adR_COUNTRY_ID: '',
      adR_STATE_ID: null,
      adR_STATE_CUSTOM: null,
      adR_CITY_ID: null,
      adR_CITY_CUSTOM: null,
      adR_STREET: '',
      adR_SUBURB: '',
      adR_CP: '',
      adR_STREET_NUMBER: '',
      adR_SUITE_NUMBER: '',
      adR_COUNTRY_ID_INFO: null,
      adR_STATE_ID_INFO: null,
      adR_CITY_ID_INFO: null,
    },
    comP_FREEMIUM: false,
    comP_JOBS_MANAGEMENT: false,
    comP_AREA_ID: null
  }
  company: Company = {
    comP_ID: null,
    // comP_CODE:            '',
    comP_NAME: '',
    comP_BUSINESS_NAME: '',
    comP_RFC: '',
    comP_LOGO_ROUTE: '',
    comP_ADDRESS_ID: null,
    comP_USER_LIMIT: null,
    comP_ACTIVE: 1,
    comP_PRIMARY_COLOR: '',
    comP_SUBDOMAIN: '',
    comP_ADDRESS_ID_INFO: {
      adR_COUNTRY_ID: '',
      adR_STATE_ID: null,
      adR_STATE_CUSTOM: null,
      adR_CITY_ID: null,
      adR_CITY_CUSTOM: null,
      adR_STREET: '',
      adR_SUBURB: '',
      adR_CP: '',
      adR_STREET_NUMBER: '',
      adR_SUITE_NUMBER: '',
      adR_COUNTRY_ID_INFO: null,
      adR_STATE_ID_INFO: null,
      adR_CITY_ID_INFO: null,
    },
    comP_FREEMIUM: false,
    comP_JOBS_MANAGEMENT: false,
    comP_AREA_ID: null
  }
  edit: boolean = false;
  displayedColumns: string[] = [
    'anU_NAME',
    'anU_LAST_NAME',
    'email',
    'roleName',
    'anU_ACTIVE_DISPLAY',
    'contra',
    'accion'
  ]
  editIcon: SafeResourceUrl = '../../../assets/icons/edit-icon.svg';
  passwordIcon: SafeResourceUrl = '../../../../assets/icons/password-icon.svg';
  firstSectionData: Company;
  secondSectionData: Company;
  thirdSectionData: Company;
  editFirstSection: boolean = false;
  editSecondSection: boolean = false;
  editThirdSection: boolean = false;
  catalogueSection: boolean = false;
  selectedState: any;
  selectedCountry: any;
  selectedCity: any;
  userPermissions: string[];
  loadingDetail: boolean = false;
  loading: boolean = false;
  sending: boolean = false;
  areas: any[] = [];

  editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['clean']
    ]
  };

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.puzzleService.getAllUsers(1000, this.pageIndex, this.nombre, this.estatus, this.id).subscribe(
      users => {
        this.users = users;
        this.dataSource = users;
      }
    )
  }

  constructor(private translate: TranslateService, private swUpdate: SwUpdate, public dialog: MatDialog, private route: ActivatedRoute, private router: Router, private puzzleService: PuzzleService, private localService: LocalService, private fb: FormBuilder, private renderer: Renderer2) {
    this.swUpdate.checkForUpdate();
  }

  getCompany() {
    this.puzzleService.getCompanyById(this.id).subscribe(company => {
      this.receivedCompany = JSON.parse(JSON.stringify(company));
      this.company = JSON.parse(JSON.stringify(company));
      if (this.company.comP_TRIAL_END_DATE && this.superadmin) {
        this.company.comP_CHANGE_DATE_TRIAL = new Date(this.company.comP_TRIAL_END_DATE);
      }

      if (!this.company.comP_WORKING_DAYS_PER_WEEK || this.company.comP_WORKING_DAYS_PER_WEEK < 5) {
        this.company.comP_WORKING_DAYS_PER_WEEK = 7;
        this.receivedCompany.comP_WORKING_DAYS_PER_WEEK = 7;
      }

      if (this.company.comP_USE_NATURAL_DAYS == null) {
        this.company.comP_USE_NATURAL_DAYS = true;
        this.receivedCompany.comP_USE_NATURAL_DAYS = true;
      }

      try {
        this.customstatesandcities = this.receivedCompany.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID_INFO.ctR_CUSTOM_INFO_ACTIVE;
      } catch (error) {
        this.customstatesandcities = true;
      }

      if (this.countries != null) {
        if (this.receivedCompany && this.receivedCompany.comP_ADDRESS_ID_INFO.adR_STATE_ID && this.receivedCompany.comP_ADDRESS_ID_INFO.adR_CITY_ID_INFO) {
          let indexStates = this.countries.findIndex(x => x.ctR_ID == this.receivedCompany.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID)
          let indexCities = this.countries[indexStates].ctR_STATES.findIndex(x => x.stS_ID == this.receivedCompany.comP_ADDRESS_ID_INFO.adR_STATE_ID)
          this.loadStates(indexStates);
          this.loadCities(indexCities);
        }

        if (this.receivedCompany && this.receivedCompany.comP_ADDRESS_ID_INFO.adR_STATE_ID) {
          let indexStates = this.countries.findIndex(x => x.ctR_ID == this.receivedCompany.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID)
          this.loadStates(indexStates);

        }

      }

      this.puzzleService.getAllUsers(1000, this.pageIndex, null, null, this.id).subscribe(users => {
        this.users = users;
        this.dataSource = this.users;

        try {
          this.length = users[0].totaL_COUNT;
        } catch (error) {
          this.length = 0;
        }

      }, error => {
      });

      this.loadingDetail = false;
    });
  }

  ngOnInit(): void {

    if (this.token.data.roleId == constants.superadmin) {
      this.superadmin = true;
    } else {
      this.superadmin = false;
    }

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    this.id = urlParams.get('id');

    this.iduser = urlParams.get('user')

    if (this.router.url.includes('/home/catalogues/company')) {
      this.catalogueSection = true;
    }

    this.userPermissions = this.token.data?.permissions?.map((permission) => permission.code);
    if (!this.getPermission('m001')) {
      if (this.getPermission('u004')) {
        this.router.navigate(['/home/catalogues/users'], { queryParams: { id: urlParams.get('id') } });
      } else if (this.getPermission('r001')) {
        this.router.navigate(['/home/catalogues/roles'], { queryParams: { id: urlParams.get('id') } });
      } else if (this.getPermission('c001')) {
        this.router.navigate(['/home/catalogues/catalogs'], { queryParams: { id: urlParams.get('id') } });
      } else {
        this.router.navigate(['/home/candidateslist']);
      }
    }

    if (this.id && ((this.id == this.token.data.companyId) || this.superadmin)) {

      this.newClient = false;
      this.edit = false;

      this.loadingDetail = true;

      this.getCompany();

      if (this.iduser) {

        this.puzzleService.getUser(this.iduser).subscribe(user => {

          if (user.length == 1) {

            this.openPassDialog(user[0]);

          } else {
            this.openResponseDialog('Error', this.translate.instant('clientDetailAlertId'));
            this.router.navigate(['home/clientdetail'], { queryParams: { id: this.id } });

          }
        });

      }

    } else {

      this.newClient = true;

      if (!this.superadmin) {
        this.router.navigateByUrl('/home/candidateslist', { skipLocationChange: true });
      }

    }

    this.puzzleService.getCountries().subscribe(countries => {
      this.countries = countries;

      if (this.newClient) {
        this.company.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID = this.countries[141].ctR_ID;
        this.loadStates(141);
      }

      if (this.receivedCompany && !this.newClient) {
        try {
          this.customstatesandcities = this.receivedCompany.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID_INFO.ctR_CUSTOM_INFO_ACTIVE;

          if (!this.customstatesandcities) {
            let indexStates = this.countries.findIndex(x => x.ctR_ID == this.receivedCompany.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID)
            this.loadStates(indexStates);
          }
        } catch (error) {
          this.customstatesandcities = true;
        }

        if (this.receivedCompany && this.receivedCompany.comP_ADDRESS_ID_INFO.adR_STATE_ID && this.receivedCompany.comP_ADDRESS_ID_INFO.adR_CITY_ID_INFO) {
          let indexStates = this.countries.findIndex(x => x.ctR_ID == this.receivedCompany.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID)
          let indexCities = this.countries[indexStates].ctR_STATES.findIndex(x => x.stS_ID == this.receivedCompany.comP_ADDRESS_ID_INFO.adR_STATE_ID)

          this.loadStates(indexStates);
          this.loadCities(indexCities);

        }

        if (this.receivedCompany && this.receivedCompany.comP_ADDRESS_ID_INFO.adR_STATE_ID) {
          let indexStates = this.countries.findIndex(x => x.ctR_ID == this.receivedCompany.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID)
          this.loadStates(indexStates);
          let indexCities = this.countries[indexStates].ctR_STATES.findIndex(x => x.stS_ID == this.receivedCompany.comP_ADDRESS_ID_INFO.adR_STATE_ID)
          this.loadCities(indexCities);
        }
        if (!this.company.comP_PRIMARY_COLOR) {
          this.company.comP_PRIMARY_COLOR = '#000000';
        } else if (!this.company.comP_PRIMARY_COLOR.startsWith('#')) {
          this.company.comP_PRIMARY_COLOR = '#' + this.company.comP_PRIMARY_COLOR;
        }
      }
    });

    this.puzzleService.getCandidateExperienceAreas().subscribe(areas => {
      this.areas = areas;
    });
  }

  getAreaKey(area: string) {
    if (!area || area == '00000000-0000-0000-0000-000000000000') {
      return 'noAreaSelected';
    }
    return this.areas?.find(a => a.experienceAreaId == area)?.experienceAreaKey;
  }

  getPermission(permission: string): boolean {
    return this.userPermissions.includes(permission);
  }

  getTranslatedStatus(status: string) {
    if (status === 'Activo' || status === 'Inactivo') {
      return this.translate.instant(status.toLowerCase());
    }
    return status;
  }

  getTranslatedRole(role: string) {
    if (role === 'Administrador' || role === 'Ejecutivo' || role === 'Cliente') {
      return this.translate.instant(role.toLowerCase());
    }
    return role;
  }

  editCompany() {
    this.company = JSON.parse(JSON.stringify(this.receivedCompany))
    this.edit = !this.edit;
  }

  editBillingSection() {
    this.firstSectionData = JSON.parse(JSON.stringify(this.receivedCompany));
    this.editFirstSection = !this.editFirstSection;

    this.secondSectionData = JSON.parse(JSON.stringify(this.receivedCompany));
    this.editSecondSection = !this.editSecondSection;

    this.thirdSectionData = JSON.parse(JSON.stringify(this.receivedCompany));
    this.editThirdSection = !this.editThirdSection;

    this.company.comP_NAME = this.transformValue(this.company.comP_NAME);
    this.company.comP_AUTHORIZED_TEMPLATE = this.transformValueNumber(this.company.comP_AUTHORIZED_TEMPLATE.toString());
    this.company.comP_USER_LIMIT = Number(this.transformValueNumber(this.company.comP_USER_LIMIT.toString()));

    this.company.comP_BUSINESS_NAME = this.transformValue(this.company.comP_BUSINESS_NAME);
    this.company.comP_RFC = this.transformValueRFC(this.company.comP_RFC);
    this.company.comP_ADDRESS_ID_INFO.adR_STATE_CUSTOM = this.transformValue(this.company?.comP_ADDRESS_ID_INFO?.adR_STATE_CUSTOM);
    this.company.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM = this.transformValue(this.company?.comP_ADDRESS_ID_INFO?.adR_CITY_CUSTOM);
    this.company.comP_ADDRESS_ID_INFO.adR_STREET = this.transformValue(this.company?.comP_ADDRESS_ID_INFO?.adR_STREET);
    this.company.comP_ADDRESS_ID_INFO.adR_STREET_NUMBER = this.transformValue(this.company?.comP_ADDRESS_ID_INFO?.adR_STREET_NUMBER);
    this.company.comP_ADDRESS_ID_INFO.adR_SUITE_NUMBER = this.transformValue(this.company?.comP_ADDRESS_ID_INFO?.adR_SUITE_NUMBER);
    this.company.comP_ADDRESS_ID_INFO.adR_SUBURB = this.transformValue(this.company?.comP_ADDRESS_ID_INFO?.adR_SUBURB);
    this.company.comP_ADDRESS_ID_INFO.adR_CP = this.transformValueNumber(this.company?.comP_ADDRESS_ID_INFO?.adR_CP);
    this.company.comP_PRIMARY_COLOR = this.transformValue(this.company.comP_PRIMARY_COLOR);
    if (!this.company.comP_PRIMARY_COLOR) {
      this.company.comP_PRIMARY_COLOR = '#000000';
    } else if (!this.company.comP_PRIMARY_COLOR.startsWith('#')) {
      this.company.comP_PRIMARY_COLOR = '#' + this.company.comP_PRIMARY_COLOR;
    }
  }

  editAdressSection() {
    /* this.firstSectionData = JSON.parse(JSON.stringify(this.receivedCompany));
    this.editFirstSection = !this.editFirstSection;

    this.secondSectionData = JSON.parse(JSON.stringify(this.receivedCompany));
    this.editSecondSection = !this.editSecondSection; */

    this.editBillingSection();
  }

  editSubDomainSection() {

    this.editBillingSection();
  }

  cancelEdit() {
    this.editFirstSection = false;
    this.editSecondSection = false;
    this.editThirdSection = false;
  }

  cancelEditDirection() {
    this.editFirstSection = false;
    this.editSecondSection = false;
    this.editThirdSection = false;
  }

  openAddDialog(accion: string, iduser?: string) {
    let countUsers = 0;
    let injectUser: InjectUser = {
      company: this.id
    };

    if (accion === 'Agregar') {
      this.users.forEach(user => {
        if (user.anU_ACTIVE_DISPLAY === 'Activo') countUsers++;
      });
      if (countUsers >= this.receivedCompany.comP_USER_LIMIT) {
        this.openResponseDialog('Error', this.translate.instant('clientDetailAlertMaxUsers'));
        return;
      }

      const isMobile = window.matchMedia('(max-width: 900px)').matches;
      const dialogRef = this.dialog.open(AddDialogComponent, {
        maxWidth: isMobile ? '90vw' : '900px',
        maxHeight: '90vh',
        data: injectUser
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          if (result.isSuccess || result.status === 'Exito') {
            this.loadUsers();
            const successMessage = this.translate.instant(result.message);
            this.openResponseDialog("Exito", successMessage);
          } else {
            const errorMessage = this.translate.instant(result.message);
            this.openResponseDialog('Error', errorMessage);
          }
        }
      });
    } else if (accion === 'Editar') {
      if (iduser) {
        this.puzzleService.getUserbyID(iduser).subscribe(
          user => {
            if (user) {
              injectUser.user = user;
              const dialogRef = this.dialog.open(AddDialogComponent, {
                width: '900px',
                data: injectUser
              });

              dialogRef.afterClosed().subscribe(result => {
                if (result && result.status) {
                  if (result.status === 'Exito') {
                    this.loadUsers();
                    const successMessage = this.translate.instant(result.message);
                    this.openResponseDialog('Exito', successMessage);
                  } else if (result.status === 'Error') {
                    const errorMessage = this.translate.instant(result.message);
                    this.openResponseDialog('Error', errorMessage);
                  }
                }
              });
            } else {
              console.error("User data is empty");
              this.openResponseDialog('Error', 'User data is empty');
            }
          },
          error => {
            console.error("Error fetching user: ", error);
            this.openResponseDialog('Error', 'Error fetching user details');
          }
        );
      } else {
        console.error("iduser is undefined");
        this.openResponseDialog('Error', 'User ID is missing');
      }
    }
  }

  openCreateSubdomainDialog() {
    if (this.receivedCompany?.comP_HAS_SUBDOMAIN && !this.receivedCompany.comP_SUBDOMAIN) {
      const isMobile = window.matchMedia('(max-width: 900px)').matches;
      const dialogRef = this.dialog.open(AddSubdomainComponent, {
        maxWidth: isMobile ? '90vw' : '600px',
        maxHeight: '90vh',
        data: {
          otherData: {
            comP_ID: this.receivedCompany.comP_ID,
            ...this.receivedCompany
          }
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          if (result.isSuccess || result.status === 'Exito') {
            this.receivedCompany.comP_SUBDOMAIN = result.subdomain;
            const successMessage = this.translate.instant('subdomainCreatedSuccessfully');
            this.openResponseDialog("Exito", successMessage);
          } else {
            const errorMessage = this.translate.instant(result.message);
            this.openResponseDialog('Error', errorMessage);
          }
        }
      });
    }
  }


  private loadUsers() {
    this.loading = true;
    this.resetValues();
    if (this.token.data.roleId === constants.superadmin) {
      this.puzzleService.getAllUsers(1000, this.pageIndex, null, null, this.id).subscribe(users => {
        this.users = users;
        this.dataSource = this.users;
        this.loading = false;
      });
    } else {
      this.puzzleService.getAllUsers(1000, this.pageIndex).subscribe(users => {
        this.users = users;
        this.dataSource = this.users;
        this.loading = false;
      });
    }
  }

  private resetValues() {
    this.pageSize = 50;
    this.pageIndex = 0;
  }

  openPassDialog(user: User) {
    const dialogRef = this.dialog.open(PasswordDialogComponent, {
      width: '500px',
      /* height: '400px', */
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.openResponseDialog('Exito', this.translate.instant('clientDetailAlertPasswordSuccess'));
      }

    });
  }

  openResponseDialog(response: string, message?: string) {
    let data: string[] = [];
    if (response == 'Error') {
      data.push('Error');
      data.push(message);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    } else if (response == 'Exito') {
      if (this.newClient) {
        data.push('Exito');
        data.push(message);
      } else {
        data.push('Exito');
        data.push(message);
      }

      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
      this.puzzleService.getCompanyById(this.id).subscribe(company => {
        this.receivedCompany = company;
        this.company = this.receivedCompany;

      })
    }
  }

  openConfirmDialog(tipo: string, user: User) {

    this.data = [];

    if (tipo == 'Eliminar') {
      this.data.push(this.translate.instant('clientDetailUserListTableDelete'));
      this.data.push(this.translate.instant('clientDetailUserListTableAbilityMessage', { name: user.username }));
    }

    // Está seguro de que desea {{data[1]}} al {{data[2]}}: {{data[3]}}?

    if (tipo == 'Habilitar') {
      this.data.push(this.translate.instant('clientDetailUserListTableAbility'));
      this.data.push(this.translate.instant('clientDetailUserListTableAbilityMessage', { name: user.username }));
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: this.data
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == true) {
        let userStatus: UserStatus = {
          id: user.id,
          aNU_COMPANY_ID: user.anU_COMPANY_ID,
          ANU_DELETED: !user.anU_DELETED
        }
        this.puzzleService.editUserStatus(userStatus).subscribe(
          resp => {
            if (resp.isSuccess) {

              if (tipo == 'Habilitar') {
                this.openResponseDialog('Exito', this.translate.instant('clientDetailSuccesAlert'));
              } else {
                this.openResponseDialog('Exito', this.translate.instant('clientDetailSuccesAlertDelete'));
              }
              this.puzzleService.getAllUsers(1000, this.pageIndex, null, null, this.id).subscribe(
                users => {
                  this.users = users;
                  this.dataSource = users
                }
              )
            } else {
              this.openResponseDialog('Error', resp.message)
            }
          }
        )
      }

    });
  }

  Buscar() {
    this.pageIndex = 0;
    this.puzzleService.getAllUsers(1000, this.pageIndex, this.nombre, this.estatus, this.id).subscribe(
      users => {
        this.pageIndex = 0;
        this.length = users[0].totaL_COUNT;
        this.users = users;
        this.dataSource = users

      }
    )
  }

  Limpiar() {
    this.estatus = 'Todos';
    this.nombre = null;
    this.pageIndex = 0;
    this.puzzleService.getAllUsers(1000, this.pageIndex, this.nombre, this.estatus, this.id).subscribe(
      users => {
        this.users = users;
        this.dataSource = users
        this.length = users[0].totaL_COUNT
      }
    )
  }

  checkCustoms() {
    if (this.company.comP_ADDRESS_ID_INFO.adR_CITY_ID == null && this.company.comP_ADDRESS_ID_INFO.adR_STATE_ID == null) {
      this.customstatesandcities = true;
    }
  }

  loadStates(id: number) {
    // this.company.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM = null;
    // this.company.comP_ADDRESS_ID_INFO.adR_STATE_CUSTOM = null;
    // this.company.comP_ADDRESS_ID_INFO.adR_CITY_ID = null;
    // this.company.comP_ADDRESS_ID_INFO.adR_STATE_ID = null;

    if (this.countries[id].ctR_CUSTOM_INFO_ACTIVE == true) {
      this.customstatesandcities = true;
    } else {
      this.customstatesandcities = false;
      this.states = this.countries[id].ctR_STATES;
    }
  }

  loadCities(id: number) {
    this.cities = this.states[id].stS_CITIES;
  }

  onSelectCountry() {
    // eliminar valor de estado
    this.company.comP_ADDRESS_ID_INFO.adR_STATE_ID = null;
    this.company.comP_ADDRESS_ID_INFO.adR_STATE_CUSTOM = null;
    // eliminar valor de ciudad
    this.company.comP_ADDRESS_ID_INFO.adR_CITY_ID = null;
    this.company.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM = null;
  }

  loadcitiesid(id) {
    for (let i = 0; i < this.states.length; i++) {
      if (this.states[i].stS_ID == id) {
        this.cities = this.states[i].stS_CITIES;
        break;
      }
    }
    // eliminar valor de ciudad
    this.company.comP_ADDRESS_ID_INFO.adR_CITY_ID = null;
    this.company.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM = null;
  }

  onStateChange(newSelection: any) {
    this.selectedState = newSelection;
    this.company.comP_ADDRESS_ID_INFO.adR_STATE_ID = newSelection.stS_ID;
    this.cities = this.states.find(state => state.stS_ID === newSelection.stS_ID).stS_CITIES;
    this.selectedCity = null;
    this.company.comP_ADDRESS_ID_INFO.adR_CITY_ID = null;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  editOrCreateCompany(myForm: NgForm, section: number = null) {
    if (this.sending) {
      return
    }
    if (!this.superadmin) {
      this.company.comP_USER_LIMIT = this.receivedCompany.comP_USER_LIMIT;
    }
    Object.values(myForm.controls).forEach((control) => {
      control.markAllAsTouched();
    });
    if (!myForm.valid) {
      this.openResponseDialog('Error', this.translate.instant('candidateDetailAlert'));
    } else {
      if (this.company.comP_ADDRESS_ID_INFO.adR_CITY_ID != null && this.company.comP_ADDRESS_ID_INFO.adR_STATE_ID != null) {
        this.company.comP_ADDRESS_ID_INFO.adR_STATE_CUSTOM = null;
        this.company.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM = null;
      }
      if (this.company.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM != null && this.company.comP_ADDRESS_ID_INFO.adR_STATE_CUSTOM != null) {
        this.company.comP_ADDRESS_ID_INFO.adR_CITY_ID = null;
        this.company.comP_ADDRESS_ID_INFO.adR_STATE_ID = null;
      }

      if (!this.superadmin) {
        // validar pais, estado y ciudad
        if (this.company.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID == null) {
          this.openResponseDialog('Error', this.translate.instant('hasWhiteSpaceKeyForm', { key: this.translate.instant('clientDetailConfCountry') }));
          return;
        }

        if (this.company.comP_ADDRESS_ID_INFO.adR_STATE_ID == null && (this.company.comP_ADDRESS_ID_INFO.adR_STATE_CUSTOM == null || this.company.comP_ADDRESS_ID_INFO.adR_STATE_CUSTOM.trim() == '')) {
          this.openResponseDialog('Error', this.translate.instant('hasWhiteSpaceKeyForm', { key: this.translate.instant('clientDetailConfState') }));
          return;
        }

        if (this.company.comP_ADDRESS_ID_INFO.adR_CITY_ID == null && (this.company.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM == null || this.company.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM.trim() == '')) {
          this.openResponseDialog('Error', this.translate.instant('hasWhiteSpaceKeyForm', { key: this.translate.instant('clientDetailConfCity') }));
          return;
        }

        /* Seccion 1 */
        if (!this.company.comP_NAME || this.hasWhitespaceValidator(this.company.comP_NAME)) {
          this.openResponseDialog('Error', this.translate.instant('hasWhiteSpaceKeyForm', { key: this.translate.instant('clientDetailConfName') }));
          return;
        } else if (this.hasSpecialCharacters(this.company.comP_NAME)) {
          this.openResponseDialog('Error', this.translate.instant('invalidCharactersKeyForm', { key: this.translate.instant('clientDetailConfName') }));
          return;
        }

        if (this.company.comP_AUTHORIZED_TEMPLATE < 0) {
          this.openResponseDialog('Error', this.translate.instant('invalidAuthStaff'));
          return;
        }

        /* Seccion 2 */
        if (!this.company.comP_BUSINESS_NAME || this.hasWhitespaceValidator(this.company.comP_BUSINESS_NAME)) {
          this.openResponseDialog('Error', this.translate.instant('hasWhiteSpaceKeyForm', { key: this.translate.instant('clientDetailConfSocialReason') }));
          return;
        } else if (this.hasSpecialCharacters(this.company.comP_BUSINESS_NAME)) {
          this.openResponseDialog('Error', this.translate.instant('invalidCharactersKeyForm', { key: this.translate.instant('clientDetailConfSocialReason') }));
          return;
        }

        if (this.hasWhitespaceValidator(this.company.comP_RFC)) {
          this.openResponseDialog('Error', this.translate.instant('hasWhiteSpaceKeyForm', { key: this.translate.instant('clientDetailConfRFC') }));
          return;
        } else if (this.company.comP_RFC.length > 0 && this.company.comP_RFC.length < 12) {
          this.openResponseDialog('Error', this.translate.instant('invalidAtLeastLengthKeyForm', { key: this.translate.instant('clientDetailConfRFC'), number: 12 }));
          return;
        } else if (this.hasSpecialCharacters(this.company.comP_RFC)) {
          this.openResponseDialog('Error', this.translate.instant('invalidCharactersKeyForm', { key: this.translate.instant('clientDetailConfRFC') }));
          return;
        } else if (!this.company.comP_RFC || this.company.comP_RFC == null || this.company.comP_RFC == undefined) {
          this.company.comP_RFC = ''
        }

        if (this.hasWhitespaceValidator(this.company?.comP_ADDRESS_ID_INFO?.adR_STREET)) {
          this.openResponseDialog('Error', this.translate.instant('hasWhiteSpaceKeyForm', { key: this.translate.instant('clientDetailConfStreet') }));
          return;
        } else if (this.hasSpecialCharacters(this.company?.comP_ADDRESS_ID_INFO?.adR_STREET)) {
          this.openResponseDialog('Error', this.translate.instant('invalidCharactersKeyForm', { key: this.translate.instant('clientDetailConfStreet') }));
          return;
        }

        if (this.hasWhitespaceValidator(this.company?.comP_ADDRESS_ID_INFO?.adR_SUITE_NUMBER)) {
          this.openResponseDialog('Error', this.translate.instant('hasWhiteSpaceKeyForm', { key: this.translate.instant('clientDetailConfNumberInt') }));
          return;
        } else if (this.hasSpecialCharacters(this.company?.comP_ADDRESS_ID_INFO?.adR_SUITE_NUMBER)) {
          this.openResponseDialog('Error', this.translate.instant('invalidCharactersKeyForm', { key: this.translate.instant('clientDetailConfNumberInt') }));
          return;
        }

        if (!this.company?.comP_ADDRESS_ID_INFO?.adR_SUBURB || this.hasWhitespaceValidator(this.company?.comP_ADDRESS_ID_INFO?.adR_SUBURB)) {
          this.openResponseDialog('Error', this.translate.instant('hasWhiteSpaceKeyForm', { key: this.translate.instant('clientDetailConfColony') }));
          return;
        } else if (this.hasSpecialCharacters(this.company?.comP_ADDRESS_ID_INFO?.adR_SUBURB)) {
          this.openResponseDialog('Error', this.translate.instant('invalidCharactersKeyForm', { key: this.translate.instant('clientDetailConfColony') }));
          return;
        }

        if (this.company?.comP_ADDRESS_ID_INFO?.adR_CP?.length < 5 && this.company?.comP_ADDRESS_ID_INFO?.adR_CP?.length > 0) {
          this.openResponseDialog('Error', this.translate.instant('invalidLengthKeyForm', { key: this.translate.instant('clientDetailConfPostalCode'), number: 5 }));
          return;
        } else if (!this.company?.comP_ADDRESS_ID_INFO?.adR_CP || this.hasWhitespaceValidator(this.company?.comP_ADDRESS_ID_INFO?.adR_CP)) {
          this.openResponseDialog('Error', this.translate.instant('hasWhiteSpaceKeyForm', { key: this.translate.instant('clientDetailConfPostalCode') }));
          return;
        } else if (this.hasSpecialCharacters(this.company?.comP_ADDRESS_ID_INFO?.adR_CP)) {
          this.openResponseDialog('Error', this.translate.instant('invalidCharactersKeyForm', { key: this.translate.instant('clientDetailConfPostalCode') }));
          return;
        }

        if (!this.company?.comP_ADDRESS_ID_INFO?.adR_STREET || this.hasWhitespaceValidator(this.company?.comP_ADDRESS_ID_INFO?.adR_STREET)) {
          this.openResponseDialog('Error', this.translate.instant('hasWhiteSpaceKeyForm', { key: this.translate.instant('clientDetailStreet') }));
          return;
        }

        if (!this.company?.comP_ADDRESS_ID_INFO?.adR_STREET_NUMBER || this.hasWhitespaceValidator(this.company?.comP_ADDRESS_ID_INFO?.adR_STREET_NUMBER)) {
          this.openResponseDialog('Error', this.translate.instant('hasWhiteSpaceKeyForm', { key: this.translate.instant('clientDetailNumber') }));
          return;
        }
      } else {
        if (!this.company.comP_NAME) this.company.comP_NAME = 'x';
        if (!this.company.comP_BUSINESS_NAME) this.company.comP_BUSINESS_NAME = 'x';
        if (!this.company.comP_RFC) this.company.comP_RFC = 'x';
        if (!this.company.comP_AUTHORIZED_TEMPLATE) this.company.comP_AUTHORIZED_TEMPLATE = 1;
        if (!this.company.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID) this.company.comP_ADDRESS_ID_INFO.adR_COUNTRY_ID = 'x';
        if (!this.company.comP_ADDRESS_ID_INFO.adR_STATE_CUSTOM) this.company.comP_ADDRESS_ID_INFO.adR_STATE_CUSTOM = 'x';
        if (!this.company.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM) this.company.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM = 'x';
        if (!this.company.comP_ADDRESS_ID_INFO.adR_STREET) this.company.comP_ADDRESS_ID_INFO.adR_STREET = 'x';
        if (!this.company.comP_ADDRESS_ID_INFO.adR_SUITE_NUMBER) this.company.comP_ADDRESS_ID_INFO.adR_SUITE_NUMBER = 'x';
        if (!this.company.comP_ADDRESS_ID_INFO.adR_SUBURB) this.company.comP_ADDRESS_ID_INFO.adR_SUBURB = 'x';
        if (!this.company.comP_ADDRESS_ID_INFO.adR_CP) this.company.comP_ADDRESS_ID_INFO.adR_CP = 'x';
        if (!this.company.comP_ADDRESS_ID_INFO.adR_STREET_NUMBER) this.company.comP_ADDRESS_ID_INFO.adR_STREET_NUMBER = 'x';
      }

      if (this.company.comP_IS_MINI) {
        this.company.comP_IS_AGENCY = false;
      }

      if (!this.company?.comP_IS_AGENCY) {
        this.company.comP_IS_AGENCY = false
      }

      if (!this.company?.comP_IS_MINI) {
        this.company.comP_IS_MINI = false
      }

      /* Sección 3 */
      if (!this.isValidHexColor(this.company.comP_PRIMARY_COLOR)) {
        this.company.comP_PRIMARY_COLOR = '#EB6E24';

      }

      this.sending = true;
      this.puzzleService.createCompany(this.company).subscribe(response => {
        let translatedMessage = '';
        if (response.isSuccess) {
          if (this.company?.comP_SUBSCRIPTION_STATUS == 'Freemium' && this.company?.plan) {
            this.changePlan(this.company.plan, this.company.comP_ID, 0);
          }
          if (response.message === 'rolCreatedCorrectly') {
            translatedMessage = this.translate.instant('companyCreatedSuccessfully');
          } else if (response.message === 'Empresa actualizada correctamente.') {
            translatedMessage = this.translate.instant('companyUpdatedSuccessfully');
          }
          this.edit = false;
          this.newClient = false;
          this.openResponseDialog('Exito', translatedMessage);

          if (!this.superadmin) {
            window.location.reload();
          } else {
            this.router.navigate(['home/clientlist'])
          }
        } else {
          translatedMessage = this.translate.instant('operationErrorRetry');
          this.openResponseDialog('Error', translatedMessage);
        }
        this.sending = false;
      }, error => {
        this.sending = false;
      });
    }
  }

  openSubdomain() {
    const url = `http://${this.receivedCompany.comP_SUBDOMAIN}.haibuhr.com`;
    window.open(url, '_blank');
  }

  hasSpecialCharacters(input: string): boolean {
    const regex = /^[\p{L}0-9\s.]*$/u;
    return !regex.test(input);
  }

  hasWhitespaceValidator(input: string) {
    // sin espacios en blanco pero puede ser nulo
    if (input === null || input === undefined || input === '') {
      return false;
    }
    return input.trim().length === 0;
  }

  private transformValue(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^a-zA-Z0-9À-ÿ\u00f1\u00d1\s.´]/g, '').trimStart();
  }

  private transformValueNumber(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^0-9]/g, '').trimStart();
  }

  private transformValueRFC(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^a-zA-ZÑñ&\d]/g, '').trimStart();
  }

  private isValidHexColor(color: string): boolean {
    const hexColorPattern = /^#([0-9A-Fa-f]{6})$/;
    return hexColorPattern.test(color);
  }

  getPlan(element: any): string {
    if (element.comP_JOBS_MANAGEMENT) {
      return 'haibujobs';
    } else
      if (element.comP_FREEMIUM) {
        return 'freemium';
      } else if (element.comP_IS_AGENCY && element.comP_HAS_SUBDOMAIN) {
        return 'full';
      } else if (element.comP_IS_AGENCY) {
        return 'agency';
      } else if (element.comP_HAS_SUBDOMAIN) {
        return 'middle';
      } else {
        return 'basic';
      }
  }

  changePlan(selectedPlan: string, companyId: string, index: number) {
    switch (selectedPlan) {
      case 'freemium':
        this.changeToFreemiumPlan(companyId, index);
        break;
      case 'basic':
        this.changeToBasicPlan(companyId, index);
        break;
      case 'agency':
        this.changeAgencyStatus(companyId, index);
        break;
      case 'middle':
        this.changeToMiddlePlan(companyId, index);
        break;
      case 'full':
        this.changeToFullPlan(companyId, index);
        break;
      case 'haibujobs':
        this.changeToJobsPlan(companyId, index);
        break
    }
  }

  changeAgencyStatus(companyId: string, index: number) {
    let company: Company = {
      cOMP_ID: companyId,
      comP_IS_AGENCY: true
    };

    this.puzzleService.changeAgencyStatus(company).subscribe(
      resp => {
        // Handle the response if needed
      }
    );
  }

  changeToFreemiumPlan(companyId: string, index: number) {
    let company: Company = {
      cOMP_ID: companyId,
    };

    this.puzzleService.changeToFreemiumPlan(company).subscribe(
      resp => {
      }
    );
  }

  changeToJobsPlan(companyId: string, index: number) {
    let company: Company = {
      cOMP_ID: companyId,
    };

    this.puzzleService.changeToJobsPlan(company).subscribe(
      resp => {
      }
    );
  }

  changeToBasicPlan(companyId: string, index: number) {
    let company: Company = {
      cOMP_ID: companyId,
      COMP_VERSION: true
    };

    this.puzzleService.changeToBasicPlan(company).subscribe(
      resp => {
      }
    );
  }

  changeToMiddlePlan(companyId: string, index: number) {
    let company: Company = {
      cOMP_ID: companyId,
      COMP_VERSION: true
    };

    this.puzzleService.changeToMiddlePlan(company).subscribe(
      resp => {
      }
    );
  }

  changeToFullPlan(companyId: string, index: number) {
    let company: Company = {
      cOMP_ID: companyId,
      COMP_VERSION: true
    };

    this.puzzleService.changeToFullPlan(company).subscribe(
      resp => {
      }
    );
  }

  openCSFDialog() {
    const dialogRef = this.dialog.open(UploadCsfDialogComponent, {
      width: '500px',
      data: {
        company: this.company
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCompany();
      }
    });
  }

  viewCSF(route: string) {
    if (route != null) {
      const fullPath = environment.imageUrl + route;
      let dialog = this.dialog.open(DocuViewerComponent, {
        panelClass: 'custom-dialog-cv',
        maxWidth: '100vw !important',
        data: { data: fullPath, header: 'CSF' },
      });

      dialog.afterClosed().subscribe((result) => { });
    }
  }

  goCompany(idComp: string) {
    this.router.navigate(['home/catalogues/company'], {
      queryParams: { id: idComp },
    });
  }

  getLabelDays(days: number) {
    if (days === 5) {
      return this.translate.instant('fiveDays');
    } else if (days === 6) {
      return this.translate.instant('sixDays');
    } else if (days === 7) {
      return this.translate.instant('sevenDays');
    } else {
      return this.translate.instant('noDaysDefined');
    }
  }

  onSelectDays(event: any) {
    this.company.comP_USE_NATURAL_DAYS = event.value;
    if (!event.value && (!this.company.comP_WORKING_DAYS_PER_WEEK || this.company.comP_WORKING_DAYS_PER_WEEK < 5)) {
      this.company.comP_WORKING_DAYS_PER_WEEK = 7;
    }
  }

  onFocus(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.addClass(target, 'focus-input');
  }

  onBlur(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.removeClass(target, 'focus-input');
  }
}
