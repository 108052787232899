<div class="flex h-fit overflow-hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-90">
    <div class="w-auto relative m-auto">
        <img src="../../../assets/img/update/popup-horizontal.png" alt="updateDialog"
            class="update-popup h-90 hidden md:block object-contain min-w-700">
        <img src="../../../assets/img/update/popup-vertical.png" alt="updateDialog"
            class="update-popup block md:hidden object-contain">
        <div class="absolute left-1/2 bottom-16 md:bottom-8 transform -translate-x-1/2">
            <app-custom-primary-button id="closeUpdate" color="secondary" size="small"
                class="w-full md:w-fit whitespace-nowrap overflow-hidden" (click)="closeModal()">
                {{"continue" | translate}}
            </app-custom-primary-button>
        </div>
    </div>
</div>