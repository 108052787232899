<div class="ml-auto w-full text-right justify-end -mt-5 -mr-5">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
        <mat-icon class="close-x-primary">close</mat-icon>
    </button>
</div>

<div class="text-xl text-center -mt-5" style="color: #28727D;">
    <div>{{ 'selectPlan' | translate }}</div>
    <div class="mb-3 subscription-switch-cont text-xs">
        <div class="flex items-center justify-center w-auto text-center">
            <button
                class="py-2 px-4 w-28 rounded-l-full bg-gray-50 text-gray-900 font-medium focus:outline-none text-sm"
                [ngClass]="{'bg-primary text-white': subscriptionType === 'mensual'}"
                (click)="setSubscriptionType('mensual')">
                {{ 'mensual' | translate }}
            </button>
            <button
                class="py-2 px-4 w-28 rounded-r-full bg-gray-50 text-gray-900 font-medium focus:outline-none text-sm"
                [ngClass]="{'bg-primary text-white': subscriptionType === 'semestral'}"
                (click)="setSubscriptionType('semestral')">
                {{ 'semestral' | translate }}
            </button>
        </div>
    </div>
    <!-- Cards -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div class="bg-white border-2 border-primary10 rounded-lg p-4 flex flex-col justify-between">
            <div>
                <div class="bg-primary10 w-full p-2 text-center text-primary text-sm rounded-md font-medium">
                    {{ 'plan.1' | translate }}
                </div>
                <hr class="mt-6 mb-6 border-t border-primary20">
                <div class="text-primary">
                    <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center -mb-4 -mt-2">
                        <div class="price-text2 text-primary"> {{ 'price.1.canceled' | translate }}</div>
                        <div class="bycompany-text2 ml-1 text-primary">MXN*</div>
                    </div>
                    <div class="flex justify-center mt-10 mb-7">
                        <div class="price-text text-primary"> {{ 'price.1.' + subscriptionType | translate }}</div>
                        <div class="bycompany-text text-primary ml-2">MXN*</div>
                    </div>
                    <div class="w-full max-w-lg mx-auto">
                        <p class="text-center bycompany-text text-primary">
                            {{ 'mensual' | translate }}
                        </p>
                    </div>
                </div>
                <hr class="mt-6 mb-4 border-t border-primary20">
                <!-- Features -->
                <div class="text-left text-primary text-xs font-medium">
                    <ul class="list-disc pl-5 space-y-1">
                        <li>{{ 'features.1' | translate }}</li>
                        <li>{{ 'features.2' | translate }}</li>
                        <li>{{ 'features.3' | translate }}*</li>
                        <li>{{ 'features.4' | translate }}*</li>
                        <li>{{ 'features.6' | translate }}</li>
                        <li>{{ 'features.7' | translate }}</li>
                        <li>{{ 'features.13' | translate }}</li>
                        <li>{{ 'features.16' | translate }}</li>
                    </ul>
                </div>
            </div>
            <!-- Botón o acción -->
            <div class="mt-4">
                <app-custom-primary-button [color]="currentPlan?.includes('Entry') && currentBilling == subscriptionType ? 'primary-disabled' : 'primary'" size="auto-small" (click)="upgradePlanStripe(subscriptionType === 'semestral' ? 'EntryY' : 'Entry')"
                    class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden" [disabled]="currentPlan?.includes('Entry') && currentBilling == subscriptionType">
                    <span *ngIf="currentPlan?.includes('Entry') && currentBilling == subscriptionType">{{ 'callToAction.selected' | translate }}</span>
                    <span *ngIf="!currentPlan?.includes('Entry') || currentBilling != subscriptionType">{{ 'callToAction.select' | translate }}</span>
                </app-custom-primary-button>
            </div>
        </div>

        <div class="bg-primary rounded-lg p-4 flex flex-col justify-between">
            <div>
                <div class="bg-primary10 w-full p-2 text-center text-primary text-sm rounded-md font-medium">
                    {{ 'plan.2' | translate }}
                </div>
                <hr class="mt-6 mb-6 border-t border-primary20">
                <div class="text-white">
                    <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center -mb-4 -mt-2">
                        <div class="price-text2 text-white"> {{ 'price.2.canceled' | translate }}</div>
                        <div class="bycompany-text2 ml-1 text-white">MXN*</div>
                    </div>
                    <div class="flex justify-center mt-10 mb-7">
                        <div class="price-text text-white"> {{ 'price.2.' + subscriptionType | translate }}</div>
                        <div class="bycompany-text text-white ml-2">MXN*</div>
                    </div>
                    <div class="w-full max-w-lg mx-auto">
                        <p class="text-center bycompany-text text-white">
                            {{ 'mensual' | translate }}
                        </p>
                    </div>
                </div>
                <hr class="mt-6 mb-4 border-t border-primary20">
                <!-- Features -->
                <div class="text-left text-white text-xs font-medium">
                    <ul class="list-disc pl-5 space-y-1">
                        <li>{{ 'features.entry' | translate }}</li>
                        <li>{{ 'features.10' | translate }}</li>
                        <li>{{ 'features.11' | translate }}</li>
                        <li>{{ 'features.12' | translate }}</li>
                        <li>{{ 'features.14' | translate }}</li>
                        <li>{{ 'features.16' | translate }}</li>
                    </ul>
                </div>
            </div>
            <!-- Botón o acción -->
            <div class="mt-4">
                <app-custom-primary-button [color]="currentPlan?.includes('Head Hunter') && !currentPlan?.includes('Mid') && currentBilling == subscriptionType ? 'secondary-disabled' : 'secondary'" size="auto-small" (click)="upgradePlanStripe(subscriptionType === 'semestral' ? 'EntryHHY' : 'EntryHH')"
                    class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden" [disabled]="currentPlan?.includes('Head Hunter') && !currentPlan?.includes('Mid') && currentBilling == subscriptionType">
                    <span *ngIf="currentPlan?.includes('Head Hunter') && !currentPlan?.includes('Mid') && currentBilling == subscriptionType">{{ 'callToAction.selected' | translate }}</span>
                    <span *ngIf="(!currentPlan?.includes('Head Hunter') || currentPlan?.includes('Mid')) || currentBilling != subscriptionType">{{ 'callToAction.select' | translate }}</span>
                </app-custom-primary-button>
            </div>
        </div>

        <div class="bg-white border-2 border-primary10 rounded-lg p-4 flex flex-col justify-between">
            <div>
                <div class="bg-primary10 w-full p-2 text-center text-primary text-sm rounded-md font-medium">
                    {{ 'plan.3' | translate }}
                </div>
                <hr class="mt-6 mb-6 border-t border-primary20">
                <div class="text-primary">
                    <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center -mb-4 -mt-2">
                        <div class="price-text2 text-primary"> {{ 'price.3.canceled' | translate }}</div>
                        <div class="bycompany-text2 ml-1 text-primary">MXN*</div>
                    </div>
                    <div class="flex justify-center mt-10 mb-7">
                        <div class="price-text text-primary"> {{ 'price.3.' + subscriptionType | translate }}</div>
                        <div class="bycompany-text text-primary ml-2">MXN*</div>
                    </div>
                    <div class="w-full max-w-lg mx-auto">
                        <p class="text-center bycompany-text text-primary">
                            {{ 'mensual' | translate }}
                        </p>
                    </div>
                </div>
                <hr class="mt-6 mb-4 border-t border-primary20">
                <!-- Features -->
                <div class="text-left text-primary text-xs font-medium">
                    <ul class="list-disc pl-5 space-y-1">
                        <li>{{ 'features.entry' | translate }}</li>
                        <li>{{ 'features.8' | translate }}</li>
                        <li>{{ 'features.17' | translate }}</li>
                        <li>{{ 'features.18' | translate }}</li>
                        <li>{{ 'features.15' | translate }}</li>
                        <li>{{ 'features.16' | translate }}</li>
                    </ul>
                </div>
            </div>
            <!-- Botón o acción -->
            <div class="mt-4">
                <app-custom-primary-button [color]="currentPlan?.includes('Mid') && !currentPlan?.includes('Head Hunter') && currentBilling == subscriptionType ? 'primary-disabled' : 'primary'" size="auto-small" (click)="upgradePlanStripe(subscriptionType === 'semestral' ? 'MidY' : 'Mid')"
                    class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden" [disabled]="currentPlan?.includes('Mid') && !currentPlan?.includes('Head Hunter') && currentBilling == subscriptionType">
                    <span *ngIf="currentPlan?.includes('Mid') && !currentPlan?.includes('Head Hunter') && currentBilling == subscriptionType">{{ 'callToAction.selected' | translate }}</span>
                    <span *ngIf="!currentPlan?.includes('Mid') || currentPlan?.includes('Head Hunter') || currentBilling != subscriptionType">{{ 'callToAction.select' | translate }}</span>
                </app-custom-primary-button>
            </div>
        </div>

        <div class="bg-primary rounded-lg p-4 flex flex-col justify-between">
            <div>
                <div class="bg-primary10 w-full p-2 text-center text-primary text-sm rounded-md font-medium">
                    {{ 'plan.4' | translate }}
                </div>
                <hr class="mt-6 mb-6 border-t border-primary20">
                <div class="text-white">
                    <div *ngIf="subscriptionType === 'semestral'" class="flex justify-center -mb-4 -mt-2">
                        <div class="price-text2 text-white"> {{ 'price.4.canceled' | translate }}</div>
                        <div class="bycompany-text2 ml-1 text-white">MXN*</div>
                    </div>
                    <div class="flex justify-center mt-10 mb-7">
                        <div class="price-text text-white"> {{ 'price.4.' + subscriptionType | translate }}</div>
                        <div class="bycompany-text text-white ml-2">MXN*</div>
                    </div>
                    <div class="w-full max-w-lg mx-auto">
                        <p class="text-center bycompany-text text-white">
                            {{ 'mensual' | translate }}
                        </p>
                    </div>
                </div>
                <hr class="mt-6 mb-4 border-t border-primary20">
                <!-- Features -->
                <div class="text-left text-white text-xs font-medium">
                    <ul class="list-disc pl-5 space-y-1">
                        <li>{{ 'features.mid' | translate }}</li>
                        <li>{{ 'features.10' | translate }}</li>
                        <li>{{ 'features.11' | translate }}</li>
                        <li>{{ 'features.12' | translate }}</li>
                        <li>{{ 'features.14' | translate }}</li>
                        <li>{{ 'features.16' | translate }}</li>
                    </ul>
                </div>
            </div>
            <!-- Botón o acción -->
            <div class="mt-4">
                <app-custom-primary-button [color]="currentPlan?.includes('Mid + Head Hunter') && currentBilling == subscriptionType ? 'secondary-disabled' : 'secondary'" size="auto-small" (click)="upgradePlanStripe(subscriptionType === 'semestral' ? 'MidHHY' : 'MidHH')"
                    class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden" [disabled]="currentPlan?.includes('Mid + Head Hunter') && currentBilling == subscriptionType">
                    <span *ngIf="currentPlan?.includes('Mid + Head Hunter') && currentBilling == subscriptionType">{{ 'callToAction.selected' | translate }}</span>
                    <span *ngIf="!currentPlan?.includes('Mid + Head Hunter') || currentBilling != subscriptionType">{{ 'callToAction.select' | translate }}</span>
                </app-custom-primary-button>
            </div>
        </div>
    </div>

    <div class="enterprise text-modal mt-3">
        {{ 'enterpriseSolution' | translate }} <a (click)="scheduleMeet()" class="text-primary">{{ 'here' | translate }}.</a>
    </div>
    <div>
        <p *ngIf="subscriptionType === 'semestral'" class="text-note -mt-1">
            {{ 'priceNote' | translate }}
        </p>
        <p *ngIf="subscriptionType !== 'semestral'" class="text-note -mt-1">
            {{ 'priceNoteM' | translate }}
        </p>
    </div>
</div>