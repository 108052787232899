import { Component, HostListener, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LocalService } from 'src/app/services/local.service';
import { LoginDialogComponent } from 'src/app/dialogs/login-dialog/login-dialog.component';
import { ViewportScroller } from '@angular/common';
import { APIResponse } from 'src/app/interfaces/response';
import { constants } from 'src/app/services/constants';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  whatsappImage: SafeResourceUrl = '../../assets/icons/whatsapp-icon.svg';
  smartPhoneImage: SafeResourceUrl = '../../assets/icons/smartphone-icon.svg';
  languageImage: SafeResourceUrl = '../../assets/icons/language-icon.svg';
  menuIcon: SafeResourceUrl = '../../assets/icons/men-icon.svg';
  haibuImage: SafeResourceUrl = '../../assets/icons/haibu-icon.svg';
  closeIcon: SafeResourceUrl = '../../assets/icons/close-icon.svg';
  fbIcon: SafeResourceUrl = '../../assets/icons/facebook-icon.svg';
  igIcon: SafeResourceUrl = '../../assets/icons/ig-icon.svg';
  linkedinIcon: SafeResourceUrl = '../../assets/icons/linkedin-icon.svg';
  ytIcon: SafeResourceUrl = '../../assets/icons/yt-icon.svg';

  scrolled = false;
  dropdownOpen = false;
  currentLang: string;
  isMenuOpen = false;

  loginNamePlaceHolder: string;
  candidateFinalDatePlaceholder: string;
  loginLastName: string;
  loginEmail: string;
  loginCellPhone: string;
  loginBussines: string;
  loginNumberEmployees: string;
  loginCountry: string;
  loginCode: string;
  userName: string;
  passwordName: string;
  summaryText: string;
  user: string;
  password: string;
  token: APIResponse = this.localService.getJsonValue('token');

  constructor(
    private localService: LocalService,
    private translate: TranslateService,
    private router: Router,
    public dialog: MatDialog,
    private viewportScroller: ViewportScroller
  ) { }

  // detectar scroll
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.scrollY > 0) {
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }
  }

  ngOnInit(): void {
    this.updateTranslations();
    this.initializeLanguage();
  }

  initializeLanguage() {
    if (this.localService.getJsonValue('lang')) {
      this.translate.use(this.localService.getJsonValue('lang'));
      this.currentLang = this.localService.getJsonValue('lang');
    } else {
      const browserLang = this.translate.getBrowserLang();
      let appLang = this.mapBrowserLangToAppLang(browserLang);
      this.translate.use(appLang);
      this.currentLang = appLang;
      this.localService.setJsonValue('lang', appLang);
    }
  }

  mapBrowserLangToAppLang(browserLang: string): string {
    const langMap = {
      'en': 'eng',
      'es': 'esp'
    };
    return langMap[browserLang] || 'eng';
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  changeLanguage(lang: string): void {
    this.translate.use(lang).subscribe(() => {
      this.updateTranslations();
    });
    const newLang = lang;
    this.translate.use(newLang);
    this.currentLang = newLang;
    this.dropdownOpen = false;
    this.localService.setJsonValue('lang', newLang);
  }

  updateTranslations() {
    this.translate.get('loginAskDemoName').subscribe((res: string) => {
      this.loginNamePlaceHolder = res;
    });
    this.translate.get('loginAskDemoLastName').subscribe((res: string) => {
      this.loginLastName = res;
    });
    this.translate.get('loginAskDemoEmail').subscribe((res: string) => {
      this.loginEmail = res;
    });
    this.translate.get('loginAskDemoPhone').subscribe((res: string) => {
      this.loginCellPhone = res;
    });
    this.translate.get('loginAskDemoCompany').subscribe((res: string) => {
      this.loginBussines = res;
    });
    this.translate.get('loginAskCountNumber').subscribe((res: string) => {
      this.loginNumberEmployees = res;
    });
    this.translate.get('loginAskCountry').subscribe((res: string) => {
      this.loginCountry = res;
    });
    this.translate.get('loginAskDemoCode').subscribe((res: string) => {
      this.loginCode = res;
    });

    this.translate.get('loginPLaceHolderUser').subscribe((res: string) => {
      this.userName = res;
    });
    this.translate.get('loginPLaceHolderPassword').subscribe((res: string) => {
      this.passwordName = res;
    });
    this.translate.get('loginPuzzleMeaning').subscribe((res: string) => {
      this.summaryText = res;
    });
  }

  get currentLangShort(): string {
    return this.currentLang === 'eng' ? 'EN' : 'ES';
  }

  toggleMenu() {
    return new Promise((resolve) => {
      this.isMenuOpen = !this.isMenuOpen;
      setTimeout(resolve, 350);
    });
  }

  scrollToElement(element: string) {
    this.toggleMenu();
    if (element === 'blog') {
      this.router.navigate(['blog']).then(() => {
        this.viewportScroller.scrollToPosition([0, 0]);
      });
      return;
    }
    // redirect to home page with the element id
    this.router.navigate(['login'], { fragment: element });
  }

  openLoginModal() {
    if (this.token?.data?.id) {
      if (this.token.data.roleId == constants.superadmin) {
        this.router.navigate(['home/clientlist']);
      } else {
        this.router.navigate(['home/dashboard']);
      }
      return;
    }
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      width: '376px',
      height: '546px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: { user: this.user, password: this.password }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
