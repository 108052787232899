import { Pipe, PipeTransform } from '@angular/core';
import { LocalService } from 'src/app/services/local.service';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {

  constructor(private localService: LocalService) { }

  transform(value: string, lineBreak: boolean = false): string {
    const tokencito = this.localService.getJsonValue('token');
    const lang = tokencito?.data?.languagePreference === 'esp' ? 'es' : 'en';

    if (!value) return '';

    // Interpretar la fecha como UTC y ajustarla a la hora local
    const date = new Date(value + 'Z');

    // Formatear la fecha con base en el idioma del token
    const formattedDate = date.toLocaleString(lang, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric', // 'numeric' elimina el cero inicial en horas de una sola cifra
      minute: '2-digit',
      hour12: true,    // Forzar el formato de 12 horas
    });

    // Insertar un salto de línea si lineBreak es true
    return lineBreak ? formattedDate.replace(',', '<br>') : formattedDate;
  }
}
