import { Component, OnInit } from '@angular/core';
import { ResponseSmallDialogComponent } from '../dialogs/response-small-dialog/response-small-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FreemiumDialogComponent } from '../dialogs/freemium-dialog/freemium-dialog.component';

@Component({
  selector: 'app-cancel-payment',
  templateUrl: './cancel-payment.component.html',
  styleUrls: ['./cancel-payment.component.css']
})
export class CancelPaymentComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.router.navigate(['/']);
    this.openResultDialog();
  }

  openResultDialog() {
    this.translate.get(['paymentUnpaid']).subscribe(translations => {
      const salesModal = this.dialog.open(FreemiumDialogComponent, {
        panelClass: 'freemium-dialog',
        width: '500px',
        maxWidth: '95vw',
        data: {
          title: translations['paymentUnpaid'],
          action2: 1,
          secondAction: 'contactSales'
        }
      });
    });
  }
}
