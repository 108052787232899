<!-- <mat-form-field class="custom-input custom-date w-full" [ngClass]="height" [style]="borderColor == 'black' ? 'border: 2px solid ' + borderColor + ';' : 'border: 1px solid ' + borderColor + ';'">
    <input matInput [matDatepicker]="dp" [(ngModel)]="selectedDate" (dateChange)="dateChanged($event)" [min]="min"
        [max]="max" [required]="required"  [disabled]="disabled" (click)="openDatepicker()" (keypress)="openDatepicker()"
        oninput="this.value = ''" (focus)="onFocus($event)" (blur)="onBlur($event)">
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp [disabled]="disabled"></mat-datepicker>
</mat-form-field> -->
<form [formGroup]="editForm" class="flex items-start w-full date-picker" [ngClass]="height">
    <!-- Validación para idioma -->
    <ng-container *ngIf="languageSelected === 'es'; else otherLanguage">
        <!-- Selector de Día -->
        <mat-select id="day" formControlName="day" [placeholder]="'day2' | translate"
            (selectionChange)="onDayChange($event)" [disabled]="disabled" [ngClass]="height"
            [ngStyle]="{ 'border-color': borderColor }" (focus)="onFocus($event)" (blur)="onBlur($event)"
            [tabIndex]="tabindex" class="text-sm p-2 block w-1/4 border bg-white rounded-l-lg date-select">
            <mat-option (click)="$event.stopPropagation()" *ngFor="let day of days" [value]="day">{{ day }}</mat-option>
        </mat-select>

        <!-- Selector de Mes -->
        <mat-select id="month" formControlName="month" [placeholder]="'month' | translate" (focus)="onFocus($event)"
            (blur)="onBlur($event)" (selectionChange)="onMonthChange($event)" [disabled]="disabled" [ngClass]="height"
            [ngStyle]="{ 'border-color': borderColor }" [tabIndex]="tabindex"
            class="h-select text-sm p-2 block w-1/4 border bg-white date-select -mx-1">
            <mat-option (click)="$event.stopPropagation()" *ngFor="let month of months" [value]="month.value">{{
                month.name | translate }}</mat-option>
        </mat-select>
    </ng-container>

    <!-- Caso idioma distinto -->
    <ng-template #otherLanguage>
        <!-- Selector de Mes -->
        <mat-select id="month" formControlName="month" [placeholder]="'month' | translate" (focus)="onFocus($event)"
            (blur)="onBlur($event)" (selectionChange)="onMonthChange($event)" [disabled]="disabled" [ngClass]="height"
            [ngStyle]="{ 'border-color': borderColor }" [tabIndex]="tabindex"
            class="h-select text-sm p-2 block w-1/4 border bg-white rounded-l-lg date-select">
            <mat-option (click)="$event.stopPropagation()" *ngFor="let month of months" [value]="month.value">{{
                month.name | translate }}</mat-option>
        </mat-select>

        <!-- Selector de Día -->
        <mat-select id="day" formControlName="day" [placeholder]="'day2' | translate"
            (selectionChange)="onDayChange($event)" [disabled]="disabled" [ngClass]="height"
            [ngStyle]="{ 'border-color': borderColor }" (focus)="onFocus($event)" (blur)="onBlur($event)"
            [tabIndex]="tabindex" class="h-select text-sm p-2 block w-1/4 border bg-white date-select -mx-1">
            <mat-option (click)="$event.stopPropagation()" *ngFor="let day of days" [value]="day">{{ day }}</mat-option>
        </mat-select>
    </ng-template>

    <!-- Selector de Año -->
    <mat-select id="year" formControlName="year" [placeholder]="'year' | translate"
        (selectionChange)="onYearChange($event)" [disabled]="disabled" [ngClass]="height"
        [ngStyle]="{ 'border-color': borderColor }" (focus)="onFocus($event)" [tabIndex]="tabindex"
        (blur)="onBlur($event)" class="h-select text-sm p-2 block w-1/4 border bg-white rounded-r-lg date-select">
        <mat-option (click)="$event.stopPropagation()" *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
    </mat-select>
</form>