import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { APIResponse } from 'src/app/interfaces/response';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.css']
})
export class LoadingDialogComponent implements OnInit {

  token: APIResponse = this.localService.getJsonValue('token');
  hasSubdomain: boolean = false;
  isMini: boolean = false;
  isAgency: boolean = false;

  constructor(private router: Router,
    private localService: LocalService,
    private dialogRef: MatDialogRef<LoadingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string[]) {
    this.hasSubdomain = this.token?.data?.comP_HAS_SUBDOMAIN;
    if (this.token?.data?.isMiniCompany) {
      this.isMini = true;
    }
    if (this.token?.data?.isAgency) {
      this.isAgency = true;
    }
  }

  ngOnInit(): void {
  }

  cerrar(option: boolean) {
    if (option) {
      this.dialogRef.close(true);
    }
    else {
      this.dialogRef.close();
    }
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
    this.dialogRef.close();
  }

}
