import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor(private storageService: StorageService) {}

  isSafari(): boolean {
    const ua = navigator.userAgent;
    return /Safari/.test(ua) && !/Chrome|CriOS|Android|Edg|Firefox/i.test(ua);
  }

  setJsonValue(key: string, value: any) {
    const encodedValue = JSON.stringify(value);

    if (this.isSafari()) {
      sessionStorage.setItem(key, encodedValue);
      localStorage.setItem(key, encodedValue); // Fallback en caso de que Safari borre sessionStorage
    } else if (this.storageService.secureStorage) {
      this.storageService.secureStorage.setItem(key, value);
    }
  }

  getJsonValue(key: string) {
    if (this.isSafari()) {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        return JSON.parse(sessionValue);
      }

      // Fallback a localStorage si sessionStorage está vacío
      const localValue = localStorage.getItem(key);
      if (localValue) {
        sessionStorage.setItem(key, localValue); // Restaurar en sessionStorage
        return JSON.parse(localValue);
      }

      return null;
    }

    return this.storageService.secureStorage ? this.storageService.secureStorage.getItem(key) : null;
  }

  removeJsonValue(key: string) {
    if (this.isSafari()) {
      sessionStorage.removeItem(key);
      localStorage.removeItem(key);
    } else if (this.storageService.secureStorage) {
      this.storageService.secureStorage.removeItem(key);
    }
  }

  clearToken() {
    const cookiePreferences = this.getJsonValue('cookiePreferences');

    if (this.isSafari()) {
      sessionStorage.clear();
      localStorage.clear();
    } else if (this.storageService.secureStorage) {
      this.storageService.secureStorage.clear();
    }

    if (cookiePreferences) {
      this.setJsonValue('cookiePreferences', cookiePreferences);
    }
  }
}
