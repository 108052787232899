<h2 mat-dialog-title class="green-title">{{ 'uploadExcelUsersCatalogs' | translate }}</h2>
<mat-dialog-content>
    <div class="text-xs text-gray-600 mb-2">
        {{ 'uploadExcelCatalogsMessage' | translate }}
    </div>
    <!-- Contenedor de Drag and Drop -->
    <div class="upload-dropzone" (drop)="onFileDropped($event)" (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)">
        <div class="text-center text-xs text-gray-600">{{ 'uploadCSFDrag' | translate }}</div>
        <input type="file" (change)="onFileSelected($event)" class="file-input" />
    </div>

    <!-- Mensaje debajo del Drag and Drop -->
    <div class="text-xs text-gray-600 mt-2">
        {{ 'uploadExcelCatalogsRequirements' | translate }}
    </div>

    <!-- Mostrar archivo seleccionado -->
    <div *ngIf="selectedFile?.name"
        class="mt-2 text-sm text-primary border rounded-lg border-primary w-fit py-1 px-3 max-w-full text-nowrap text-ellipsis overflow-hidden">
        {{ selectedFile?.name }}
    </div>
</mat-dialog-content>
<mat-dialog-actions class="flex gap-2 justify-end">
    <button mat-dialog-close class="bg-white text-primary border border-primary py-2 px-4 rounded-full text-sm" [disabled]="sending">
        {{ 'cancelBtn' | translate }}
    </button>
    <button (click)="uploadFile()" class="py-2 px-4 rounded-full text-sm" [disabled]="!selectedFile"
        [ngClass]="selectedFile ? 'bg-primary text-white' : 'bg-gray-200 text-gray-500'">
        <ng-container *ngIf="!sending; else loading">
            <span>{{ 'updateCSFBtn' | translate }}</span>
        </ng-container>
        <ng-template #loading>
            <div>
                <mat-spinner diameter="22"></mat-spinner>
            </div>
        </ng-template>
    </button>
</mat-dialog-actions>