import { Component, OnInit } from '@angular/core';
import { ResponseSmallDialogComponent } from '../dialogs/response-small-dialog/response-small-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { PuzzleService } from '../services/puzzle.service';
import { LocalService } from '../services/local.service';

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.css']
})
export class SuccessPaymentComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private router: Router,
    private puzzleService: PuzzleService,
    private localService: LocalService,
  ) {
    this.initializeLanguage();
  }

  ngOnInit(): void {
    this.translate.get('paymentSuccess').subscribe((translatedText: string) => {
      const dialogRef = this.openResultDialog(1, translatedText);
      dialogRef.afterClosed().subscribe(result => {
        this.router.navigate(['/home/dashboard']);
      });
    });
  }

  initializeLanguage() {
    const tokencito = this.localService.getJsonValue('token');
    const languagePreference = tokencito?.data?.languagePreference;
    if (languagePreference === 'esp') {
      this.translate.use('esp');
    } else if (languagePreference === 'eng') {
      this.translate.use('eng');
    } else {
      const browserLang = this.translate.getBrowserLang();
      let appLang = this.mapBrowserLangToAppLang(browserLang);
      this.translate.use(appLang);
      this.puzzleService.setUserLanguage(appLang).subscribe();
    }
  }

  mapBrowserLangToAppLang(browserLang: string): string {
    const langMap = {
      'en': 'eng',
      'es': 'esp'
    };

    return langMap[browserLang] || 'eng';
  }

  openResultDialog(mode: number, message?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0: {
        data.push('Error');
        data.push(message);
        return this.dialog.open(ResponseSmallDialogComponent, {
          width: '500px',
          data: data,
          disableClose: true
        });
      } break;
      case 1: {
        data.push('Exito');
        data.push(message);

        return this.dialog.open(ResponseSmallDialogComponent, {
          width: '500px',
          data: data,
          disableClose: true
        });
      } break;
    }

  }
}
