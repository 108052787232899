<div>
    <!-- <div class="bg-yellow-300 text-black text-center text-sm flex justify-center items-center py-1 flex-col lg:flex-row gap-1">
        <mat-icon class="warning-icon mr-1">warning</mat-icon>
        <span>{{ 'maintenanceMessageAlert' | translate }}</span>
    </div> -->
    <div *ngIf="!testHaibu && !isFreemium"
        class="bg-primary text-white text-center text-sm flex justify-center items-center py-1 flex-col lg:flex-row gap-1">
        <span>{{ 'maintenanceMessage' | translate }}</span> <a href="https://haibujobs.com/" target="_blank"
            class="underline">{{ 'maintenanceLink' | translate }}</a>
    </div>
    <div *ngIf="(!testHaibu && isFreemium) || (testHaibu && daysLeft === 0)"
        class="bg-secondary text-white text-center text-sm flex justify-center items-center py-1 flex-col lg:flex-row gap-1">
        <span>{{ 'upgradeHaibuFreemium' | translate }}</span> <span *ngIf="!isMiniCompany"><a *appPermission="'m002'" (click)="openPlansModal()" class="text-primary">{{
            'here' | translate }}</a></span>
    </div>
    <div *ngIf="testHaibu && daysLeft > 0"
        class="bg-secondary text-white text-center text-sm flex justify-center items-center py-1 flex-col lg:flex-row gap-1">
        <span>{{ 'upgradeHaibu' | translate: { days: daysLeft, label: daysLeft > 1 ? ('days' | translate) : ('day' |
            translate) } }}</span> <span *ngIf="!isMiniCompany"><a *appPermission="'m002'" (click)="openPlansModal()" class="text-primary">{{
                'here' | translate }}</a></span>
    </div>
</div>